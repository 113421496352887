import React from 'react';
import track, { useTracking } from 'react-tracking';
import { Link } from 'react-scroll';
import classnames from 'classnames';

import styles from './Tab.pcss';

import Text from 'Atoms/Text/Text';
import Icon from 'Atoms/Icon/Icon';
import UnreadCount from 'Atoms/UnreadCount/UnreadCount';
import SimpleLink from 'Components/SimpleLink/SimpleLink';

const Tabs = (props) => {
  const {
    id,
    size,
    className,
    msg,
    icon,
    text,
    link,
    msgValues,
    count,
    isActive,
    currentId,
    leftIconName,
    unread,
    unreadCount,
    onClick,
    linkTo,
    linkContainerId,
    linkOffset = -50,
  } = props;

  const tracking = useTracking();

  const handleClick = () => {
    tracking.trackEvent({
      event: 'selected',
      payload: {
        value: id,
      },
    });

    if (currentId !== id) {
      onClick(id);
    }
  };

  const color = isActive ? '' : 'grayDog';
  const iconColor = isActive ? 'base' : 'grayDog';
  const countColor = isActive ? 'white' : 'grayDog';

  const titleStyle = size === 'lg' ? { tag: 'h2', weight: '700' } : {};

  const textEl = (
    <div className={styles.content}>
      {leftIconName && <Icon name={leftIconName} color={iconColor} className={styles.leftIcon} />}
      <Text
        msg={msg}
        text={text}
        color={color}
        values={msgValues}
        className={styles.text}
        {...titleStyle}
      />
    </div>
  );

  const content = linkTo ? (
    <Link
      to={linkTo}
      spy={true}
      smooth={true}
      duration={100}
      containerId={linkContainerId}
      className={styles.scrollLink}
      activeClass={styles.activeLink}
      offset={linkOffset}
    >
      {textEl}
    </Link>
  ) : (
    textEl
  );

  const sizeStyle = size ? styles[size] : '';

  const el = (
    <div
      className={classnames(`${className} ${styles.root}`, sizeStyle, {
        [styles.isActive]: linkTo ? false : isActive,
      })}
      onClick={isActive || link ? null : handleClick}
    >
      <div className={styles.textWrap}>
        {content}
        {count > 0 && (
          <div className={classnames(styles.counter, { [styles.unread]: unread })}>
            <Text size="sm" color={countColor} weight="500">
              {count}
            </Text>
          </div>
        )}
        {icon && <img src={icon} className={styles.icon} />}
        {unreadCount > 0 && <UnreadCount count={unreadCount} className={styles.unreadCount} />}
      </div>
      <div className={styles.line} />
    </div>
  );

  return link ? (
    <SimpleLink
      href={link}
      target="_blank"
      display="block"
      className={`${className} ${styles.root}`}
    >
      {el}
    </SimpleLink>
  ) : (
    el
  );
};

export default track({
  element: 'tab',
})(Tabs);
