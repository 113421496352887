import compact from 'lodash/compact';

import { ControlItem } from '../components/SegmentedControls/SegmentedControls';

import { amplitude } from 'Helpers/amplitude';
import {
  CONTENT_CREATION,
  INFLUENCER,
  INSTAGRAM,
  TIKTOK,
  FACEBOOK,
  OTHER,
  MONEY_TYPE,
  BARTER_TYPE,
  ORGANIC_POSTING,
  CREATOR_ADS,
  PRODUCT_SEEDING,
  COMMISSION_DISABLED_TYPE,
  COMMISSION_ENABLED_TYPE,
  CONTENT_CREATION_BRIEF_TEMPLATE,
  ORGANIC_POSTING_BRIEF_TEMPLATE,
  CREATOR_ADS_BRIEF_TEMPLATE,
  PRODUCT_SEEDING_BRIEF_TEMPLATE,
  USER_GENERATED_CONTENT_SAMPLE_LINK,
  INFLUENCER_POSTS_SAMPLE_LINK,
  PRODUCT_SEEDING_SAMPLE_LINK,
  TIKTOK_SPARK_ADS_SAMPLE_LINK,
  META_PARTNERSHIP_ADS_SAMPLE_LINK,
  AFFILIATE_CAMPAIGN_SAMPLE_LINK,
  TIKTOK_SHOP_SAMPLE_LINK,
} from 'Constants/general';
import { Currency } from 'GraphTypes/BriefTemplateContentQuery.graphql';
import { CampaignDetails_campaign$data } from 'GraphTypes/CampaignDetails_campaign.graphql';
import { CampaignType } from 'GraphTypes/BriefQuery.graphql';
import { CampaignObjective, CampaignUseCase } from 'GraphTypes/CreateCampaignMutation.graphql';
import { FC } from 'react';

export const MIN_PRICE_AMOUNT = 29;
export const MIN_TIKTOK_PRICE_AMOUNT = 150;
export const MIN_INSTAGRAM_PRICE_AMOUNT = 160;
export const MAX_PRICE_AMOUNT = 100000;

type GetTypesList = (data: {
  campaignId: string;
  availableCampaignTypes: CampaignType[];
}) => ControlItem[];
export const getTypesList: GetTypesList = (data) => {
  const { availableCampaignTypes, campaignId } = data;

  const sendEvent = () => {
    amplitude.sendEvent<350>({
      id: '350',
      category: 'brief',
      name: 'learn_more_tooltip_click',
      param: { campaign_id: campaignId },
    });
  };

  const result = [
    {
      id: CONTENT_CREATION,
      value: CONTENT_CREATION,
      titleMsg: 'brief_template.field.campaign_type.content_creation.title',
      subtitleMsg: 'brief_template.field.campaign_type.content_creation.descr',
      disabled: !availableCampaignTypes.includes(CONTENT_CREATION),
      dataTest: 'button:campaignType:justContent',
      outerLink: CONTENT_CREATION_BRIEF_TEMPLATE,
      outerLinkMsg: 'brief_template.field.campaign_type.sample_brief',
      outerLinkCb: sendEvent,
    },
    {
      id: ORGANIC_POSTING,
      value: ORGANIC_POSTING,
      titleMsg: 'brief_template.field.campaign_type.organic_posting.title',
      subtitleMsg: 'brief_template.field.campaign_type.organic_posting.descr',
      disabled: !availableCampaignTypes.includes(INFLUENCER),
      dataTest: 'button:campaignType:organicPosting',
      outerLink: ORGANIC_POSTING_BRIEF_TEMPLATE,
      outerLinkMsg: 'brief_template.field.campaign_type.sample_brief',
      outerLinkCb: sendEvent,
    },
    {
      id: CREATOR_ADS,
      value: CREATOR_ADS,
      titleMsg: 'brief_template.field.campaign_type.creator_ads.title',
      subtitleMsg: 'brief_template.field.campaign_type.creator_ads.descr',
      disabled: !availableCampaignTypes.includes(INFLUENCER),
      dataTest: 'button:campaignType:creatorAds',
      outerLink: CREATOR_ADS_BRIEF_TEMPLATE,
      outerLinkMsg: 'brief_template.field.campaign_type.sample_brief',
      outerLinkCb: sendEvent,
    },
    {
      id: PRODUCT_SEEDING,
      value: PRODUCT_SEEDING,
      titleMsg: 'brief_template.field.campaign_type.product_seeding.title',
      subtitleMsg: 'brief_template.field.campaign_type.product_seeding.descr',
      disabled: !availableCampaignTypes.includes(PRODUCT_SEEDING),
      dataTest: 'button:campaignType:productSeeding',
      outerLink: PRODUCT_SEEDING_BRIEF_TEMPLATE,
      outerLinkMsg: 'brief_template.field.campaign_type.sample_brief',
      outerLinkCb: sendEvent,
    },
  ];
  return result;
};

const platformsList = [
  {
    id: INSTAGRAM,
    value: INSTAGRAM,
    titleMsg: 'brief_template.field.campaign_platform.instagram',
    iconName: 'instagramColored',
    dataTest: 'button:platform:instagram',
  },
  {
    id: TIKTOK,
    value: TIKTOK,
    titleMsg: 'brief_template.field.campaign_platform.tiktok',
    iconName: 'tiktokColored',
    dataTest: 'button:platform:tikTok',
  },
  {
    id: FACEBOOK,
    value: FACEBOOK,
    titleMsg: 'brief_template.field.campaign_platform.facebook',
    iconName: 'facebookColored',
    dataTest: 'button:platform:facebook',
  },
];

export type strategyType =
  | 'ugc'
  | 'influencer'
  | 'productSeeding'
  | 'sparkAds'
  | 'metaAds'
  | 'affiliate'
  | 'tiktokshop';

const strategyPlatformMap: Omit<Record<CampaignUseCase, Array<string>>, '%future added value'> = {
  USER_GENERATED_CONTENT: [INSTAGRAM, TIKTOK, FACEBOOK, OTHER],
  INFLUENCER_POSTS: [INSTAGRAM, FACEBOOK, TIKTOK],
  PRODUCT_SEEDING: [INSTAGRAM, FACEBOOK, TIKTOK],
  TIKTOK_SPARK_ADS: [TIKTOK],
  META_PARTNERSHIP_ADS: [INSTAGRAM, FACEBOOK],
  AFFILIATE_CAMPAIGN: [INSTAGRAM, FACEBOOK, TIKTOK],
  TIKTOK_SHOP: [TIKTOK],
};

export const getPlatformsList = ({
  currency,
  isCreatorAds,
  useCase,
}: {
  currency?: Currency;
  isCreatorAds?: boolean;
  useCase?: CampaignUseCase | null;
}): ControlItem[] => {
  if (useCase !== undefined && useCase !== '%future added value' && useCase !== null) {
    return [
      ...platformsList,
      {
        id: OTHER,
        value: OTHER,
        titleMsg: 'brief_template.field.campaign_platform.other',
        iconName: 'otherPlatform',
        dataTest: 'button:platform:other',
      },
    ].filter((platform) => strategyPlatformMap[useCase].includes(platform.id));
  }
  return compact([
    ...platformsList,
    ...(!isCreatorAds
      ? [
          {
            id: OTHER,
            value: OTHER,
            titleMsg: 'brief_template.field.campaign_platform.other',
            iconName: 'otherPlatform',
            dataTest: 'button:platform:other',
          },
        ]
      : []),
  ]);
};

export const getPaymentMethodsList = (data: { campaignId: string }): ControlItem[] => {
  const { campaignId } = data;

  return [
    {
      id: MONEY_TYPE,
      value: MONEY_TYPE,
      titleMsg: 'brief_template.field.campaign_payment_method.money',
      tooltipMsg: 'brief_template.field.campaign_payment_method.money.tooltip',
      tooltipEventData: {
        id: '71',
        category: 'brief',
        name: 'campaign_payment_method_tooltip_show',
        param: { info: 'Money', campaignId },
      },
    },
    {
      id: BARTER_TYPE,
      value: BARTER_TYPE,
      titleMsg: 'brief_template.field.campaign_payment_method.barter',
      tooltipMsg: 'brief_template.field.campaign_payment_method.barter.tooltip',
      tooltipEventData: {
        id: '71',
        category: 'brief',
        name: 'campaign_payment_method_tooltip_show',
        param: { info: 'Product', campaignId },
      },
    },
  ];
};

export const getCommissionTypesList = (): ControlItem[] => [
  {
    id: COMMISSION_DISABLED_TYPE,
    value: COMMISSION_DISABLED_TYPE,
    titleMsg: 'brief_template.field.campaign_comission.disabled',
    dataTest: 'button:noCommission',
  },
  {
    id: COMMISSION_ENABLED_TYPE,
    value: COMMISSION_ENABLED_TYPE,
    titleMsg: 'brief_template.field.campaign_comission.enabled',
    dataTest: 'button:includeCommission',
  },
];

export const getCreatorsAmountList = (): ControlItem[] => {
  return [
    {
      id: '1-4',
      value: '1-4',
      titleText: '<5',
      dataTest: 'button:creatorsToHireAmount:<5',
    },
    {
      id: '5-10',
      value: '5-10',
      titleText: '5-10',
      dataTest: 'button:creatorsToHireAmount:5-10',
    },
    {
      id: '11-20',
      value: '11-20',
      titleText: '10-20',
      dataTest: 'button:creatorsToHireAmount:10-20',
    },
    {
      id: '21-100',
      value: '21-100',
      titleText: '>20',
      dataTest: 'button:creatorsToHireAmount:>20',
    },
  ];
};

export const getCurrentCreatorsAmountValue = (
  creatorsCountFrom: number | null | undefined,
  creatorsCountTo: number | null | undefined
) => {
  if (creatorsCountTo) {
    if (creatorsCountTo > 20) {
      return '21-100';
    } else if (creatorsCountTo > 10 && creatorsCountTo < 21) {
      return '11-20';
    } else if (creatorsCountTo > 4 && creatorsCountTo < 11) {
      return '5-10';
    }

    return '1-4';
  } else if (creatorsCountFrom) {
    if (creatorsCountFrom > 20) {
      return '21-100';
    } else if (creatorsCountFrom > 10 && creatorsCountFrom < 21) {
      return '11-20';
    } else if (creatorsCountFrom > 4 && creatorsCountFrom < 11) {
      return '5-10';
    }

    return '1-4';
  }

  return undefined;
};

export const getObjectivesList = (data: { campaignId: string }): ControlItem[] => {
  const { campaignId } = data;

  return [
    {
      id: 'CONVERSIONS',
      value: 'CONVERSIONS',
      titleMsg: 'brief_template.field.campaign_objective.conversions',
      tooltipMsg: 'brief_template.field.campaign_objective.conversions.tooltip',
      dataTest: 'button:campaignObjective:conversions',
      tooltipEventData: {
        id: '73',
        category: 'brief',
        name: 'campaign_objective_tooltip_show',
        param: { info: 'conversions', campaignId },
      },
    },
    {
      id: 'REACH',
      value: 'REACH',
      titleMsg: 'brief_template.field.campaign_objective.reach',
      tooltipMsg: 'brief_template.field.campaign_objective.reach.tooltip',
      dataTest: 'button:campaignObjective:reach',
      tooltipEventData: {
        id: '73',
        category: 'brief',
        name: 'campaign_objective_tooltip_show',
        param: { info: 'reach', campaignId },
      },
    },
  ];
};

export type GoalType = 'UGC' | 'REACH' | 'CONVERSATION';

export const getGoalList = (data: { campaignId: string }): ControlItem<CampaignObjective>[] => {
  const { campaignId } = data;

  return [
    {
      id: 'JUST_CONTENT',
      value: 'JUST_CONTENT',
      titleMsg: 'campaign.goal.option.ugc',
      tooltipMsg: 'campaign.goal.option.ugc.tooltip',
      dataTest: 'button:campaignObjective:conversions',
    },
    {
      id: 'REACH',
      value: 'REACH',
      titleMsg: 'campaign.goal.option.awarenessReach',
      tooltipMsg: 'campaign.goal.option.awarenessReach.tooltip',
    },
    {
      id: 'CONVERSIONS',
      value: 'CONVERSIONS',
      titleMsg: 'campaign.goal.option.convSales',
      tooltipMsg: 'campaign.goal.option.convSales.tooltip',
    },
  ];
};

export const getUseCaseList = (
  goalType: CampaignObjective | undefined | null,
  availableUseCases: CampaignUseCase[],
  amplitudeCb?: (useCase: CampaignUseCase) => void,
  getTooltipContent?: FC,
  organization?: Record<string, unknown> | null
): ControlItem<CampaignUseCase>[] => {
  switch (goalType) {
    case 'JUST_CONTENT':
      return [
        {
          id: 'USER_GENERATED_CONTENT',
          value: 'USER_GENERATED_CONTENT',
          titleMsg: 'campaign.strategy.ugc.title',
          subtitleMsg: 'campaign.strategy.ugc.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: USER_GENERATED_CONTENT_SAMPLE_LINK,
          outerLinkCb: () => amplitudeCb?.('USER_GENERATED_CONTENT'),
        },
      ];
    case 'REACH':
      return [
        {
          id: 'INFLUENCER_POSTS',
          value: 'INFLUENCER_POSTS',
          titleMsg: 'campaign.strategy.influencerPosts.title',
          subtitleMsg: 'campaign.strategy.influencerPosts.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: INFLUENCER_POSTS_SAMPLE_LINK,
          outerLinkCb: () => amplitudeCb?.('INFLUENCER_POSTS'),
        },
        {
          id: 'PRODUCT_SEEDING',
          value: 'PRODUCT_SEEDING',
          titleMsg: 'campaign.strategy.productSeeding.title',
          subtitleMsg: 'campaign.strategy.productSeeding.description',
          outerLinkMsg: 'campaign.stategy.sample',
          tooltipGetContent: getTooltipContent,
          disabled: !availableUseCases.includes('PRODUCT_SEEDING'),
          outerLink: PRODUCT_SEEDING_SAMPLE_LINK,
          tooltipIcon: 'Lock',
          outerLinkCb: () => amplitudeCb?.('PRODUCT_SEEDING'),
        },
        {
          id: 'TIKTOK_SPARK_ADS',
          value: 'TIKTOK_SPARK_ADS',
          titleMsg: 'campaign.strategy.sparkAds.title',
          subtitleMsg: 'campaign.strategy.sparkAds.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: TIKTOK_SPARK_ADS_SAMPLE_LINK,
          outerLinkCb: () => amplitudeCb?.('TIKTOK_SPARK_ADS'),
        },
        {
          id: 'META_PARTNERSHIP_ADS',
          value: 'META_PARTNERSHIP_ADS',
          titleMsg: 'campaign.strategy.metaAds.title',
          subtitleMsg: 'campaign.strategy.metaAds.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: META_PARTNERSHIP_ADS_SAMPLE_LINK,
          outerLinkCb: () => amplitudeCb?.('META_PARTNERSHIP_ADS'),
        },
      ];
    case 'CONVERSIONS':
      return [
        {
          id: 'AFFILIATE_CAMPAIGN',
          value: 'AFFILIATE_CAMPAIGN',
          titleMsg: 'campaign.strategy.affiliate.title',
          subtitleMsg: 'campaign.strategy.affiliate.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: AFFILIATE_CAMPAIGN_SAMPLE_LINK,
          tooltipGetContent: getTooltipContent,
          disabled: !availableUseCases.includes('AFFILIATE_CAMPAIGN'),
          tooltipIcon: 'Lock',
          outerLinkCb: () => amplitudeCb?.('AFFILIATE_CAMPAIGN'),
        },
        {
          id: 'TIKTOK_SHOP',
          value: 'TIKTOK_SHOP',
          titleMsg: 'campaign.strategy.tiktokshop.title',
          subtitleMsg: 'campaign.strategy.tiktokshop.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: TIKTOK_SHOP_SAMPLE_LINK,
          tooltipGetContent: getTooltipContent,
          disabled: !availableUseCases.includes('TIKTOK_SHOP'),
          plane: 'Trial',
          tooltipIcon: 'Lock',
          outerLinkCb: () => amplitudeCb?.('TIKTOK_SHOP'),
          authorizationCompleted: Boolean(organization?.tiktokShopLinked),
          authorizationType: 'tiktokshop',
        },
        {
          id: 'TIKTOK_SPARK_ADS',
          value: 'TIKTOK_SPARK_ADS',
          titleMsg: 'campaign.strategy.sparkAds.title',
          subtitleMsg: 'campaign.strategy.sparkAds.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: TIKTOK_SPARK_ADS_SAMPLE_LINK,
          outerLinkCb: () => amplitudeCb?.('TIKTOK_SPARK_ADS'),
        },
        {
          id: 'META_PARTNERSHIP_ADS',
          value: 'META_PARTNERSHIP_ADS',
          titleMsg: 'campaign.strategy.metaAds.title',
          subtitleMsg: 'campaign.strategy.metaAds.description',
          outerLinkMsg: 'campaign.stategy.sample',
          outerLink: META_PARTNERSHIP_ADS_SAMPLE_LINK,
          outerLinkCb: () => amplitudeCb?.('META_PARTNERSHIP_ADS'),
        },
      ];
    default:
      return [];
  }
};

const checkPriceRangeValid = (priceLimitMin?: number | null, priceLimitMax?: number | null) => {
  if (priceLimitMin && priceLimitMin <= MIN_PRICE_AMOUNT) {
    return false;
  } else if (priceLimitMin && priceLimitMax && priceLimitMin >= priceLimitMax) {
    return false;
  }

  if (priceLimitMax && priceLimitMax <= MIN_PRICE_AMOUNT) {
    return false;
  } else if (priceLimitMax && priceLimitMax > MAX_PRICE_AMOUNT) {
    return false;
  }
  if (!priceLimitMax) {
    return false;
  }
  return true;
};

export const checkValidation = (
  campaign: CampaignDetails_campaign$data,
  newCases: boolean
): string[] => {
  const { brand, name, type, useCase, platform, paymentType, brief } = campaign;
  const priceLimitMin = brief?.priceLimitMin;
  const priceLimitMax = brief?.priceLimitMax;
  const priceRangeAmountValid =
    campaign.type === 'PRODUCT_SEEDING'
      ? true
      : (newCases && (useCase === 'AFFILIATE_CAMPAIGN' || useCase === 'TIKTOK_SHOP')) ||
        checkPriceRangeValid(priceLimitMin, priceLimitMax);

  const priceFilled = priceLimitMin ? !!priceLimitMax : true;
  const hasCases = !newCases || (newCases && !!campaign.useCase);
  const creatorAdsInstagramFilled =
    campaign?.postingType === 'CREATOR_ADS' && campaign?.platform === 'INSTAGRAM'
      ? !!(
          (campaign?.brief?.paidSocial && campaign?.brief?.paidSocialActivationDays) ||
          campaign.brief?.paidSocialBca
        )
      : true;
  const creatorAdsTiktokFilled =
    campaign?.postingType === 'CREATOR_ADS' && campaign?.platform === 'TIKTOK'
      ? !!campaign?.brief?.sparkAdsActivationDays
      : true;
  const referralFeeFilled =
    campaign?.type === 'CONTENT_CREATION' ||
    !campaign?.brief?.includeReferralFee ||
    (!!campaign?.brief?.referralFeeRate &&
      campaign?.brief?.referralFeeRate > 0 &&
      campaign?.brief?.referralFeeRate < 101 &&
      campaign?.brief?.referralFeeRate % 1 === 0);

  const creatorsCountValid = brief?.creatorsCountFrom || brief?.creatorsCountTo;

  const errors = compact([
    brand?.id ? undefined : 'brand',
    name ? undefined : 'name',
    type ? undefined : 'type',
    hasCases ? undefined : 'useCases',
    paymentType ? undefined : 'paymentType',
    platform ? undefined : 'platform',
    creatorAdsInstagramFilled ? undefined : 'creatorAdsIg',
    creatorAdsTiktokFilled ? undefined : 'creatorAdsTiktok',
    creatorsCountValid ? undefined : 'creatorsCount',
    priceRangeAmountValid ? undefined : 'priceRange',
    priceFilled ? undefined : 'priceRange',
    referralFeeFilled ? undefined : 'fee',
  ]);

  return errors;
};

type ConditionType = {
  campaign: CampaignDetails_campaign$data;
  canUsePaidSocial?: boolean;
  showSparkAdsAwareness?: boolean;
  canUseNewCases?: boolean;
  useCase?: CampaignUseCase | null;
  withComission?: boolean;
};

export const getViewConditions = (params: ConditionType) => {
  const { campaign, showSparkAdsAwareness, withComission, canUseNewCases, useCase } = params;

  const isPriceRangeValid =
    checkPriceRangeValid(campaign.brief?.priceLimitMin, campaign.brief?.priceLimitMax) ||
    (withComission && !campaign.brief?.priceLimitMax && !campaign.brief?.priceLimitMin);

  const platformVisible =
    (campaign.type && campaign.brand?.id && campaign.name) || showSparkAdsAwareness;
  const partnershipDetailsVisible =
    (campaign.platform === 'FACEBOOK' || campaign.platform === 'INSTAGRAM') &&
    campaign?.postingType === 'CREATOR_ADS' &&
    campaign.type === 'INFLUENCER';
  const productSeedingDetailsVisible = campaign.type === PRODUCT_SEEDING;
  const sparkAdsVisible =
    (platformVisible || showSparkAdsAwareness) &&
    campaign.platform === 'TIKTOK' &&
    campaign.type === 'INFLUENCER' &&
    campaign.postingType === 'CREATOR_ADS';
  const psActive =
    !!(campaign.brief?.paidSocial && campaign?.brief?.paidSocialActivationDays) ||
    campaign.brief?.paidSocialBca;
  const creatorsAmountVisible =
    platformVisible &&
    campaign.platform &&
    (campaign?.postingType === 'CREATOR_ADS' && campaign?.platform === 'INSTAGRAM'
      ? !!psActive
      : true) &&
    (campaign?.postingType === 'CREATOR_ADS' && campaign?.platform === 'TIKTOK'
      ? !!campaign?.brief?.sparkAdsActivationDays
      : true);
  const priceRangeVisible =
    (creatorsAmountVisible &&
      campaign.type !== PRODUCT_SEEDING &&
      (campaign.brief?.creatorsCountFrom || campaign.brief?.creatorsCountTo)) ||
    (canUseNewCases &&
      !!campaign?.objective &&
      !!campaign?.useCase &&
      campaign.type !== PRODUCT_SEEDING);
  const objectiveVisible = (() => {
    if (canUseNewCases) {
      return true;
    }
    if (campaign.type === PRODUCT_SEEDING) {
      return (
        creatorsAmountVisible &&
        (campaign.brief?.creatorsCountFrom || campaign.brief?.creatorsCountTo)
      );
    }
    return (
      creatorsAmountVisible &&
      (campaign.brief?.creatorsCountFrom || campaign.brief?.creatorsCountTo) &&
      campaign.type === 'INFLUENCER'
    );
  })();

  const comissionAvailable =
    ((campaign.type === PRODUCT_SEEDING && objectiveVisible) ||
      (campaign.type !== PRODUCT_SEEDING &&
        isPriceRangeValid &&
        (campaign.type === 'INFLUENCER' ||
          campaign.type === 'PAYOUT' ||
          campaign.type === CONTENT_CREATION))) &&
    (!useCase || (!!useCase && useCase !== 'AFFILIATE_CAMPAIGN' && useCase !== 'TIKTOK_SHOP'));

  const commissionInputVisible =
    (campaign.type === 'CONTENT_CREATION' ||
      campaign.type === 'INFLUENCER' ||
      campaign.type === 'PAYOUT' ||
      campaign.type === 'PRODUCT_SEEDING') &&
    !!campaign?.brief?.includeReferralFee;

  const showUseCases = canUseNewCases && !!campaign?.objective;

  const maxLicensedCreators =
    campaign.organization?.subscription?.effectiveLimits.maxLicensedCreators;

  return {
    platform: !!platformVisible,
    sparkAdsDetaild: !!sparkAdsVisible,
    creatorsAmount: !!creatorsAmountVisible,
    priceRange: !!priceRangeVisible,
    objective: !!objectiveVisible,
    partnershipDetails: !!partnershipDetailsVisible,
    comissionAvailable: !!comissionAvailable,
    commissionInputVisible: !!commissionInputVisible,
    productSeedingDetailsVisible: !!productSeedingDetailsVisible,
    newCases: !!showUseCases,
    wlAddonsBanner:
      !!creatorsAmountVisible &&
      campaign?.postingType === 'CREATOR_ADS' &&
      (campaign.platform === 'INSTAGRAM' || campaign.platform === 'FACEBOOK') &&
      typeof maxLicensedCreators === 'number',
  };
};
