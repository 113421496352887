/**
 * @generated SignedSource<<3d3798d1ff7709297f25a0c0c5f3ce6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CampaignStageFilterQuery$variables = Record<PropertyKey, never>;
export type CampaignStageFilterQuery$data = {
  readonly activeCampaigns: {
    readonly totalCount: number;
  } | null | undefined;
  readonly completedCampaigns: {
    readonly totalCount: number;
  } | null | undefined;
  readonly draftCampaigns: {
    readonly totalCount: number;
  } | null | undefined;
};
export type CampaignStageFilterQuery = {
  response: CampaignStageFilterQuery$data;
  variables: CampaignStageFilterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": "activeCampaigns",
    "args": [
      {
        "kind": "Literal",
        "name": "stages",
        "value": [
          "ACTIVE",
          "IN_REVIEW"
        ]
      }
    ],
    "concreteType": "CampaignConnection",
    "kind": "LinkedField",
    "name": "campaigns",
    "plural": false,
    "selections": (v0/*: any*/),
    "storageKey": "campaigns(stages:[\"ACTIVE\",\"IN_REVIEW\"])"
  },
  {
    "alias": "draftCampaigns",
    "args": [
      {
        "kind": "Literal",
        "name": "stages",
        "value": [
          "DRAFT"
        ]
      }
    ],
    "concreteType": "CampaignConnection",
    "kind": "LinkedField",
    "name": "campaigns",
    "plural": false,
    "selections": (v0/*: any*/),
    "storageKey": "campaigns(stages:[\"DRAFT\"])"
  },
  {
    "alias": "completedCampaigns",
    "args": [
      {
        "kind": "Literal",
        "name": "stages",
        "value": [
          "COMPLETED"
        ]
      }
    ],
    "concreteType": "CampaignConnection",
    "kind": "LinkedField",
    "name": "campaigns",
    "plural": false,
    "selections": (v0/*: any*/),
    "storageKey": "campaigns(stages:[\"COMPLETED\"])"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CampaignStageFilterQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CampaignStageFilterQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ffa701ca7b4a37b629287147092852dc",
    "id": null,
    "metadata": {},
    "name": "CampaignStageFilterQuery",
    "operationKind": "query",
    "text": "query CampaignStageFilterQuery {\n  activeCampaigns: campaigns(stages: [ACTIVE, IN_REVIEW]) {\n    totalCount\n  }\n  draftCampaigns: campaigns(stages: [DRAFT]) {\n    totalCount\n  }\n  completedCampaigns: campaigns(stages: [COMPLETED]) {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "91addd41ce62713ff8acbc493059798b";

export default node;
