import React from 'react';

import { InviteOutreachCreatorsProvider } from 'Modal/admin/InviteOutreachCreators/InviteOutreachCreatorsProvider';
import InviteOutreachCreators from 'Modal/admin/InviteOutreachCreators/InviteOutreachCreators';
import { useNavigate } from 'react-router-dom';

const CreateOutreachBatch: React.FC = () => {
  const navigate = useNavigate();
  const handleUploadCsv = () => {
    navigate(-1);
  };

  return (
    <InviteOutreachCreatorsProvider handleUploadCsv={handleUploadCsv}>
      <InviteOutreachCreators />
    </InviteOutreachCreatorsProvider>
  );
};

export default CreateOutreachBatch;
