// general

export const ADD_SEARCH_PARAMS_DATA = 'ADD_SEARCH_PARAMS_DATA';

// session

export const SIGNIN = 'SIGNIN';

// modal
export const SET_MODAL = 'SET_MODAL';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';

//user

export const UPDATE_CURRENT_USER_DATA = 'UPDATE_CURRENT_USER_DATA';
export const CLEAR_CURRENT_USER_DATA = 'CLEAR_CURRENT_USER_DATA';

//stripe

export const PRICE_CHANGE_REQUEST = 'PRICE_CHANGE_REQUEST';

// create campaign

export const ADD_NEW_BRAND = 'ADD_NEW_BRAND';
export const ADD_NEW_REFERENCE = 'ADD_NEW_REFERENCE';

// brief

export const SAVING_BRIEF_STATUS = 'SAVING_BRIEF_STATUS';

// creator

export const ADD_OFFER_REACTION_DATA = 'ADD_OFFER_REACTION_DATA';
export const ACCEPT_OFFER = 'ACCEPT_OFFER';

// UI

export const SHOW_HEADER = 'SHOW_HEADER';
export const SHOW_FOOTER = 'SHOW_FOOTER';
export const TOGGLE_PRELOADER = 'TOGGLE_PRELOADER';
export const TOGGLE_MOBILE_VIEW = 'TOGGLE_MOBILE_VIEW';

// FB

export const FB_USER_AUTH = 'FB_USER_AUTH';

// ADS MANAGER

export const FACEBOOK = 'facebook';
export const SNAPCHAT = 'snapchat';

// APPROVAL

export const APPROVAL_AUTH = 'APPROVAL_AUTH';
export const APPROVAL_SET_NEW_PASSWORD = 'APPROVAL_SET_NEW_PASSWORD';

export const UPDATE_MEDIAS_FOR_CREATIVE = 'UPDATE_MEDIAS_FOR_CREATIVE';

export const ADD_MEDIA_FOR_COMMENT = 'ADD_MEDIA_FOR_COMMENT';
export const REMOVE_MEDIA_FOR_COMMENT = 'REMOVE_MEDIA_FOR_COMMENT';

// CREATORS SEARCH

export const ADD_CREATOR_FOR_EXLUSION = 'ADD_CREATOR_FOR_EXLUSION';
export const REMOVE_CREATOR_FOR_EXLUSION = 'REMOVE_CREATOR_FOR_EXLUSION';
export const CLEAR_CREATORS_FROM_EXLUSION = 'CLEAR_CREATORS_FROM_EXLUSION';
export const ADD_CREATORS_TO_BULK = 'ADD_CREATORS_TO_BULK';
export const REMOVE_CREATOR_FROM_BULK = 'REMOVE_CREATOR_FROM_BULK';
export const START_CREATORS_BULK_ADDING = 'START_CREATORS_BULK_ADDING';
export const STOP_CREATORS_BULK_ADDING = 'STOP_CREATORS_BULK_ADDING';
export const TOGGLE_CREATORS_BULK_ADDING = 'TOGGLE_CREATORS_BULK_ADDING';
export const CLEAR_BULK_CREATORS_DATA = 'CLEAR_BULK_CREATORS_DATA';
export const SET_CREATORS_BULK_COUNT = 'SET_CREATORS_BULK_COUNT';

// Notifications

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

// References

export const ADD_REFERENCE_TO_QUEUE = 'ADD_REFERENCE_TO_QUEUE';
export const REMOVE_REFERENCE_TO_QUEUE = 'REMOVE_REFERENCE_TO_QUEUE';
export const CLEAR_ALL_REFERENCE_FROM_QUEUE = 'CLEAR_ALL_REFERENCE_FROM_QUEUE';
