import React, { useCallback, useMemo } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './OrganizationTeam.pcss';

import Page from 'Templates/Page/Page';
import { amplitude } from 'Helpers/amplitude';
import { setModal } from 'Actions/Modal.Actions';
import Text from 'Components/ui/Text/Text';
import Tag from 'Components/ui/Tag/Tag';
import Avatar from 'Atoms/Avatar/Avatar';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { modalName } from 'Types/modals';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { BILLING_ROUTE } from 'Constants/general';
import { OrganizationTeamQuery as QueryType } from 'GraphTypes/OrganizationTeamQuery.graphql';

const OrganizationTeamQuery = graphql`
  query OrganizationTeamQuery {
    currentUser {
      id
      organization {
        id
        name
        subscription {
          effectiveLimits {
            maxSeats
          }
        }
        members {
          edges {
            node {
              id
              email
              advertiserProfile {
                lastName
                firstName
              }
            }
          }
          totalCount
        }
      }
    }
  }
`;

const OrganizationTeam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useLazyLoadQuery<QueryType>(OrganizationTeamQuery, {});

  const organization = data?.currentUser?.organization;
  const organizationId = organization?.id;
  const organizationName = organization?.name;
  const members = organization?.members;
  const totalCount = members?.totalCount;
  const limitOfUsers = organization?.subscription?.effectiveLimits.maxSeats || null;
  const currentId = data?.currentUser?.id;

  const handleInviteClick = useCallback(() => {
    amplitude.sendEvent({
      id: 221,
      category: 'advertiser_settings',
      name: 'add_new_member_click',
    });
    dispatch(setModal(modalName.ADD_TEAMMATE, { organizationId, organizationName }));
  }, [organizationId, organizationName]);

  const handleAddMore = useCallback(() => {
    navigate(BILLING_ROUTE);
  }, [organizationId, organizationName]);

  if (!data) return null;

  const list = useMemo(() => {
    return members?.edges?.map((item) => {
      if (!item?.node) return null;

      const { id, email, advertiserProfile } = item.node;

      const name = advertiserProfile?.firstName && advertiserProfile.lastName;

      const label = name ? `${advertiserProfile.firstName} ${advertiserProfile.lastName}` : email;

      return (
        <div key={id} className={styles.member}>
          <Avatar size="40" letters={label} className={styles.avatar} />
          <div className={styles.names}>
            <Text type="sm" text={name || ''} />
            <Text type="md" text={email} className={styles.email} />
          </div>
          {currentId === id && (
            <Tag color="purple" msg="advertiser.settings.team.you" className={styles.tag} />
          )}
        </div>
      );
    });
  }, [members?.edges]);

  const isUsersCountOverLimit = Boolean(
    totalCount && typeof limitOfUsers === 'number' && limitOfUsers <= totalCount
  );

  return (
    <Page className={styles.root}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Text
            type="d2"
            msg="advertiser.settings.team.title"
            formatValues={{ count: totalCount || '' }}
          />

          {isUsersCountOverLimit ? (
            <Tooltip
              tooltipMsg={'advertiser.settings.team.invite.overlimit'}
              place={'top'}
              id={'advertiser.settings.team.invite.overlimit'}
            >
              <AlterButton
                icon="Add-plus-circle"
                onClick={handleAddMore}
                type="black"
                msg="advertiser.settings.team.invite.overlimit_button"
              />
            </Tooltip>
          ) : (
            <AlterButton
              icon="Add-plus-circle"
              onClick={handleInviteClick}
              type="black"
              msg="advertiser.settings.team.invite"
            />
          )}
        </div>
        <div className={styles.members}>
          <div className={styles.fields}>
            <Text type="sm" msg="advertiser.settings.team.name" className={styles.fieldName} />
          </div>
          {list}
        </div>
      </div>
    </Page>
  );
};

export default OrganizationTeam;
