import React, { ChangeEvent, useRef, useState } from 'react';
import styles from '../EditorControl.pcss';
import buttonStyles from '../../InviteOutreachCreators.pcss';
import Input from 'Components/ui/Input/Input';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Icon from 'Components/ui/Icon/Icon';
import classNames from 'classnames';
import { lightWebsiteUrl } from 'Util/validate';
import useOutsideClick from 'Hooks/useClickOutside';

interface Props {
  onClick: (url: string, text?: string) => void;
  saveSelection: () => void;
}

const AddLinkControl: React.FC<Props> = (props) => {
  const { onClick, saveSelection } = props;

  const [showLinkMenu, setShowLinkMenu] = useState(false);
  const [hrefUrl, setHrefUrl] = useState('');
  const [hrefText, setHrefText] = useState('');
  const [isFormValid, setFormValidStatus] = useState(true);

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const menuRef = useOutsideClick<HTMLDivElement>(() => {
    setShowLinkMenu(false);
  });

  const handleAddClick = () => {
    if (!hrefUrl.trim().length || !lightWebsiteUrl(hrefUrl)) {
      setFormValidStatus(false);
      return;
    }
    onClick(hrefUrl, hrefText);
    setHrefText('');
    setHrefUrl('');
    setShowLinkMenu(false);
  };

  const handleShowLinkMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (e.target !== buttonRef.current) return;
    saveSelection();
    setFormValidStatus(true);
    setShowLinkMenu((state) => !state);
  };

  const handleUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValidStatus(true);
    setHrefUrl(e.target.value);
  };

  const handleAltChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValidStatus(true);
    setHrefText(e.target.value);
  };

  return (
    <>
      <button
        ref={buttonRef}
        className={classNames(buttonStyles.control, buttonStyles.withIcon)}
        onClick={handleShowLinkMenu}
      >
        <Icon name="Attach-link" />
        <div
          ref={menuRef}
          className={classNames(styles.buttonMenu, { [styles.show]: showLinkMenu })}
        >
          <Input
            label="Link URL *"
            forceLabelShow
            hideCloseIcon
            bordered
            placeholder="https://..."
            labelClassName={styles.label}
            className={styles.menuInput}
            onChange={handleUrlChange}
            error={!isFormValid}
            value={hrefUrl}
          />
          <Input
            label="Link text"
            forceLabelShow
            hideCloseIcon
            bordered
            placeholder="Any text"
            labelClassName={styles.label}
            className={styles.menuInput}
            onChange={handleAltChange}
            value={hrefText}
          />
          <AlterButton
            hover={false}
            className={styles.button}
            text="Add"
            onClick={handleAddClick}
          />
        </div>
      </button>
    </>
  );
};

export default AddLinkControl;
