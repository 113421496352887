import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useDispatch } from 'react-redux';

import ExtraPaymentAction from '../../ExtraPaymentAction/ExtraPaymentAction';

import { amplitude } from 'Helpers/amplitude';
import { setModal } from 'Actions/Modal.Actions';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { modalName } from 'Types/modals';
import { CompletedStage_project$key } from 'GraphTypes/CompletedStage_project.graphql';

interface Props {
  project: CompletedStage_project$key | null;
}

const CompletedStage: React.FC<Props> = (props) => {
  const { project } = props;

  const data = useFragment(
    graphql`
      fragment CompletedStage_project on Project {
        id
        creator {
          id
        }
        campaign {
          id
          completion {
            id
          }
          canDuplicate
          organization {
            paymentAccount {
              balance
            }
          }
        }
        ...FbAdsAction_project
      }
    `,
    project
  );

  const creatorId = data?.creator?.id || '';
  const campaignId = data?.campaign?.id || '';
  const canDuplicate = data?.campaign?.canDuplicate;

  const dispatch = useDispatch();
  const handleStartNewDealClick = useCallback(() => {
    amplitude.sendEvent<153>({
      id: '153',
      category: 'brand',
      name: 'start_new_deal_chat',
      param: { creator_id: creatorId, project_id: projectId, source: 'popup-chat' },
    });
    dispatch(setModal(modalName.START_NEW_DEAL, { campaignId, creatorId }));
  }, [creatorId, campaignId]);

  if (!data) {
    return null;
  }

  const projectId = data.id;
  const campaign = data.campaign;
  const campaignCompletion = campaign?.completion?.id;
  const balance = campaign.organization?.paymentAccount?.balance;

  return (
    <>
      {canDuplicate && (
        <AlterButton
          type="black"
          msg="creator_card.start_new_deal"
          icon="Add-plus-circle"
          onClick={handleStartNewDealClick}
        />
      )}
      {!campaignCompletion && (
        <ExtraPaymentAction isOldView={false} balance={balance} projectId={projectId} />
      )}
    </>
  );
};

export default CompletedStage;
