import { DeleteOutreachTemplateMutation } from 'GraphTypes/DeleteOutreachTemplateMutation.graphql';
import { ConnectionHandler, graphql, useMutation } from 'react-relay';

import type { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const DeleteOutreachTemplateMutation = graphql`
  mutation DeleteOutreachTemplateMutation($input: DeleteOutreachTemplateInput!) {
    deleteOutreachTemplate(input: $input) {
      __typename
      ... on Error {
        message
      }
    }
  }
`;

export const useDeleteOutreachTemplateMutation: BaseMutation<DeleteOutreachTemplateMutation> = (
  config
) => {
  const [deleteOutreachTemplate, loading] = useMutation<DeleteOutreachTemplateMutation>(
    DeleteOutreachTemplateMutation
  );

  const deleteOutreachTemplateMutationCaller: BaseMutationExecFn<DeleteOutreachTemplateMutation> = (
    execConfig
  ) => {
    deleteOutreachTemplate({
      ...config,
      ...execConfig,
      updater: (store) => {
        const id = execConfig?.variables.input.id;
        if (!id) return;
        const template = store.get(id);
        if (!template) return;
        const connection = store.get('client:root:outreachTemplates');

        if (connection) {
          ConnectionHandler.deleteNode(connection, id);
        }
      },
    });
  };
  return [deleteOutreachTemplateMutationCaller, loading];
};
