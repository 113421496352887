/**
 * @generated SignedSource<<d01792132c8513b301bce7c9576f7fa8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductDeliveryOption = "BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS" | "BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT" | "COLLABORATION_WILL_NOT_REQUIRE_PRODUCT_SHIPMENT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Campaigns_organization$data = {
  readonly campaigns: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly brief: {
          readonly productDelivery?: ProductDeliveryOption | null | undefined;
        } | null | undefined;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"Campaign_campaign">;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly pageInfo: {
      readonly endCursor: string | null | undefined;
      readonly hasNextPage: boolean;
    };
  } | null | undefined;
  readonly " $fragmentType": "Campaigns_organization";
};
export type Campaigns_organization$key = {
  readonly " $data"?: Campaigns_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Campaigns_organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 18,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "stages"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "textQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "campaigns"
        ]
      }
    ]
  },
  "name": "Campaigns_organization",
  "selections": [
    {
      "alias": "campaigns",
      "args": [
        {
          "kind": "Variable",
          "name": "stages",
          "variableName": "stages"
        },
        {
          "kind": "Variable",
          "name": "textQuery",
          "variableName": "textQuery"
        }
      ],
      "concreteType": "CampaignConnection",
      "kind": "LinkedField",
      "name": "__Campaigns_campaigns_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CampaignEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Campaign",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Campaign_campaign"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "brief",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "productDelivery",
                          "storageKey": null
                        }
                      ],
                      "type": "V2Brief",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "0c836e2f2950f7f546ebccfdd2e3df4d";

export default node;
