import React from 'react';

import styles from './RangeField.pcss';

import NewText from 'Components/ui/Text/Text';
import Text from 'Atoms/Text/Text';
import FieldItem from 'Molecules/Field/Field';
import TextButton from 'Atoms/TextButton/TextButton';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { FieldType, FieldElementType, TextButtonType, SmartLinkType } from 'Types/common';

interface Props {
  title: string;
  description: string;
  descriptionLinkData?: SmartLinkType & TextButtonType;
  isOptional?: boolean;
  minFiedlData: FieldType & FieldElementType;
  maxFiedlData: FieldType & FieldElementType;
  className?: string;
}

const RangeField: React.FC<Props> = (props) => {
  const {
    title,
    description,
    descriptionLinkData,
    isOptional,
    minFiedlData,
    maxFiedlData,
    className,
  } = props;

  const getLink = () => {
    if (!descriptionLinkData) return null;

    const { msg, ...rest } = descriptionLinkData;

    return (
      <SmartLink {...rest}>
        <TextButton msg={msg} size="sm" display="inline" />
      </SmartLink>
    );
  };

  return (
    <div className={`${styles.root} ${className}`}>
      <div className={styles.titleWrap}>
        <NewText type="md" msg={title} />
        {isOptional && (
          <NewText type="md" msg="brief_template.field.otional" className={styles.optional} />
        )}
      </div>
      <div className={styles.descr}>
        <NewText type="label" msg={description} />
        {getLink()}
      </div>
      <div className={styles.range}>
        <FieldItem {...minFiedlData} className={styles.field} />
        <Text text="—" className={styles.seperator} />
        <FieldItem {...maxFiedlData} className={styles.field} />
      </div>
    </div>
  );
};

export default RangeField;
