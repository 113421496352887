import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { useDispatch } from 'react-redux';

import styles from './Info.pcss';

import { setModal } from 'Actions/Modal.Actions';
import NewText from 'Components/ui/Text/Text';
import Text from 'Atoms/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import CopyToClipboard from 'Molecules/CopyToClipboard/CopyToClipboard';
import TeammatesInvitation from 'Organisms/TeammatesInvitation/TeammatesInvitation';
import { modalName } from 'Types/modals';
import { Info_organization$key } from 'GraphTypes/Info_organization.graphql';

interface Props {
  organization: Info_organization$key;
  el: JSX.Element;
}

const Info: React.FC<Props> = (props) => {
  const { organization, el } = props;

  const data = useFragment(
    graphql`
      fragment Info_organization on Organization {
        id
        name
        members {
          edges {
            node {
              id
              email
              advertiserProfile {
                firstName
                lastName
                phoneNumber
              }
            }
          }
        }
      }
    `,
    organization
  );

  const organizationName = data.name;
  const organizationId = data.id;

  const dispatch = useDispatch();

  const createMembers = () => {
    return data.members?.edges?.map((item) => {
      if (!item?.node) return null;
      const { id, email, advertiserProfile } = item.node;
      const firstName = advertiserProfile?.firstName || '';
      const lastName = advertiserProfile?.lastName || '';
      const phoneNumber = advertiserProfile?.phoneNumber;

      const emailText = `<${email}>`;
      const nameText = `${firstName} ${lastName}`;

      const handleMoveClick = () => {
        dispatch(setModal(modalName.TRANSFER_USER_TO_ORGANIZATION, { userId: id }));
      };

      return (
        <div key={id} className={styles.member}>
          <Text size="sm" text={nameText} className={styles.contact} />
          <div onClick={handleMoveClick}>
            <Tooltip id="email">
              <Text size="sm" color="grayDog" text={emailText} className={styles.email} />
            </Tooltip>
          </div>
          {phoneNumber && (
            <CopyToClipboard value={phoneNumber}>
              <Text size="sm" color="grayDog" text={phoneNumber} className={styles.phone} />
            </CopyToClipboard>
          )}
        </div>
      );
    });
  };

  const membersList = createMembers();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <NewText type="d2" text={organizationName} className={styles.title} />
        {el}
        <div className={styles.members}>
          <div className={styles.addUser}>
            <TeammatesInvitation
              organizationId={organizationId}
              organizationName={organizationName}
            />
          </div>
          {membersList}
        </div>
      </div>
    </div>
  );
};

export default Info;
