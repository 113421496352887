import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { SetupIntent, PaymentIntent, StripeError } from '@stripe/stripe-js';
import { useStripe } from '@stripe/react-stripe-js';
import find from 'lodash/find';

import styles from './ConfirmNewPlanContainer.pcss';
import ProrationPreview from './ProrationPreview/ProrationPreview';

import { Props as DrawerProps } from '../ConfirmNewPlan';

import { amplitude } from 'Helpers/amplitude';
import { toggleModal } from 'Actions/Modal.Actions';
import { ChangeSubscriptionPlanMutation$data } from 'GraphTypes/ChangeSubscriptionPlanMutation.graphql';
import { CreateSetupIntentMutation$data } from 'GraphTypes/CreateSetupIntentMutation.graphql';
import { ConfirmNewPlanQuery$data, AddonId } from 'GraphTypes/ConfirmNewPlanQuery.graphql';
import createSetupIntentMutation from 'Api/mutations/CreateSetupIntent.Mutation';
import { useChangeSubscriptionPlanMutation } from 'Api/mutations/ChangeSubscriptionPlan.Mutation';
import AttachPaymentMethodToSubscriptionMutation from 'Api/mutations/AttachPaymentMethodToSubscription.Mutation';
import PaymentMethodSelect from 'Modal/advertiser/PaymentMethodSelect/PaymentMethodSelect';
import TransactionFailed from 'Molecules/TransactionFailed/TransactionFailed';
import SubscriptonBought from 'Molecules/SubscriptonBought/SubscriptonBought';
import Text from 'Components/ui/Text/Text';
import Icon from 'Components/ui/Icon/Icon';
import Button from 'Components/ui/Button/Button';
import StripePaymentElement from 'Organisms/StripePaymentElement/StripePaymentElement';
import {
  PAYMENT_INTENT,
  REQUIRES_ACTION,
  REQUIRES_PAYMENT_METHOD,
  SETUP_INTENT,
  SUCCEEDED,
  SUCCESS,
} from 'Constants/general';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { PreviewProrationMutation$data } from 'GraphTypes/PreviewProrationMutation.graphql';

export interface Props {
  previewProration: PreviewProrationMutation$data;
  queryData?: ConfirmNewPlanQuery$data;
  errorMsg?: Error | StripeError;
  successCallback?: () => void;
}

const ConfirmNewPlanContainer: React.FC<Props & DrawerProps> = (props) => {
  const {
    queryData,
    addonItems,
    previewProration: prorationData,
    errorMsg,
    successCallback,
    successActionTitle,
  } = props;

  const { closeDrawer } = useContext(DrawerContext);

  const prorationPreview = prorationData?.stripe?.previewProration?.prorationPreview;
  const prorationPreviewId = prorationPreview?.id;
  const planId = prorationPreview?.planId;
  const interval = prorationPreview?.interval;

  const stripeCustomer = queryData?.currentUser?.organization?.stripeCustomer;
  const defaultPaymentMethod = stripeCustomer?.defaultPaymentMethod;

  const stripe = useStripe();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<'SUCCESS' | 'ERROR' | undefined>();
  const [error, setError] = useState<Error | StripeError | undefined>(errorMsg);
  const [clientSecret, setClientSecret] = useState<string>();
  const [shouldConfirmPayment, setShouldConfirmPayment] = useState<boolean>(false);
  const [paymentMethodSelectVisible, setPaymentMethodSelectVisible] = useState<boolean>(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!defaultPaymentMethod) showPaymentForm();
  }, [defaultPaymentMethod]);

  const [changeSubscriptionPlan, processing] = useChangeSubscriptionPlanMutation();

  const handleConfirmSuccess = (
    intent: PaymentIntent | SetupIntent,
    subscribeImmediately?: boolean
  ) => {
    if (intent) {
      const { status, payment_method, object } = intent;

      if (status?.toLowerCase() === SUCCEEDED && payment_method) {
        setError(undefined);

        if (object === SETUP_INTENT) {
          AttachPaymentMethodToSubscriptionMutation(
            { stripePaymentMethodId: payment_method as string },
            !subscribeImmediately ? attachMethodSuccess : handleChangeSubscription
          );
        } else if (object === PAYMENT_INTENT) {
          handleChangeSubscription();
        }
      }
    }
  };

  const attachMethodSuccess = () => {};

  const showPaymentForm = async () => {
    const setupIntent: CreateSetupIntentMutation$data = await new Promise((resolve, reject) => {
      createSetupIntentMutation({}, resolve, reject);
    });
    const token = setupIntent.stripe?.createSetupIntent?.setupIntent.clientSecret;

    if (token) setClientSecret(token);
  };

  const handleChangeSubscription = async () => {
    setLoading(true);

    if (prorationPreviewId) {
      changeSubscriptionPlan({
        variables: {
          input: {
            prorationPreviewId,
          },
        },
        onCompleted: (response) => {
          const currentAddons = queryData?.currentUser?.organization?.subscription?.addonItems;
          if (Number(addonItems?.length) > 0) {
            const newAddonsData = addonItems?.reduce<Partial<Record<AddonId, number>>>(
              (acc, item) => {
                const newQuantity = item.quantity;
                const addonName = item.addonId;
                const currentAddon = find(
                  currentAddons,
                  (addonItem) => addonItem.addon.id === addonName
                );
                const diff = currentAddon ? newQuantity - currentAddon.quantity : 0;
                acc[addonName] = diff;
                return acc;
              },
              {}
            );
            if (newAddonsData) {
              amplitude.sendEvent<368>({
                id: '368',
                category: 'billing',
                name: 'addon_added',
                param: newAddonsData,
              });
            }
            amplitude.sendEvent<371>({
              id: '371',
              category: 'billing',
              name: 'payment_complete',
              param: {
                type: 'update',
                product: newAddonsData ? 'addon' : 'plan',
              },
            });
          }
          changeSubscriptionPlanCallback(response);
        },
        onError: () => {
          setResult('ERROR');
        },
      });
    }
  };

  const changeSubscriptionPlanCallback = async (data: ChangeSubscriptionPlanMutation$data) => {
    const token = data?.stripe?.changeSubscriptionPlan?.clientSecret;
    const result = data?.stripe?.changeSubscriptionPlan?.result;

    setClientSecret(undefined);

    if (!result) {
      if (token) setClientSecret(token);

      return;
    }

    switch (result) {
      case REQUIRES_PAYMENT_METHOD: {
        setShouldConfirmPayment(true);
        if (token) setClientSecret(token);

        setError(new Error('Requires payment method'));
        setLoading(false);

        break;
      }
      case REQUIRES_ACTION: {
        if (!stripe || !token) {
          setResult('ERROR');

          break;
        }

        const { error, paymentIntent } = await stripe.confirmCardPayment(token);

        if (error) {
          setError(error);
          setLoading(false);
        } else {
          if (paymentIntent.status === SUCCEEDED) {
            setResult('SUCCESS');
          }
        }

        break;
      }
      case SUCCESS: {
        setResult('SUCCESS');

        break;
      }
      default: {
        setResult('ERROR');
      }
    }
  };

  const handleMethodEditClick = () => {
    setPaymentMethodSelectVisible(true);
  };

  const handleMethodSelectClose = () => {
    setPaymentMethodSelectVisible(false);
  };

  const handleDoneClick = () => {
    setResult(undefined);
    closeDrawer('confirm-new-plan');
  };

  const handleRetryClick = () => {
    setResult(undefined);
  };

  if (result === 'SUCCESS') {
    const planId = prorationData?.stripe?.previewProration?.prorationPreview?.planId;

    return (
      <SubscriptonBought
        submitMsg={successActionTitle}
        planId={planId}
        interval={interval}
        onSubmitClick={successCallback || handleDoneClick}
      />
    );
  }

  if (result === 'ERROR') {
    return <TransactionFailed onRetryClick={handleRetryClick} />;
  }

  return !paymentMethodSelectVisible ? (
    <>
      <Text type="d2" msg="confirm_new_plan_modal.title" className={styles.title} />
      <div className={styles.content}>
        {prorationData && (
          <ProrationPreview
            prorationData={prorationData}
            methodLast={defaultPaymentMethod?.last4}
            methodBrand={defaultPaymentMethod?.brand}
            onMethodEditClick={handleMethodEditClick}
          />
        )}
        {error && (
          <div className={styles.error}>
            <Text type="sm" text={error.message || JSON.stringify(error)} />
          </div>
        )}
        {clientSecret && (
          <StripePaymentElement
            className={styles.paymentForm}
            clientSecret={clientSecret}
            confirmPayment={shouldConfirmPayment}
            subscribeImmediately={true}
            onSuccess={handleConfirmSuccess}
            onFail={setError}
          />
        )}
      </div>
      {!clientSecret && (
        <div className={styles.buttons}>
          <Button
            color="black"
            className={styles.confirmBtn}
            loading={loading}
            disabled={!defaultPaymentMethod}
            msg="confirm_new_plan_modal.btn.confirm"
            onClick={handleChangeSubscription}
          />
        </div>
      )}
    </>
  ) : (
    <>
      <div className={styles.backButton} onClick={handleMethodSelectClose}>
        <Icon name="Arrow-small-left" />
      </div>
      <PaymentMethodSelect onBackClick={handleMethodSelectClose} className={styles.paymentSelect} />
    </>
  );
};

export default ConfirmNewPlanContainer;
