import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import classnames from 'classnames';

import styles from './ProjectActions.pcss';
import MsgField from './MsgField/MsgField';
import ContractorActions from './ContractorActions/ContractorActions';
import AdvertiserProjectActions from './AdvertiserProjectActions/AdvertiserProjectActions';
import StartNewDealAction from './StartNewDealAction/StartNewDealAction';
import ExtraPaymentAction from './ExtraPaymentAction/ExtraPaymentAction';
import ReimbursementPayment from './ReimbursementPaymentAction/ReimbursementPayment';
import ChangeDeadlineAction from './AdvertiserProjectActions/ChangeDeadlineAction/ChangeDeadlineAction';
import { AttachContextProvider } from './MsgField/Attach.Context';

import Text from 'Components/ui/Text/Text';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { ProjectActionsQuery as QueryType } from 'GraphTypes/ProjectActionsQuery.graphql';
import { LONG_TERM, ADVERTISER_LONG_TERM_INFO, CREATOR_LONG_TERM_INFO } from 'Constants/general';

const ProjectActionsQuery = graphql`
  query ProjectActionsQuery($id: ID!) {
    project(id: $id) {
      id
      archivation {
        id
        side
      }
      completion {
        id
      }
      campaign {
        id
        type
        canDuplicate
        completion {
          id
        }
        organization {
          paymentAccount {
            balance
          }
        }
      }
      creator {
        id
      }
      brief {
        ... on V2Brief {
          productDelivery
        }
      }
      deadlineChangeRequest {
        draftDate
      }
      advertiserStage
      ...ContractorActions_project
      ...AdvertiserProjectActions_project
      ...ChangeDeadlineAction_project
    }
    currentUser {
      admin
      type
      organization {
        subscription {
          canCreateDeposits
          canUsePaidSocial
          canCreateMultipleDeals
          effectiveLimits {
            maxHiredCreators
            maxBcaConnections
          }
          planId
        }
      }
    }
  }
`;

interface Props {
  projectId: string;
  isOldView: boolean;
}

const ProjectActions: React.FC<Props> = (props) => {
  const { projectId, isOldView } = props;

  const queryProps = useLazyLoadQuery<QueryType>(ProjectActionsQuery, { id: projectId });

  if (!queryProps) return null;

  const project = queryProps?.project;

  if (!project) return null;

  const currentUser = queryProps?.currentUser;

  const avaliableActions = () => {
    if (project.deadlineChangeRequest) {
      return <ChangeDeadlineAction project={project} isOldView={isOldView} />;
    }
    if (!isOldView) {
      return <AdvertiserProjectActions currentUser={currentUser} project={project} />;
    }
    return null;
  };

  const userType = currentUser?.type;
  const isArchived = project?.archivation?.id;
  const advertiserStage = project?.advertiserStage;
  const campaignType = project.campaign.type;

  if (userType === 'CONTRACTOR') {
    return (
      <div>
        {campaignType === LONG_TERM && (
          <div className={styles.longTermDescription}>
            <Text type="label" msg="chat.project.long_term.creator.description" />
            <SmartLink path={CREATOR_LONG_TERM_INFO} className={styles.link}>
              <Text type="label" msg="general.read_more" />
            </SmartLink>
          </div>
        )}
        <ContractorActions project={project} />
      </div>
    );
  } else if (userType === 'ADVERTISER') {
    if (isArchived) return null;

    const canCreateMultipleDeals = currentUser?.organization?.subscription?.canCreateMultipleDeals;
    const campaignId = project.campaign.id;
    const creatorId = project.creator?.id;
    const canDuplicate = project.campaign.canDuplicate;

    const paymentAccount = project.campaign.organization?.paymentAccount;
    const balance = paymentAccount?.balance;

    const projectCompletion = !!project.completion?.id;
    const campaignCompletion = !!project.campaign.completion?.id;
    const productDelivery = project?.brief?.productDelivery;

    const isReimbursementPaymentAvailable =
      productDelivery === 'BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT' &&
      project.advertiserStage !== 'ACCEPTED' &&
      project.advertiserStage !== 'REJECTED' &&
      !projectCompletion &&
      isOldView;

    return (
      <>
        {campaignType === LONG_TERM && (
          <div className={styles.longTermDescription}>
            <Text type="label" msg="chat.project.long_term.advertiser.description" />
            <SmartLink path={ADVERTISER_LONG_TERM_INFO} className={styles.link}>
              <Text type="label" msg="chat.project.long_term.advertiser.link" />
            </SmartLink>
          </div>
        )}
        <div className={styles.controls}>
          {!!(
            isOldView &&
            advertiserStage === 'COMPLETED' &&
            campaignId &&
            creatorId &&
            canCreateMultipleDeals &&
            canDuplicate
          ) && (
            <StartNewDealAction
              creatorId={creatorId}
              campaignId={campaignId}
              projectId={projectId}
            />
          )}
          {isReimbursementPaymentAvailable && isOldView && (
            <ReimbursementPayment balance={balance} projectId={projectId} />
          )}
          {isOldView && projectCompletion && !campaignCompletion && (
            <ExtraPaymentAction balance={balance} projectId={projectId} />
          )}
        </div>
        <div className={classnames(styles.root)}>
          {avaliableActions()}
          <AttachContextProvider>
            <MsgField isOldView={isOldView} projectId={projectId} />
          </AttachContextProvider>
        </div>
      </>
    );
  }

  return null;
};

export default ProjectActions;
