import React, { ChangeEvent, useRef, useState } from 'react';
import styles from '../EditorControl.pcss';
import buttonStyles from '../../InviteOutreachCreators.pcss';
import Input from 'Components/ui/Input/Input';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Icon from 'Components/ui/Icon/Icon';
import classNames from 'classnames';
import { lightWebsiteUrl } from 'Util/validate';
import useOutsideClick from 'Hooks/useClickOutside';

interface Props {
  onClick: (url: string, alt?: string) => void;
  saveSelection: () => void;
}

const AddImageControl: React.FC<Props> = (props) => {
  const { onClick, saveSelection } = props;

  const [showImageMenu, setShowImageMenu] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [imgAlt, setImgAlt] = useState('');
  const [isFormValid, setFormValidStatus] = useState(true);

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const menuRef = useOutsideClick<HTMLDivElement>(() => {
    setShowImageMenu(false);
  });

  const handleAddClick = () => {
    if (!imgUrl.trim().length || !lightWebsiteUrl(imgUrl)) {
      setFormValidStatus(false);
      return;
    }
    onClick(imgUrl, imgAlt);
    setImgAlt('');
    setImgUrl('');
    setShowImageMenu(false);
  };

  const handleShowImageMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (e.target !== buttonRef.current) return;
    saveSelection();
    setFormValidStatus(true);
    setShowImageMenu((state) => !state);
  };

  const handleUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValidStatus(true);
    setImgUrl(e.target.value);
  };

  const handleAltChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValidStatus(true);
    setImgAlt(e.target.value);
  };

  return (
    <>
      <button
        ref={buttonRef}
        className={classNames(buttonStyles.control, buttonStyles.withIcon)}
        onClick={handleShowImageMenu}
      >
        <Icon name="Photo" />
        <div
          ref={menuRef}
          className={classNames(styles.buttonMenu, { [styles.show]: showImageMenu })}
        >
          <Input
            label="Image URL *"
            forceLabelShow
            hideCloseIcon
            bordered
            placeholder="https://..."
            labelClassName={styles.label}
            className={styles.menuInput}
            onChange={handleUrlChange}
            error={!isFormValid}
            value={imgUrl}
          />
          <Input
            label="Image alt"
            forceLabelShow
            hideCloseIcon
            bordered
            placeholder="Optional"
            labelClassName={styles.label}
            className={styles.menuInput}
            onChange={handleAltChange}
            value={imgAlt}
          />
          <AlterButton
            hover={false}
            className={styles.button}
            text="Add"
            onClick={handleAddClick}
          />
        </div>
      </button>
    </>
  );
};

export default AddImageControl;
