import React, { useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { useNavigate } from 'react-router-dom';

import styles from './SubscriptionLimits.pcss';
import SubscriptionLimit from './SubscriptionLimit/SubscriptionLimit';

import Text from 'Components/ui/Text/Text';
import Limit from 'Molecules/Limit/Limit';
import {
  SubscriptionLimits_organization$key,
  SubscriptionLimits_organization$data,
  AddonId,
} from 'GraphTypes/SubscriptionLimits_organization.graphql';
import { BRANDS_ROUTE, PS_LICENSES_ROUTE, USER_SETTINGS } from 'Constants/general';
import { ExtendedPlanId, PlanType, getPlans } from 'Page/advertiser/BillingPlans/data';

interface Props {
  organization: SubscriptionLimits_organization$key;
}

const SubscriptionLimits: React.FC<Props> = (props) => {
  const { organization } = props;
  const navigate = useNavigate();

  const data = useFragment(
    graphql`
      fragment SubscriptionLimits_organization on Organization {
        counters {
          activeBrands
          psLicensedCreators
          bcaLicensedCreators
        }
        subscription {
          planId
          availableAddonIds
          addonItems {
            addon {
              id
              resourcesPerUnit
            }
            quantity
          }
          paused
          interval
          effectiveLimits {
            maxActiveBrands
            maxLicensedCreators
            maxBcaConnections
            maxSeats
          }
          baseLimits {
            maxActiveBrands
            maxLicensedCreators
            maxBcaConnections
            maxSeats
          }
        }
        members {
          totalCount
        }
      }
    `,
    organization as any
  ) as SubscriptionLimits_organization$data;

  if (!data || !organization) return null;

  const subscription = data.subscription;
  const counters = data.counters;
  const teamCount = data.members?.totalCount || 0;

  if (!subscription || !counters) return null;

  const { activeBrands, bcaLicensedCreators } = counters;
  const { planId, interval, availableAddonIds } = subscription;
  const { maxActiveBrands, maxBcaConnections, maxSeats } = subscription.effectiveLimits;
  const {
    maxActiveBrands: defaultMaxActiveBrands,
    maxBcaConnections: defaultMaxBcaConnections,
    maxSeats: defaultMaxSeats,
  } = subscription.baseLimits;

  const isTrial = planId === 'TRIAL_2022_08' || planId === 'TRIAL_2024_03';
  const isFreePlan = planId === 'FREE' || planId === 'FREE_TRIAL';
  const isAddonAvailable = (addonKey: AddonId) => availableAddonIds?.includes(addonKey);

  const currentPlanData = useMemo<PlanType | undefined>(() => {
    const plans = getPlans({
      currency: 'USD',
      plansProps: {
        [planId as ExtendedPlanId]: {
          interval,
          addons: [],
        },
      },
    });
    return plans.find((plan) => plan.planId === planId);
  }, [planId]);

  const handleManageBrands = () => {
    navigate(BRANDS_ROUTE);
  };

  const handleManageSeats = () => {
    navigate(USER_SETTINGS, { state: { section: 'TEAM' } });
  };

  const handleManageLicenses = () => {
    navigate(PS_LICENSES_ROUTE);
  };

  if (!currentPlanData?.availableAddons) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Text type="h1" msg="billing.limits.title" className={styles.title} />
        <SubscriptionLimit
          titleMsg="billing.limits.brands.title"
          totalCount={activeBrands}
          maxCount={maxActiveBrands}
          paused={subscription.paused}
          onManageClick={handleManageBrands}
          type="maxActiveBrands"
          disabled={
            isTrial || isFreePlan || !isAddonAvailable('BRANDS') || maxActiveBrands === null
          }
        >
          <Limit
            type="maxActiveBrands"
            totalCount={activeBrands}
            maxCount={maxActiveBrands}
            addons={currentPlanData?.availableAddons}
            defaultLimit={defaultMaxActiveBrands}
            disabledManage={
              isTrial || isFreePlan || !isAddonAvailable('BRANDS') || maxActiveBrands === null
            }
            subscriptionData={subscription}
          />
        </SubscriptionLimit>
        <SubscriptionLimit
          titleMsg="billing.limits.seats.title"
          totalCount={teamCount}
          maxCount={maxSeats}
          onManageClick={handleManageSeats}
          type="maxSeats"
          paused={subscription.paused}
          disabled={isTrial || isFreePlan || !isAddonAvailable('SEATS') || maxSeats === null}
        >
          <Limit
            type="maxSeats"
            totalCount={teamCount}
            maxCount={maxSeats}
            addons={currentPlanData?.availableAddons}
            defaultLimit={defaultMaxSeats}
            disabledManage={
              isTrial || isFreePlan || !isAddonAvailable('SEATS') || maxSeats === null
            }
            subscriptionData={subscription}
          />
        </SubscriptionLimit>
        <SubscriptionLimit
          titleMsg="billing.limits.bca_licenses.title"
          totalCount={bcaLicensedCreators}
          maxCount={maxBcaConnections}
          onManageClick={handleManageLicenses}
          type="maxBcaConnections"
          paused={subscription.paused}
          disabled={
            isTrial ||
            isFreePlan ||
            maxBcaConnections === null ||
            !isAddonAvailable('WHITELISTING_LICENSES')
          }
        >
          <Limit
            type={'maxBcaConnections'}
            totalCount={bcaLicensedCreators}
            maxCount={maxBcaConnections}
            addons={currentPlanData?.availableAddons}
            defaultLimit={defaultMaxBcaConnections}
            disabledManage={
              isTrial ||
              isFreePlan ||
              maxBcaConnections === null ||
              !isAddonAvailable('WHITELISTING_LICENSES')
            }
            subscriptionData={subscription}
          />
        </SubscriptionLimit>
      </div>
    </div>
  );
};

export default SubscriptionLimits;
