import React, { Suspense, useEffect, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import styles from './ConfirmNewPlan.pcss';

import ConfirmNewPlanContainer from './ConfirmNewPlanContainer/ConfirmNewPlanContainer';

import Drawer from 'Components/ui/Drawer/Drawer';
import previewProrationMutation from 'Mutations/PreviewProration.Mutation';
import createSetupIntentMutation from 'Mutations/CreateSetupIntent.Mutation';
import { ConfirmNewPlanQuery as QueryType } from 'GraphTypes/ConfirmNewPlanQuery.graphql';
import { CreateSetupIntentMutation$data } from 'GraphTypes/CreateSetupIntentMutation.graphql';
import {
  PlanId,
  SubscriptionInterval,
  AddonItemInput,
  PreviewProrationMutation$data,
} from 'GraphTypes/PreviewProrationMutation.graphql';
import Spinner from 'Atoms/Spinner/Spinner';
import TransactionFailed from 'Molecules/TransactionFailed/TransactionFailed';

const STRIPE_KEY = process.env.STRIPE_KEY || '';
const stripePromise = loadStripe(STRIPE_KEY);

const ConfirmNewPlanQuery = graphql`
  query ConfirmNewPlanQuery {
    currentUser {
      organization {
        subscription {
          addonItems {
            addon {
              id
            }
            quantity
          }
        }
        stripeCustomer {
          defaultPaymentMethod {
            ... on Stripe_Card {
              id
              stripeId
              last4
              brand
            }
          }
          paymentMethods {
            edges {
              node {
                ... on Stripe_Card {
                  id
                  stripeId
                  brand
                  createdAt
                  expYear
                  expMonth
                  last4
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface Props {
  opened?: boolean;
  planId: PlanId;
  interval: SubscriptionInterval;
  promocode?: string | null;
  addonItems?: AddonItemInput[];
  onClose?: () => void;
  successCallback?: () => void;
  successActionTitle?: string;
}

const ConfirmNewPlan: React.FC<Props> = (props) => {
  const { planId, interval, promocode, addonItems, opened, onClose } = props;
  const [token, setToken] = useState<string>();
  const [error, setError] = useState<boolean>();
  const [previewProration, setPreviewProration] = useState<PreviewProrationMutation$data>();

  useEffect(() => {
    if (!token) createSetupIntentMutation({}, createSetupIntentSuccess);
    if (!previewProration) initPreviewProrationMutation();
  }, []);

  const initPreviewProrationMutation = () => {
    setError(false);
    previewProrationMutation(
      { planId, interval, promocode, addonItems },
      createPreviewProrationSuccess,
      createPreviewProrationFail
    );
  };

  const createPreviewProrationSuccess = (response: PreviewProrationMutation$data) => {
    setPreviewProration(response);
  };

  const createPreviewProrationFail = () => {
    setError(true);
  };

  const createSetupIntentSuccess = (data: CreateSetupIntentMutation$data) => {
    const clientSecret = data.stripe?.createSetupIntent?.setupIntent?.clientSecret;

    if (clientSecret) setToken(clientSecret);
  };

  const data = useLazyLoadQuery<QueryType>(ConfirmNewPlanQuery, {});

  const canShowContent = data && token && previewProration;

  return (
    <Drawer rootKey="confirm-new-plan" className={styles.drawer} opened={opened} onClose={onClose}>
      <div className={styles.drawerContainer}>
        {error && <TransactionFailed onRetryClick={initPreviewProrationMutation} />}
        {!canShowContent && !error && <Spinner className={styles.preloader} />}
        {canShowContent && !error && (
          <Suspense fallback={<Spinner className={styles.preloader} />}>
            <Elements stripe={stripePromise} options={{ clientSecret: token }}>
              <ConfirmNewPlanContainer
                previewProration={previewProration}
                queryData={data}
                {...props}
              />
            </Elements>
          </Suspense>
        )}
      </div>
    </Drawer>
  );
};

export default ConfirmNewPlan;
