import React, { useMemo, useContext, useEffect } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import BrandModal from 'Modal/advertiser/BrandModal/BrandModal';

import styles from './BrandsLimit.pcss';

import colors from 'Styles/vars/colors.css';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Spinner from 'Atoms/Spinner/Spinner';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Input from 'Components/ui/Input/Input';
import { amplitude } from 'Helpers/amplitude';
import { BrandsLimitQuery as QueryType, AddonId } from 'GraphTypes/BrandsLimitQuery.graphql';
import { BILLING_ROUTE, FREE_PLAN } from 'Constants/general';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { ExtendedPlanId, PlanType, getPlans } from 'Page/advertiser/BillingPlans/data';

const BrandsLimitQuery = graphql`
  query BrandsLimitQuery {
    brandCount: brands {
      totalCount
    }
    currentUser {
      admin
      organization {
        counters {
          activeBrands
        }
        subscription {
          planId
          availableAddonIds
          addonItems {
            addon {
              id
              resourcesPerUnit
            }
            quantity
          }
          paused
          interval
          effectiveLimits {
            maxActiveBrands
          }
          baseLimits {
            maxActiveBrands
          }
        }
      }
    }
    brandCategories {
      id
      name
    }
  }
`;

type Props = {
  increaseLimit: () => void;
  changePlan: () => void;
  onQueryChange: (text: string) => void;
  onLoadCompleted: () => void;
  onBuyAddonClick: () => void;
  brandName: string;
};

const BrandsLimit: React.FC<Props> = (props) => {
  const { increaseLimit, changePlan, brandName, onQueryChange, onBuyAddonClick, onLoadCompleted } =
    props;

  const { openDrawer } = useContext(DrawerContext);

  const data = useLazyLoadQuery<QueryType>(BrandsLimitQuery, {});

  useEffect(() => {
    if (data) {
      onLoadCompleted();
    }
  }, [data]);

  const activeCount = data?.currentUser?.organization?.counters?.activeBrands || 0;
  const totalCount =
    data?.currentUser?.organization?.subscription?.effectiveLimits.maxActiveBrands || null;
  const planId = data?.currentUser?.organization?.subscription?.planId || 'your';
  const interval = data.currentUser?.organization?.subscription?.interval;
  const isFreeUser = planId === FREE_PLAN;
  const isTrial = planId === 'TRIAL_2022_08' || planId === 'TRIAL_2024_03';
  const isPlanPaused = Boolean(data.currentUser?.organization?.subscription?.paused);
  const brandCategories = data?.brandCategories;

  const currentPlanData = useMemo<PlanType | undefined>(() => {
    const plans = getPlans({
      currency: 'USD',
      plansProps: {
        [planId as ExtendedPlanId]: {
          interval,
          addons: [],
        },
      },
    });
    return plans.find((plan) => plan.planId === planId);
  }, [planId]);

  const isLimitExcceded = totalCount && activeCount >= Number(totalCount);

  const addNewDisabled = Boolean(
    isLimitExcceded ||
      isFreeUser ||
      (totalCount && Number(totalCount) <= activeCount) ||
      isPlanPaused
  );

  const handleAddBrandClick = () => {
    if (isLimitExcceded) {
      return;
    }

    amplitude.sendEvent({
      id: '138',
      category: 'brand_management_dashboard',
      name: 'new_brand',
    });
    openDrawer('edit-brand-modal-new');
  };

  const counter = useMemo(() => {
    if (isFreeUser) {
      return <Text type="md" text=" " />;
    } else if (totalCount === 0 && activeCount === 0) {
      return <Spinner size={'sm'} color={colors.colorDark} />;
    } else if (totalCount === null) {
      return <Text type="md" msg={'brands.dashboard.limits.unlimited'} />;
    }

    return (
      <Text
        type="md"
        msg={'brands.dashboard.limits.counter'}
        formatValues={{
          activeBrands: activeCount,
          maxBrandsCount: totalCount,
        }}
        key={activeCount + totalCount}
      />
    );
  }, [isFreeUser, totalCount, activeCount]);

  const buyButton = useMemo(() => {
    if (isPlanPaused) return null;
    if (isFreeUser || isTrial) {
      return (
        <AlterButton
          fluid
          msg={'brands.dashboard.limits.changePlan'}
          type={'black'}
          onClick={changePlan}
          className={styles.actionBtn}
        />
      );
    } else if (totalCount !== null) {
      return (
        <AlterButton
          fluid
          msg={'brands.dashboard.limits.increase'}
          type={addNewDisabled ? 'black' : 'white'}
          onClick={increaseLimit}
          className={styles.actionBtn}
        />
      );
    }

    return null;
  }, [isFreeUser, totalCount]);

  const handleResumePlanClick = () => {
    amplitude.sendEvent<405>({
      id: '405',
      category: 'brand_management_dashboard',
      name: 'pause_subsciption_resume_click',
    });
  };

  if (!data) return null;

  const getContent = () => {
    if (isPlanPaused) {
      return (
        <Tooltip
          id="brands.dashboard.limits.newBrand.tooltip_pause"
          place="right"
          clickable
          customTooltip={
            <>
              <Text type="md" msg="brands.dashboard.limits.newBrand.tooltip_pause" />
              <a
                href={BILLING_ROUTE}
                className={styles.resumePlanTooltipLink}
                onClick={handleResumePlanClick}
              >
                <Text type="md" msg="brands.dashboard.limits.newBrand.tooltip_pause_button" />
              </a>
            </>
          }
        >
          <AlterButton
            msg="brands.dashboard.limits.newBrand"
            type="black"
            icon="Lock"
            iconPosition="left"
            disabled={true}
            className={styles.addBrandButton}
          />
        </Tooltip>
      );
    }

    if ((isFreeUser || isTrial) && isLimitExcceded) return buyButton;

    if (isLimitExcceded && currentPlanData?.availableAddons) {
      return (
        <AlterButton
          icon="Add-plus-circle"
          type="black"
          msg="brands.dashboard.limits.newBrand"
          className={styles.addBrandButton}
          onClick={onBuyAddonClick}
        />
      );
    }

    return (
      <AlterButton
        msg="brands.dashboard.limits.newBrand"
        type="black"
        onClick={handleAddBrandClick}
        icon={isPlanPaused ? 'Lock' : 'Add-plus-circle'}
        iconPosition="left"
        className={styles.addBrandButton}
      />
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.progressTitle}>{counter}</div>
      <div className={styles.row}>
        <Input
          placeholderMsg="brands.dashboard.search.placeholder"
          handleChange={onQueryChange}
          value={brandName}
          className={styles.search}
        />
        <div className={styles.controls}>{getContent()}</div>
      </div>
      <BrandModal id="new" brandCategories={brandCategories} />
    </div>
  );
};

export default BrandsLimit;
