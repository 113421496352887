import React, { useRef, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import styles from './AddAllCreatorsDrawer.pcss';
import AddAllByBrand from './AddAllByBrand/AddAllByBrand';

import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import Button from 'Components/ui/Button/Button';
import { useImportCreatorsBySearchQueryMutation } from 'Api/mutations/ImportCreatorsBySearchQuery.Mutation';
import { SearchResultsContainerQuery$variables } from 'GraphTypes/SearchResultsContainerQuery.graphql';
import ErrorHandler from 'Util/errorHandler';
import { CAMPAIGN_INVITED_ROUTE } from 'Constants/general';

interface Props {
  isAdmin: boolean;
  campaignId: string;
  searchParams: SearchResultsContainerQuery$variables['searchQueryInput'];
  opened: boolean;
  handleClose: () => void;
  creatorsCount: number;
}

const AddAllCreatorsDrawer: React.FC<Props> = (props) => {
  const { isAdmin, campaignId, searchParams, opened, handleClose, creatorsCount } = props;
  const usedSearchParams = useRef('');
  const navigate = useNavigate();

  const [status, setStatus] = useState<'success'>();

  const invitedTabUrl = generatePath(CAMPAIGN_INVITED_ROUTE, { id: campaignId });

  const [importCreatorsBySearchQuery, loading] = useImportCreatorsBySearchQueryMutation({
    variables: {
      input: {
        campaignId,
        searchQuery: { ...searchParams },
      },
    },
    onCompleted: (data, errors) => {
      if (errors) {
        ErrorHandler.error('importCreatorsBySearchQuery was failed', { errorData: errors });
        return alert('Something went wrong');
      }
      const response = data.importCreatorsBySearchQuery;
      if (
        response?.__typename === 'ImportCreatorsBySearchQueryPayload' &&
        response.importCreatorsBySearchQueryOperation.state === 'FAILURE'
      ) {
        ErrorHandler.error('importCreatorsBySearchQuery was failed', {
          errorData: response.importCreatorsBySearchQueryOperation,
        });
        return alert(
          `Something went wrong\n${response.importCreatorsBySearchQueryOperation.error}`
        );
      } else if (response?.__typename !== 'ImportCreatorsBySearchQueryPayload') {
        ErrorHandler.error('importCreatorsBySearchQuery was failed', {
          errorData: response,
        });
        return alert(`Something went wrong\n${response?.__typename}`);
      }
      setStatus('success');
    },
  });

  const handleAddAllCreatorsToCampaign = () => {
    usedSearchParams.current = JSON.stringify(searchParams || {});
    importCreatorsBySearchQuery();
  };

  const handleAddCreatorsByBrand = (count: number) => {
    importCreatorsBySearchQuery({
      variables: {
        input: {
          campaignId,
          count,
          searchQuery: { ...searchParams },
        },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          ErrorHandler.error('importCreatorsBySearchQuery was failed', { errorData: errors });
          return alert('Something went wrong');
        }
        setStatus('success');
      },
    });
  };

  const handleSuccessDrawerClose = () => {
    navigate(invitedTabUrl);
    handleClose();
  };

  const content = isAdmin ? (
    <>
      <div className={styles.content}>
        <Text type="d2" msg="browse_creators.add_all_drawer.title" />
        <Text
          type="md"
          msg="browse_creators.add_all_drawer.description"
          formatValues={{ creatorsCount }}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          color="black"
          fluid
          className={styles.mainBtn}
          disabled={JSON.stringify(searchParams) === usedSearchParams.current}
          loading={loading}
          msg="browse_creators.add_all_drawer.button"
          formatValues={{ creatorsCount }}
          onClick={handleAddAllCreatorsToCampaign}
        />
      </div>
    </>
  ) : (
    <AddAllByBrand
      campaignId={campaignId}
      creatorsCount={creatorsCount}
      onAddClick={handleAddCreatorsByBrand}
    />
  );

  const successContent = (
    <>
      <div className={styles.content}>
        <Text type="d2" msg="creators_bulk_adding_drawer.success.title" />
        <Text type="md" msg="creators_bulk_adding_drawer.success.descr" />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          color="black"
          fluid
          className={styles.mainBtn}
          msg="creators_bulk_adding_drawer.success.go_to"
          onClick={handleSuccessDrawerClose}
        />
      </div>
    </>
  );

  return (
    <Drawer
      opened={opened}
      onClose={handleClose}
      needCloseButton
      className={styles.root}
      rootKey="add_all_creators_to_campaign"
    >
      {status === 'success' ? successContent : content}
    </Drawer>
  );
};

export default AddAllCreatorsDrawer;
