import { CampaignStage, CampaignsQuery$data } from 'GraphTypes/CampaignsQuery.graphql';

export type Counters = NonNullable<
  NonNullable<CampaignsQuery$data['currentUser']>['organization']
>['counters'];

export const getBars = (stage?: CampaignStage, counters?: Counters | null) => {
  const newApplicants = counters?.newApplicants;
  const unreadInCompletedProjects = counters?.unreadMessagesInCompletedCampaigns;
  const unreadInUncompletedProjects = counters?.unreadMessagesInUncompletedCampaigns;
  const submittedContent = counters?.projectsWithSubmittedContent;

  switch (stage) {
    case 'ACTIVE':
      return [
        { msg: 'campaigns.bars.campaign', width: 332 },
        { msg: 'campaigns.bars.new_applications', width: 167, mark: !!newApplicants },
        { msg: 'campaigns.bars.new_messages', width: 167, mark: !!unreadInUncompletedProjects },
        { msg: 'campaigns.bars.review', width: 167, mark: !!submittedContent },
        { msg: 'campaigns.bars.status', width: 199 },
      ];
    case 'DRAFT':
      return [
        { msg: 'campaigns.bars.campaign', width: 332 },
        { msg: 'campaigns.bars.date_creation', width: 533 },
        { msg: 'campaigns.bars.status', width: 199 },
      ];
    case 'COMPLETED':
      return [
        { msg: 'campaigns.bars.campaign', width: 332 },
        { msg: 'campaigns.bars.completed', width: 167 },
        {
          msg: 'campaigns.bars.new_messages',
          width: 167,
          mark: !!unreadInCompletedProjects,
        },
        { msg: 'campaigns.bars.total_spent', width: 167 },
        { msg: 'campaigns.bars.status', width: 199 },
      ];

    default:
      return [];
  }
};
