import React, { useContext, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import track, { useTracking } from 'react-tracking';

import styles from './OnboardingByAdvertiser.pcss';

import MaxHiredCreatorsExceededDrawer from 'Modal/advertiser/MaxHiredCreatorsExceeded/MaxHiredCreatorsExceeded';
import launchProject from 'Mutations/LaunchProject.Mutation';
import { createSum } from 'Util/numberFormatter';
import { getOnboardingData } from 'Util/onboardingData';
import Text from 'Atoms/Text/Text';
import Button from 'Atoms/Button/Button';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import TopupBalanceDrawer from 'Modal/advertiser/TopupBalance/TopupBalance';
import { BARTER_TYPE, FREE_PLAN, PRODUCT_SEEDING } from 'Constants/general';
import { OnboardingByAdvertiser_project$key } from 'GraphTypes/OnboardingByAdvertiser_project.graphql';
import { StepperQuery$data } from 'GraphTypes/StepperQuery.graphql';
import { ProjectInfoContext } from 'Page/common/Chat/Dialog/ProjectInfo/ProjectInfo.Context';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { useTikTokShopAuth } from 'Hooks/useTikTokShopAuth';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';

interface Props {
  project: OnboardingByAdvertiser_project$key;
  hasShipment: boolean;
  currentUser: StepperQuery$data['currentUser'];
}

const OnboardingByAdvertiser: React.FC<Props> = (props) => {
  const { project, currentUser, hasShipment } = props;
  const { openDrawer } = useContext(DrawerContext);
  const {
    isMaxHiredCreatorsExceeded,
    subscription: { maxHiredCreators, planId },
  } = useContext(ProjectInfoContext);
  const { authorize: authorizeTiktokShop } = useTikTokShopAuth();
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);

  const data = useFragment(
    graphql`
      fragment OnboardingByAdvertiser_project on Project {
        id
        paidSocial
        creator {
          id
          type
          username
          ... on InstagramCreator {
            paidSocialEnabled
            user {
              profilePictureUrl
            }
          }
          ... on YoutubeCreator {
            channel {
              name
              thumbnailUrl
            }
          }
          ... on TiktokCreator {
            user {
              name
              avatar {
                secureUrl
              }
            }
          }
          profile {
            estimatedPrice
            currency
          }
        }
        price
        currency
        initialPrice
        initialCurrency
        campaign {
          id
          type
          useCase
          paymentType
          completion {
            id
          }
          organization {
            tiktokShopLinked
            paymentAccount {
              balance
              currency
            }
          }
        }
        archivation(side: ADVERTISER) {
          id
        }
        archivationByContractor: archivation(side: CONTRACTOR) {
          id
        }
      }
    `,
    project
  );

  const tracking = useTracking();

  const canCreateDeposits = currentUser?.organization?.subscription?.canCreateDeposits;
  const canUsePaidSocial = currentUser?.organization?.subscription?.canUsePaidSocial;

  const projectId = data.id;
  const paymentAccount = data.campaign.organization?.paymentAccount;
  const paidSocial = data.paidSocial && canUsePaidSocial;
  const creator = data.creator;
  const paidSocialEnabled = creator?.paidSocialEnabled;
  const currency = data.currency;
  const price = Number(data.price);
  const isBarter = data.campaign.paymentType === BARTER_TYPE;
  const campaignType = data.campaign.type;
  const isCampaignWithTiktokShop = data.campaign.useCase === 'TIKTOK_SHOP';
  const isTTShopAuthorized = data.campaign.organization?.tiktokShopLinked;

  const notProductSeeding = campaignType !== PRODUCT_SEEDING;

  const canOnboard = price === 0 || (price && canCreateDeposits);

  const shipmentPrefix = hasShipment ? '_with_shipment' : '';

  const balance = Number(paymentAccount?.balance);

  const needTopup = useMemo<boolean>(() => {
    if (campaignType === PRODUCT_SEEDING) {
      return false;
    }
    return Boolean(balance < price && currency);
  }, [balance, price, currency, campaignType]);

  const { finalAmount } = getOnboardingData(price, balance, 'USD');

  const onBoardBtnMsg = needTopup
    ? 'project.stepper.onboarding.advertiser.topup_hire'
    : `project.stepper.onboarding${shipmentPrefix}.advertiser.submit`;

  const handleOnboardClick = () => {
    tracking.trackEvent({ event: 'onboard_click' });

    if (isMaxHiredCreatorsExceeded) {
      openDrawer('max-hired-creators-exceeded');
      return;
    }
    if (hasFeatureFlag('tiktok_shop')) {
      if (isCampaignWithTiktokShop) {
        if (isTTShopAuthorized) {
          openDrawer('tt-shop-product-list');
        } else {
          authorizeTiktokShop();
        }
        return;
      }
    }

    if (needTopup) {
      openDrawer('topup-balance-top_up_for_hiring');
    } else {
      handleHireCreator();
    }
  };

  const handleHireCreator = (callback?: any) => {
    launchProject({ projectId }, callback || handleLaunchSuccess);
  };

  const handleLaunchSuccess = () => {
    showConfirmModal();
  };

  const handleHireWithChangePrice = () => {
    const callback = () => {
      openDrawer(`project-price-request-${projectId}`);
    };

    const hireCallback = () => {
      showConfirmModal(callback);
    };

    handleHireCreator(hireCallback);
  };

  const showConfirmModal = (callback?: any) => {
    openDrawer(`project-draft-deadline-${projectId}`);
  };

  const getPriceDetails = () => {
    const initialPrice = Number(data.initialPrice);
    const initialCurrency = data.initialCurrency;
    const creatorCurrency = data.creator?.profile?.currency;
    const creatorPrice = Number(data.creator?.profile?.estimatedPrice);

    if (isBarter) {
      return { priceText: createSum(price, currency) };
    }

    if (initialPrice !== price && currency === initialCurrency && price < initialPrice) {
      return {
        prevPriceText: createSum(initialPrice, creatorCurrency),
        priceText: createSum(price, currency),
        color: 'green',
      };
    }

    if (creatorCurrency === currency && initialPrice === price && price < creatorPrice) {
      return {
        prevPriceText: createSum(creatorPrice, creatorCurrency),
        priceText: createSum(price, currency),
        color: 'green',
      };
    }

    return { priceText: createSum(price, currency) };
  };

  const { priceText, prevPriceText, color } = getPriceDetails();
  const archivation = data.archivation;
  const archivationByContractor = data.archivationByContractor;
  const campaignCompletion = data.campaign.completion;

  const isFreePlan = planId === FREE_PLAN;

  const onboardDisabled = !!(
    archivation ||
    campaignCompletion ||
    archivationByContractor ||
    isFreePlan
  );
  const psDisabled = paidSocial && !paidSocialEnabled;

  const tooltipMsg = useMemo<string | undefined>(() => {
    if (archivation) {
      return 'project.stepper.onboarding.advertiser.archived';
    } else if (campaignCompletion) {
      return 'project.stepper.onboarding.advertiser.campaign_completed';
    } else if (psDisabled) {
      return 'project.stepper.onboarding.advertiser.ps.failed';
    } else if (isFreePlan) {
      return 'project.stepper.onboarding.advertiser.hire_disabled_for_free_plan';
    }
    return undefined;
  }, [archivation, campaignCompletion, psDisabled, isFreePlan]);

  const isPriceVisible = notProductSeeding;
  const isDisclaimerVisible = notProductSeeding;

  return (
    <div>
      {isPriceVisible && (
        <div className={styles.priceWrap}>
          <Text weight="500" msg="project.stepper.onboarding.price" className={styles.priceTitle} />
          <Text tag="h2" weight="500" color={color} text={priceText} />
          {prevPriceText && (
            <Text
              size="sm"
              color="grayDog"
              text={` ${prevPriceText}`}
              className={styles.prevPrice}
            />
          )}
        </div>
      )}
      <Tooltip id="onboard" tooltipMsg={tooltipMsg} place="bottom">
        <Button
          width="full"
          msg={onBoardBtnMsg}
          disabled={psDisabled || onboardDisabled}
          onClick={handleOnboardClick}
          className={styles.button}
        />
      </Tooltip>
      {isDisclaimerVisible && (
        <Text
          size="sm"
          align="center"
          msg="project.stepper.onboarding.advertiser.disclaimer"
          className={styles.disclaimer}
        />
      )}
      {isBarter && (
        <div>
          <Tooltip id="onboardAndHire" tooltipMsg={tooltipMsg} place="bottom">
            <Button
              width="full"
              color="normal"
              msg="project.stepper.onboarding.advertiser.submit_and_change_price"
              className={styles.hireAndChangePrice}
              disabled={psDisabled || onboardDisabled || !canOnboard}
              onClick={handleHireWithChangePrice}
            />
          </Tooltip>
          <Text
            size="sm"
            align="center"
            msg="project.stepper.onboarding.advertiser.submit_and_change_price.descr"
            className={styles.disclaimer}
          />
        </div>
      )}
      <MaxHiredCreatorsExceededDrawer
        attach={{
          planId,
          maxHiredCreators,
        }}
      />
      <TopupBalanceDrawer
        purpose="TOP_UP_FOR_HIRING"
        amount={finalAmount}
        successCallback={handleHireCreator}
      />
    </div>
  );
};

const OnboardingByAdvertiserWrap = track({ element: 'onboard' })(OnboardingByAdvertiser);

export default OnboardingByAdvertiserWrap;
