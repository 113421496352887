/**
 * @generated SignedSource<<94362a092abdaa34f80143320cbb8d17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type CampaignPostingType = "CREATOR_ADS" | "ORGANIC_POSTING" | "%future added value";
export type CampaignStage = "ACTIVE" | "COMPLETED" | "DRAFT" | "IN_REVIEW" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type ProductDeliveryOption = "BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS" | "BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT" | "COLLABORATION_WILL_NOT_REQUIRE_PRODUCT_SHIPMENT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AdditionalActions_campaign$data = {
  readonly activation: {
    readonly id: string;
    readonly paused: boolean;
  } | null | undefined;
  readonly brief: {
    readonly productDelivery?: ProductDeliveryOption | null | undefined;
  } | null | undefined;
  readonly briefSubmission: {
    readonly acceptance: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
  readonly canBeCompleted: boolean | null | undefined;
  readonly canDuplicate: boolean;
  readonly completion: {
    readonly __typename: "CampaignCompletion";
    readonly id: string;
  } | null | undefined;
  readonly completionRequest: {
    readonly __typename: "CampaignCompletionRequest";
  } | null | undefined;
  readonly counters: {
    readonly completedProjects: number;
    readonly launchedProjects: number;
  } | null | undefined;
  readonly deletable: boolean;
  readonly id: string;
  readonly name: string | null | undefined;
  readonly organization: {
    readonly id: string;
  } | null | undefined;
  readonly platform: CampaignPlatform | null | undefined;
  readonly postingType: CampaignPostingType | null | undefined;
  readonly stage: CampaignStage;
  readonly type: CampaignType | null | undefined;
  readonly userSubscribed: boolean;
  readonly " $fragmentType": "AdditionalActions_campaign";
};
export type AdditionalActions_campaign$key = {
  readonly " $data"?: AdditionalActions_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdditionalActions_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdditionalActions_campaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postingType",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canDuplicate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productDelivery",
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CampaignCounters",
      "kind": "LinkedField",
      "name": "counters",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "launchedProjects",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completedProjects",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CampaignActivation",
      "kind": "LinkedField",
      "name": "activation",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paused",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deletable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canBeCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BriefSubmission",
      "kind": "LinkedField",
      "name": "briefSubmission",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BriefSubmissionAcceptance",
          "kind": "LinkedField",
          "name": "acceptance",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CampaignCompletion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CampaignCompletionRequest",
      "kind": "LinkedField",
      "name": "completionRequest",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userSubscribed",
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "48e1268efaee1ce16a9e52a9a404f587";

export default node;
