import React, { PropsWithChildren } from 'react';
import { isMobile } from 'react-device-detect';

import { DrawerProvider, getDrawerContextInstance } from 'Components/ui/Drawer';
import ContinueProfileCreation from 'Modal/contractor/ContinueProfileCreation/ContinueProfileCreation';

type DrawerIds =
  | 'max-launched-campaigns-exceeded'
  | 'max-hired-creators-exceeded'
  | 'max-licensed-creators-exceeded'
  | 'max-active-brands-exceeded'
  | 'add-more-brands'
  | 'add-profile'
  | 'add-more-ad-creatives'
  | 'continue-profile-creation'
  | 'licenses-downgrade'
  | 'invite-creators'
  | `message-deletion-${string}`
  | 'invite-outreach-creators'
  | `invite-outreach-errors-${string}`
  | 'manual-transactions'
  | 'bca-license'
  | 'chat-post-links-recognised'
  | 'add-creator-blocklist'
  | 'onboarding'
  | 'confirm-new-plan'
  | `topup-balance-${string}`
  | `transaction-comment-${string}`
  | 'save-mediaplan'
  | 'custom-list-create'
  | 'custom-list-edit'
  | `custom-list-edit-${string}`
  | 'add-posting-to-this-collaboration'
  | `bca-permission-${string}`
  | 'connect-to-ads-manager-for-this-collaboration'
  | 'shopify-auth'
  | 'shopify-order-creation'
  | `project-notes-${string}`
  | `project-price-request-${string}`
  | `extra_payment-${string}`
  | `new-project-price-${string}`
  | `archive-project-${string}`
  | `archive-project-by-contractor-${string}`
  | `project-draft-deadline-${string}`
  | `project-publication-date-${string}`
  | `project-bca-connection-${string}`
  | `project-creator-rating-${string}`
  | `project-reimburse-${string}`
  | `edit-brand-modal-${string}`
  | `chat-modal-${string}`
  | `media-file-${string}`
  | `shop-revision-${string}`
  | 'pause-subscription-drawer'
  | 'success-pause-subscription-drawer'
  | `request-revision-${string}`
  | `buy-${string}-addon`
  | 'cancel-subscription-drawer'
  | 'cancel-subscription-typeform-drawer'
  | 'cancel-subscription-offer-drawer'
  | 'cancel-subscription-success-drawer'
  | 'cancel-subscription-billing-drawer'
  | 'cancel-subscription-offer-accepted-drawer'
  | 'tt-shop-product-list';

const DrawerContext = getDrawerContextInstance<DrawerIds>();

const DrawerContainer: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;

  return (
    <DrawerProvider context={DrawerContext}>
      {children}
      {!isMobile ? <ContinueProfileCreation /> : null}
    </DrawerProvider>
  );
};

export default DrawerContainer;
export { DrawerContext };
