import compact from 'lodash/compact';

import { SearchResultsContainerQuery$variables } from 'GraphTypes/SearchResultsContainerQuery.graphql';

export const isFiltersApplied = (
  input: SearchResultsContainerQuery$variables['searchQueryInput']
) => {
  if (!input) return false;

  const {
    creatorCountryIds,
    cityIds,
    categoryIds,
    audienceGenderId,
    creatorGenderId,
    creatorBirthdateTo,
    creatorBirthdateFrom,
    creatorEthnicityIds,
    ageRangeIds,
    audienceEthnicityIds,
    postPriceFrom,
    postPriceTo,
    followersFrom,
    followersTo,
    engagementRateFrom,
    engagementRateTo,
    creatorBadgeIds,
    includeAmazonStorefrontLink,
    withTiktokShop,
    onlyCreatorsWorkedWith,
    favorite,
    customListIds,
    excludeBadges,
    textQuery,
    hashtags,
    exclusions,
  } = input;

  const formattedFilters = compact([
    Number(creatorCountryIds?.length) > 0,
    Number(cityIds?.length) > 0,
    Number(categoryIds?.length) > 0,
    creatorGenderId,
    audienceGenderId,
    creatorBirthdateTo,
    creatorBirthdateFrom,
    Number(ageRangeIds?.length) > 0,
    Number(creatorEthnicityIds?.length) > 0,
    Number(audienceEthnicityIds?.length) > 0,
    followersFrom,
    followersTo,
    postPriceFrom,
    postPriceTo,
    engagementRateFrom,
    engagementRateTo,
    Number(creatorBadgeIds?.length) > 0,
    includeAmazonStorefrontLink,
    withTiktokShop,
    onlyCreatorsWorkedWith,
    favorite,
    Number(customListIds?.length) > 0,
    excludeBadges,
    textQuery,
    Number(hashtags?.length) > 0,
    Number(exclusions?.length) > 0,
  ]);

  return formattedFilters.length > 0;
};
