import { generatePath } from 'react-router-dom';

import { encodeQueryData } from 'Util/encodeQueryData';
import { getYoutubeVideoCode } from 'Util/textFormatter';
import {
  CREATOR_STATISTIC_ROUTE,
  CREATOR_ROUTE,
  CREATOR_SECTION_ROUTE,
  PROJECT_ROUTE,
  CAMPAIGN_ROUTE,
  CAMPAIGN_TYPE_ROUTE,
  CAMPAIGN_BRIEF_ROUTE,
  REFUND_POLICY_LINK,
  YOUTUBE_LINK,
  TIKTOK_LINK,
  CONTRACTOR_TERMS_LINK,
  POLICY_PRIVACY_LINK,
  PROJECT_BRIEF_ROUTE,
  ORGANIZATION_PROJECTS_ROUTE,
  BOOK_A_DEMO_LINK_EN,
  LISTING_ROUTE,
  CONTRACTOR_PROFILE_ROUTE,
  PROJECT_FOR_OFFER,
  ADMIN_ORGANIZATION_ROUTE,
  SIGNUP_ADVERTISER_ROUTE,
  ORGANIZATION_INVITE_ROUTE,
  ORGANIZATION_PERFORMANCE_ROUTE,
  OUTREACH_BATCH_ROUTE,
} from 'Constants/general';

const INSTAGRAM_ROOT = 'https://instagram.com/';

type CreatorProfileLinkParams = {
  creatorId?: string;
};
type CreatorProfileLink = (data: CreatorProfileLinkParams) => string;
export const getCreatorProfileLink: CreatorProfileLink = ({ creatorId }) => {
  return creatorId ? CREATOR_STATISTIC_ROUTE.replace(':creatorId', creatorId) : '';
};

type CreatorStatLinkParams = {
  creatorId: string;
  campaignId: string;
  section?: string;
};
type CreatorStatLink = (data: CreatorStatLinkParams) => string;
export const getCreatorStatLink: CreatorStatLink = ({ creatorId, campaignId, section }) => {
  if (section) {
    return CREATOR_SECTION_ROUTE.replace(':id', campaignId)
      .replace(':creatorId', creatorId)
      .replace(':section', section);
  }

  return CREATOR_ROUTE.replace(':id', campaignId).replace(':creatorId', creatorId);
};

type ProjectLinkParams = {
  projectId?: string;
};
type ProjectLink = (data: ProjectLinkParams) => string;
export const getProjectLink: ProjectLink = ({ projectId }) => {
  if (!projectId) return '';
  return PROJECT_ROUTE.replace(':id', projectId);
};

type ProjectsLinkParams = {
  projectId?: string;
  campaignId?: string;
  stages?: string;
  organizationId?: string;
  withUnreadEvents?: boolean;
};
type ProjectsLink = (data: ProjectsLinkParams) => string;
export const getProjectsLink: ProjectsLink = ({
  projectId,
  campaignId,
  stages,
  withUnreadEvents,
  organizationId,
}) => {
  const data = JSON.parse(
    JSON.stringify({
      campaignIds: campaignId,
      projectId,
      stages,
      withUnreadEvents: withUnreadEvents ? 'true' : undefined,
      organizationId,
    })
  );
  return `${ORGANIZATION_PROJECTS_ROUTE}${encodeQueryData(data)}`;
};

type ProjectBriefLinkParams = {
  projectId: string;
};
type ProjectBriefLink = (data: ProjectBriefLinkParams) => string;
export const getProjectBriefLink: ProjectBriefLink = ({ projectId }) => {
  return PROJECT_BRIEF_ROUTE.replace(':id', projectId);
};

type CampaignBriefLinkParams = {
  campaignId: string;
};
type CampaignBriefLink = (data: CampaignBriefLinkParams) => string;
export const getCampaignBriefLink: CampaignBriefLink = ({ campaignId }) => {
  return CAMPAIGN_TYPE_ROUTE.replace(':id', campaignId).replace(':type', 'brief');
};

type CampaignShipmentLink = (campaignId: string) => string;
export const getCampaignShipmentLink: CampaignShipmentLink = (campaignId) =>
  `/campaigns/${campaignId}/shipment`;

type CampaignCreatorsLinkParams = {
  campaignId: string;
};
type CampaignCreatorsLink = (data: CampaignCreatorsLinkParams) => string;
export const getCampaignCreatorsLink: CampaignCreatorsLink = ({ campaignId }) => {
  return CAMPAIGN_TYPE_ROUTE.replace(':id', campaignId).replace(':type', 'creators');
};

type CampaignMediaplanLinkParams = {
  campaignId: string;
};
type CampaignMediaplanLink = (data: CampaignMediaplanLinkParams) => string;
export const getCampaignMediaplanLink: CampaignMediaplanLink = ({ campaignId }) => {
  return CAMPAIGN_TYPE_ROUTE.replace(':id', campaignId).replace(':type', 'mediaplan');
};

type CampaignLinkParams = {
  campaignId: string;
};
type CampaignLink = (data: CampaignLinkParams) => string;
export const getCampaignLink: CampaignLink = ({ campaignId }) => {
  return CAMPAIGN_ROUTE.replace(':id', campaignId);
};

type CampaignTypeLinkParams = {
  campaignId: string;
  path?: string;
};
type CampaignTypeLink = (data: CampaignTypeLinkParams) => string;
export const getCampaignTypeLink: CampaignTypeLink = ({ campaignId, path = '' }) => {
  return CAMPAIGN_TYPE_ROUTE.replace(':id', campaignId).replace(':type', path);
};

type CampaignBriefForViewingLinkParams = {
  campaignId: string;
};
type CampaignBriefForViewingLink = (data: CampaignBriefForViewingLinkParams) => string;
export const getCampaignBriefForViewingLink: CampaignBriefForViewingLink = ({ campaignId }) => {
  return CAMPAIGN_BRIEF_ROUTE.replace(':id', campaignId);
};

type CampaignOutreachBatchLinkParams = {
  campaignId: string;
};
type CampaignOutreachBatchLink = (data: CampaignOutreachBatchLinkParams) => string;
export const getCampaignOutreachBatchLink: CampaignOutreachBatchLink = ({ campaignId }) => {
  return OUTREACH_BATCH_ROUTE.replace(':id', campaignId);
};

export const getBookADemoLink = () => {
  return BOOK_A_DEMO_LINK_EN;
};

type ContractorProfileLinkParams = {
  id: string;
};
type ContractorProfileLink = (data: ContractorProfileLinkParams) => string;
export const getContractorProfileLink: ContractorProfileLink = ({ id }) => {
  return CONTRACTOR_PROFILE_ROUTE.replace(':id', id);
};

type ListingLinkParams = {
  listingId: string;
};
type ListingLink = (data: ListingLinkParams) => string;
export const getListingLink: ListingLink = ({ listingId }) => {
  return LISTING_ROUTE.replace(':listingId', listingId);
};

type OrganizationLinkParams = {
  organizationId: string;
};
type OrganizationLink = (data: OrganizationLinkParams) => string;
export const getOrganizationLink: OrganizationLink = ({ organizationId }) => {
  return ADMIN_ORGANIZATION_ROUTE.replace(':id', organizationId);
};

type OrganizationPerformanceLinkParams = {
  organizationId: string;
};
type OrganizationPerformanceLink = (data: OrganizationPerformanceLinkParams) => string;
export const getOrganizationPerformanceLink: OrganizationPerformanceLink = ({ organizationId }) => {
  return ORGANIZATION_PERFORMANCE_ROUTE.replace(':id', organizationId);
};

// inner links

export const refundPolicyLink = REFUND_POLICY_LINK;

export const termsOfUseLink = CONTRACTOR_TERMS_LINK;

export const policyPrivacyLink = POLICY_PRIVACY_LINK;

type YoutubeLinkParams = {
  id: string;
};
type YoutubeLink = (data: YoutubeLinkParams) => string;
export const youtubeLink: YoutubeLink = ({ id }) => {
  return `${YOUTUBE_LINK}${id}`;
};

type InstagramLinkParams = {
  username: string | undefined | null;
};
type InstagramLink = (data: InstagramLinkParams) => string | null;
export const instagramLink: InstagramLink = ({ username }) => {
  if (!username) {
    return null;
  }
  return `${INSTAGRAM_ROOT}${username}/`;
};

type TiktokLinkParams = {
  username: string;
};
type TiktokLink = (data: TiktokLinkParams) => string;
export const tiktokLink: TiktokLink = ({ username }) => {
  return `${TIKTOK_LINK}@${username}`;
};

type FbPageLinkParams = {
  facebookPageId: string;
};
type FbPageLink = (data: FbPageLinkParams) => string;
export const creatorFbPageLink: FbPageLink = ({ facebookPageId }) => {
  return `https://facebook.com/${facebookPageId}`;
};

type ProjectForOfferLinkParams = {
  projectId: string;
  publicOfferToken?: string;
};
type ProjectForOfferLink = (data: ProjectForOfferLinkParams) => string;
export const projectForOfferLink: ProjectForOfferLink = (args) => {
  const { projectId, publicOfferToken } = args;
  const sp = new URLSearchParams();
  if (publicOfferToken) {
    sp.set('token', publicOfferToken);
  }
  const searchParamsString = sp.toString();
  const searchParams = searchParamsString ? `?${searchParamsString}` : '';
  return `${generatePath(PROJECT_FOR_OFFER, { id: projectId })}/${searchParams}`;
};

// YOUTUBE LINK

type YoutubeVideoThumbnailLinkParams = {
  code: string;
};
type YoutubeVideoThumbnailLink = (data: YoutubeVideoThumbnailLinkParams) => string;
export const youtubeVideoThumbnailLink: YoutubeVideoThumbnailLink = ({ code }) => {
  return `https://img.youtube.com/vi/${code}/hqdefault.jpg`;
};

type YoutubeVideoByUrlLinkParams = {
  url: string;
};
type YoutubeVideoByUrlLink = (data: YoutubeVideoByUrlLinkParams) => string;
export const youtubeVideoByUrlLink: YoutubeVideoByUrlLink = ({ url }) => {
  const code = getYoutubeVideoCode(url);

  return `https://img.youtube.com/vi/${code}/hqdefault.jpg`;
};

// sessins links

// TODO: Remove this!
type SignUpWithOrganizationLinkParams = {
  data: any;
};
type SignUpWithOrganizationLink = (data: SignUpWithOrganizationLinkParams) => string;
/**
 * @deprecated See CreateOrganizationInvite.Mutation.ts
 */

export const signUpWithOrganizationLink: SignUpWithOrganizationLink = (data) => {
  return `${process.env.PUBLIC_URL}${SIGNUP_ADVERTISER_ROUTE}${encodeQueryData(data)}`;
};

type OrganizationInviteLinkParams = {
  organizationInviteId?: string;
  organizationId?: string;
  organizationName?: string;
  token?: string;
};
type OrganizationInviteLink = (data: OrganizationInviteLinkParams) => string;
export const confirmOrganizationInviteLink: OrganizationInviteLink = (data) => {
  const { organizationInviteId, organizationId, organizationName, token } = data;
  const origin = window.location.origin;
  const sp = new URLSearchParams();
  organizationInviteId && sp.set('organizationInviteId', organizationInviteId);
  organizationId && sp.set('organizationId', organizationId);
  organizationName && sp.set('organizationName', organizationName);
  token && sp.set('token', token);
  return `${origin}${ORGANIZATION_INVITE_ROUTE}?${sp.toString()}`;
};
