import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styles from './Notifications.pcss';

import { StateProps } from 'Reducers/Notifications.Reducer';
import Notification from 'Atoms/Notification/Notification';
import { removeNotification } from 'Actions/Notifications.Actions';

interface Props {
  disable?: boolean;
  removeDelay?: number;
}

const Notifications: React.FC<Props> = ({ removeDelay, disable }) => {
  if (disable) return null;

  const dispatch = useDispatch();
  const notifications = useSelector((state: StateProps) => state.root.notifications.items);

  const removeItemFromStore = (id: string) => dispatch(removeNotification({ id }));

  return (
    <div className={styles.root}>
      {notifications.map((notification) => {
        return (
          <Notification
            key={notification.id}
            id={notification.id}
            text={notification.text}
            values={notification.values}
            btnCaption={notification.btnCaption}
            removeDelay={removeDelay}
            onBtnClick={notification.callback}
            onHideClick={removeItemFromStore}
          />
        );
      })}
    </div>
  );
};

export default Notifications;
