import React from 'react';

import styles from './Stats.pcss';

import { formatValue } from 'Util/numberFormatter';
import Text from 'Components/ui/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import { TooltipType, formatType } from 'Types/common';

interface Props {
  items?: {
    tooltipData?: TooltipType;
    value?: string | number;
    format?: formatType;
    formatData?: any;
    subtitleMsg?: string;
    width?: string;
    id: string;
  }[];
}

const Stats: React.FC<Props> = (props) => {
  const { items } = props;

  if (!items || items.length == 0) return null;

  return (
    <div className={styles.root}>
      {items.map((item) => {
        const { id, value, format, formatData = {}, subtitleMsg, tooltipData = {}, width } = item;

        const valueText = format ? formatValue({ value, format, ...formatData }) : value;

        return (
          <div key={id} style={{ width }} className={styles.statItem}>
            <Tooltip id={id} {...tooltipData}>
              <div key={id} className={styles.statItem}>
                <Text type="md" text={valueText} />
                <Text type="label" msg={subtitleMsg} className={styles.label} />
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

export default Stats;
