import React from 'react';

import styles from './ReferenceLoader.pcss';

import Spinner from 'Atoms/Spinner/Spinner';

interface Props {
  file: MediaSource;
}

const ReferenceLoader = (props: Props) => {
  const { file } = props;
  const style = { backgroundImage: `url(${URL.createObjectURL(file)})` };

  return (
    <div style={style} className={styles.root}>
      <div className={styles.preloaderWrap}>
        <Spinner color="white" size="sm" />
      </div>
    </div>
  );
};

export default ReferenceLoader;
