import Text from 'Components/ui/Text/Text';

import styles from '../InviteOutreachCreators.pcss';
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import {
  InviteOutreachCreatorsError,
  InviteOutreachCreatorsContext,
} from '../InviteOutreachCreatorsContext';
import { graphql, useFragment } from 'react-relay';
import DropdownItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import { GmailSettingsForOutreach_campaign$key } from 'GraphTypes/GmailSettingsForOutreach_campaign.graphql';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import AddGmailAccountButton from 'Page/advertiser/Campaign/Outreach/OutreachBatchesControls/AddGmailAccountButton/AddGmailAccountButton';
import Input from 'Components/ui/Input/Input';
import { GmailAuthDataResponse } from 'Page/advertiser/Campaign/Outreach/OutreachBatchesControls/AddGmailAccountButton/utils';

interface Props {
  query: GmailSettingsForOutreach_campaign$key;
}

const GmailSettings: React.FC<Props> = (props) => {
  const { query } = props;
  const { senderName, senderEmail, setSenderActiveAuth, setSenderEmail, setSenderName, setError } =
    useContext(InviteOutreachCreatorsContext);

  const data = useFragment(
    graphql`
      fragment GmailSettingsForOutreach_campaign on Campaign {
        organization {
          gmailAuthorizations {
            edges {
              node {
                id
                reauthenticationRequired
                availableEmails
                availableNames
              }
            }
          }
        }
      }
    `,
    query
  );

  const [name, setName] = useState(senderName);
  const [email, setEmail] = useState(senderEmail);
  const [authData, setAuthData] = useState(senderEmail);

  const gmailAuthorizationList =
    data.organization?.gmailAuthorizations?.edges?.reduce((list, auth) => {
      if (auth?.node?.availableEmails && !auth?.node?.reauthenticationRequired) {
        return [...list, auth?.node];
      }
      return list;
    }, []) || [];

  const previousAvailableNames =
    data.organization?.gmailAuthorizations?.edges?.reduce((list, auth) => {
      if (!auth?.node?.reauthenticationRequired) {
        return [...list, ...(auth?.node?.availableNames || [])];
      }
      return list;
    }, []) || [];
  const [availableGmailAccounts, setAvailableGmailAccounts] = useState(gmailAuthorizationList);

  const availableEmails = useMemo(() => {
    return availableGmailAccounts.reduce((list, auth) => {
      const emails = auth?.availableEmails?.map((email) => {
        return { email, authId: auth.id };
      });
      if (emails?.length) {
        return [...list, ...emails];
      }
      return list;
    }, []);
  }, [availableGmailAccounts]);

  const [availableNames, setAvailableNames] = useState(previousAvailableNames);

  useEffect(() => {
    if (availableNames[0]) {
      setName(availableNames[0]);
    }
    if (gmailAuthorizationList[0]?.id) {
      setAuthData(gmailAuthorizationList[0]?.id);
    }
    if (availableEmails[0]?.email) {
      setEmail(availableEmails[0]?.email);
    }
  }, [availableNames[0], gmailAuthorizationList[0]?.id, availableEmails[0]?.email]);

  useEffect(() => {
    setSenderName(name);
  }, [name]);

  useEffect(() => {
    setSenderEmail(email);
  }, [email]);

  useEffect(() => {
    setSenderActiveAuth(authData);
  }, [authData]);

  const handleAddNewGmailAccount = (accountData: GmailAuthDataResponse['data']) => {
    if (accountData?.__typename === 'AuthenticateWithGmailPayload') {
      if (
        Array.isArray(accountData.gmailAuthorization.availableEmails) &&
        accountData.gmailAuthorization.availableEmails?.length
      ) {
        const isPreviousEmailsListEmpty = availableEmails.length === 0;
        setAvailableGmailAccounts((authList) => {
          return [...authList, accountData.gmailAuthorization];
        });
        if (isPreviousEmailsListEmpty) {
          setEmail(accountData.gmailAuthorization.availableEmails[0]);
          setAuthData(accountData.gmailAuthorization.id);
        }
      }
      if (
        Array.isArray(accountData.gmailAuthorization.availableNames) &&
        accountData.gmailAuthorization.availableNames?.length
      ) {
        const isPreviousNamesListEmpty = availableNames.length === 0;
        setAvailableNames((nameList) => {
          return [...nameList, ...(accountData.gmailAuthorization.availableNames as string[])];
        });
        if (isPreviousNamesListEmpty) {
          setName(accountData.gmailAuthorization.availableNames[0]);
        }
      }
    } else if (accountData?.__typename === 'AuthenticateWithGmail_InvalidScopeError') {
      setError(InviteOutreachCreatorsError.INVALID_GMAIL_SCOPE);
    } else {
      setError(InviteOutreachCreatorsError.GENERAL_ERROR);
    }
  };

  const handleChangeSenderName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleResetSenderName = () => {
    setName('');
  };

  const isAllowToShowGmailAuthButton = Boolean(!availableEmails.length);

  if (isAllowToShowGmailAuthButton) {
    return (
      <>
        <Text
          type="md"
          msg="invite_outreach_creators_modal.step_gmail.add_account"
          className={styles.subtitle}
        />
        <AddGmailAccountButton
          buttonProps={{ className: styles.addNewAccountButton }}
          callback={handleAddNewGmailAccount}
        />
      </>
    );
  }

  return (
    <>
      <Text
        type="md"
        msg="invite_outreach_creators_modal.step_gmail.emails"
        className={styles.subtitle}
      />
      <Dropdown
        value={<Text type="md" text={email} />}
        className={styles.dropdownEmail}
        disabled={availableEmails.length === 1}
      >
        <DropdownGroup className={styles.dropdownMenu}>
          {availableEmails.map((item) => {
            const isActive = item.email === email;

            const handleEmailChange = () => {
              if (isActive) return;
              setEmail(item.email);
              setAuthData(item.authId);
            };

            return (
              <DropdownItem key={`${item.authId}_${item.email}`}>
                <AlterButton
                  text={item.email}
                  onClick={handleEmailChange}
                  fluid
                  iconPosition="right"
                  icon={isActive ? 'Check' : null}
                />
              </DropdownItem>
            );
          })}
        </DropdownGroup>
      </Dropdown>
      <AddGmailAccountButton
        buttonProps={{
          className: styles.addAccountButton,
          type: 'white',
          borderless: true,
        }}
        callback={handleAddNewGmailAccount}
      />
      <Text
        type="md"
        msg="invite_outreach_creators_modal.step_gmail.names"
        className={styles.subtitle}
      />
      <Dropdown
        value={
          <Input
            type="text"
            placeholderMsg="invite_outreach_creators_modal.step_gmail.names.placeholder"
            value={name}
            onChange={handleChangeSenderName}
            onResetValue={handleResetSenderName}
            borderless
            className={styles.label}
          />
        }
        className={styles.dropdownName}
      >
        <DropdownGroup className={styles.dropdownMenu}>
          {[...new Set(availableNames)].map((item) => {
            const isActive = item === name;

            const handleNameChange = () => {
              if (isActive) return;
              setName(item);
            };

            return (
              <DropdownItem key={item}>
                <AlterButton
                  text={item}
                  onClick={handleNameChange}
                  fluid
                  iconPosition="right"
                  icon={isActive ? 'Check' : null}
                />
              </DropdownItem>
            );
          })}
        </DropdownGroup>
      </Dropdown>
    </>
  );
};

export default GmailSettings;
