import React from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import { CampaignStage } from 'GraphTypes/CampaignsQuery.graphql';
import Tabs from 'Molecules/Tabs/Tabs';
import { CampaignStageFilterQuery as QueryType } from 'GraphTypes/CampaignStageFilterQuery.graphql';

const CampaignStageFilterQuery = graphql`
  query CampaignStageFilterQuery {
    activeCampaigns: campaigns(stages: [ACTIVE, IN_REVIEW]) {
      totalCount
    }
    draftCampaigns: campaigns(stages: [DRAFT]) {
      totalCount
    }
    completedCampaigns: campaigns(stages: [COMPLETED]) {
      totalCount
    }
  }
`;

interface Props {
  currentStage?: string;
  onStageChange: (id: CampaignStage) => void;
}

const CampaignStageFilter: React.FC<Props> = (props) => {
  const { query, currentStage, onStageChange } = props;

  const data = useLazyLoadQuery<QueryType>(CampaignStageFilterQuery, {});

  if (!data) return null;

  const items = [
    {
      id: 'ACTIVE',
      textData: { msg: 'dashboard.campaigns.filter.stage.active' },
      count: data.activeCampaigns?.totalCount,
      isSimpleView: true,
    },
    {
      id: 'DRAFT',
      textData: { msg: 'dashboard.campaigns.filter.stage.draft' },
      count: data.draftCampaigns?.totalCount,
      isSimpleView: true,
    },
    {
      id: 'COMPLETED',
      textData: { msg: 'dashboard.campaigns.filter.stage.completed' },
      count: data.completedCampaigns?.totalCount,
      isSimpleView: true,
    },
  ];

  const selectStage = (stage: CampaignStage) => {
    onStageChange(stage);
  };

  return (
    <Tabs
      items={items}
      currentItemId={currentStage}
      filled={true}
      mute={true}
      onChange={selectStage}
    />
  );
};

export default CampaignStageFilter;
