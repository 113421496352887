import React, { useContext, useMemo } from 'react';

import { BriefContext } from '../../Brief.Context';

import styles from './BriefTemplateMenu.pcss';
import SideBar from './SideBar/SideBar';

const BriefTemplateMenu = () => {
  const [briefState] = useContext(BriefContext);

  const completeTotalCount = useMemo<number>(() => {
    return Object.keys(briefState.creativeAssets).reduce((prevItem, currentItem) => {
      return briefState.creativeAssets[currentItem] === true ? prevItem + 1 : prevItem;
    }, 0);
  }, [briefState.creativeAssets]);

  const menuItems: SideBarProps['items'] = [
    {
      id: 'campaignInfo',
      titleData: { msg: 'brief_template.section.campaign_info' },
      scrollContainerName: 'campaignInfo',
      status: briefState.campaignInfoStatus,
      hidden: !briefState.isStepAvailable.campaignInfo,
    },
    {
      id: 'productInfo',
      titleData: { msg: 'brief_template.section.product' },
      scrollContainerName: 'productInfo',
      status: briefState.productInfoStatus,
      hidden: !briefState.isStepAvailable.productInfo,
    },
    {
      id: 'creatorsInfo',
      titleData: { msg: 'brief_template.section.creators' },
      scrollContainerName: 'creatorsInfo',
      status: briefState.creatorsInfoStatus,
      hidden: !briefState.isStepAvailable.creatorsInfo,
    },
    {
      id: 'creativesInfo',
      titleData: {
        msg: 'brief_template.section.creative_assets',
        values: { count: completeTotalCount ? `(${completeTotalCount})` : ' ' },
      },
      scrollContainerName: 'creativesInfo',
      status: briefState.creativesInfoStatus,
      hidden: !briefState.isStepAvailable.creativesInfo,
    },
    {
      id: 'optionalInfo',
      titleData: { msg: 'brief_template.section.optional' },
      scrollContainerName: 'optionalInfo',
      status: briefState.optionalInfoStatus,
      hidden: !briefState.isStepAvailable.optionalInfo,
    },
  ];

  return (
    <div className={styles.root}>
      <SideBar items={menuItems} />
    </div>
  );
};

export default BriefTemplateMenu;

// types

type SideBarProps = React.ComponentProps<typeof SideBar>;
