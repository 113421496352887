import React, { useCallback } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';

import styles from './CampaignActionsModal.pcss';
import CreatorsReportForAdmin from './CreatorsReportForAdmin/CreatorsReportForAdmin';
import CreatorsReport from './CreatorsReport/CreatorsReport';

import activateCampaign from 'Mutations/ActivateCampaign.Mutation';
import completeCampaign from 'Mutations/CompleteCampaign.Mutation';
import pauseCampaignActivation from 'Mutations/PauseCampaignActivation.Mutation';
import resumeCampaignActivation from 'Mutations/ResumeCampaignActivation.Mutation';
import { setModal, toggleModal } from 'Actions/Modal.Actions';
import { DUPLICATE_CAMPAIGN } from 'Constants/modals';
import { getCampaignBriefForViewingLink } from 'Util/links';
import { modalName } from 'Types/modals';
import Text from 'Components/ui/Text/Text';
import IconNew from 'Components/ui/Icon/Icon';
import TextPreset from 'Components/ui/TextPreset/TextPreset';
import Toggle from 'Components/ui/Toggle/Toggle';
import { amplitude } from 'Helpers/amplitude';
import { CampaignActionsModalQuery as queryType } from 'GraphTypes/CampaignActionsModalQuery.graphql';
import ShipmentReport from './ShipmentReport/ShipmentReport';
import useDashly from 'Hooks/useDashly';
import { Campaign_campaign$data } from 'GraphTypes/Campaign_campaign.graphql';

type Props = {
  campaign: Campaign_campaign$data;
  isAdmin: boolean;
  canBrowseCreators: boolean;
  canDownloadShipment?: boolean;
  showBrief?: boolean;
  handleClose: () => void;
  setActiveCampaign?: (data: Campaign_campaign$data | null) => void;
};

const CampaignActionsModalQuery = graphql`
  query CampaignActionsModalQuery {
    currentUser {
      admin
      organization {
        test
        subscription {
          planId
        }
      }
    }
  }
`;

const CampaignActionsModal: React.FC<Props> = (props) => {
  const {
    campaign,
    handleClose,
    isAdmin,
    setActiveCampaign,
    canBrowseCreators,
    showBrief = true,
    canDownloadShipment,
  } = props;

  const { track: trackEvent } = useDashly();
  const dispatch = useDispatch();

  const { currentUser } = useLazyLoadQuery<queryType>(CampaignActionsModalQuery, {});
  const isTest = currentUser?.organization?.test;
  const planId = currentUser?.organization?.subscription?.planId;

  const handleResume = useCallback(() => {
    amplitude.sendEvent({
      id: '18',
      category: 'campaign',
      name: 'response_action',
      param: { actionName: 'continue' },
    });
    resumeCampaignActivation({ campaignId });
    if (setActiveCampaign && campaign.activation) {
      setActiveCampaign({ ...campaign, activation: { ...campaign.activation, paused: false } });
    }
  }, []);

  const handleStop = useCallback(() => {
    trackEvent('stop_response_in_campaign');
    amplitude.sendEvent({
      id: '18',
      category: 'campaign',
      name: 'response_action',
      param: { actionName: 'stop' },
    });
    pauseCampaignActivation({ campaignId });
    if (setActiveCampaign && campaign.activation) {
      setActiveCampaign({ ...campaign, activation: { ...campaign.activation, paused: true } });
    }
  }, []);

  if (!campaign) {
    return null;
  }

  const campaignId = campaign.id;
  const {
    briefSubmission,
    completion,
    activation,
    completionRequest,
    deletable,
    canBeCompleted,
    organization,
    canDuplicate,
  } = campaign;

  const hasAcceptance = !!briefSubmission?.acceptance?.id;

  const isPaused = activation?.paused;
  const canSendBrief = canBrowseCreators && hasAcceptance && !isPaused && !completion;

  const handleDuplicateCampaign = () => {
    const isDraft = (!briefSubmission || !briefSubmission.acceptance) && !completion;
    amplitude.sendEvent({
      id: '255',
      category: 'campaign',
      name: 'actions_dashboard_duplicate',
      param: {
        admin: isAdmin,
        campaign_id: campaignId,
        organization_id: organization?.id,
        isTestOrganization: isTest,
        planId,
      },
    });
    dispatch(setModal(DUPLICATE_CAMPAIGN, { campaignId, isDraft }));
    handleClose();
  };

  const handleCloseCampaign = () => {
    pauseCampaignActivation({ campaignId });
    completeCampaign({ campaignId }, handleCampaigCompletionDone);
    amplitude.sendEvent({
      id: '48',
      category: 'campaign_actions',
      name: 'dashboard_close_campaign',
      param: { isAdmin },
    });
    handleClose();
  };

  const handleDeleteClick = () => {
    handleClose();
    dispatch(setModal(modalName.DELETE_CAMPAIGN, { campaignId, organizationId: organization?.id }));
  };

  const handleCampaigCompletionDone = () => {
    dispatch(toggleModal());
  };

  const handleReceivingApplications = () => {
    if (isPaused) {
      handleResume();
    } else {
      handleStop();
    }
  };

  const handleOpenBriefClick = () => {
    const path = getCampaignBriefForViewingLink({ campaignId });
    window.open(path);
    handleClose();
  };

  const handleSendBriefClick = () => {
    activateCampaign({ campaignId });
    handleClose();
  };
  const clickableItemClassList = classnames(styles.menuItem, styles.pointer);

  return (
    <div className={styles.drawerContainer}>
      <div className={styles.drawerClosable} onClick={handleClose} />
      <div>
        <div className={styles.drawerHeader}>
          <Text type={'h2'} msg={'campaign.drawer.title'} />
          <IconNew name={'Close-small'} handleClick={handleClose} />
        </div>
        <div className={styles.drawerMenu}>
          {activation?.id && !completion && !completionRequest ? (
            <TextPreset
              className={styles.menuItem}
              header={<Text className={styles.title} msg={'campaign.drawer.recieve'} />}
              rightComponent={<Toggle on={!isPaused} onChange={handleReceivingApplications} />}
            />
          ) : null}
          {canDuplicate && (
            <TextPreset
              className={clickableItemClassList}
              header={<Text className={styles.title} msg={'campaign.drawer.duplicate'} />}
              handleClick={handleDuplicateCampaign}
              rightComponent={<IconNew name={'Assets'} />}
            />
          )}
          {hasAcceptance && (
            <div className={styles.menuItemWrap}>
              {showBrief && (
                <TextPreset
                  className={clickableItemClassList}
                  header={<Text className={styles.title} msg={'campaign.drawer.open_brief'} />}
                  handleClick={handleOpenBriefClick}
                  rightComponent={<IconNew name="Brief" />}
                />
              )}
              {canSendBrief && (
                <TextPreset
                  className={clickableItemClassList}
                  header={<Text className={styles.title} msg="campaign.dropdown_send_brief" />}
                  handleClick={handleSendBriefClick}
                  rightComponent={<IconNew name="Group-users" />}
                />
              )}
            </div>
          )}
          <div
            className={classnames({
              [styles.buttonsRowContainer]: canDownloadShipment,
            })}
          >
            <CreatorsReport campaignId={campaignId} className={clickableItemClassList} />
            {canDownloadShipment && (
              <ShipmentReport campaignId={campaignId} className={clickableItemClassList} />
            )}
          </div>
          {isAdmin && hasAcceptance && (
            <CreatorsReportForAdmin campaignId={campaignId} className={clickableItemClassList} />
          )}
          {isAdmin || (hasAcceptance && !completion) ? (
            <TextPreset
              className={classnames(clickableItemClassList, {
                [styles.disabled]: !canBeCompleted && !isAdmin,
              })}
              header={<Text className={styles.title} msg={'campaign.drawer.close'} />}
              subtitle={<Text className={styles.subtitle} msg={'campaign.drawer.close.subtitle'} />}
              handleClick={canBeCompleted || isAdmin ? handleCloseCampaign : undefined}
              rightComponent={<IconNew name={'Campaigns'} />}
            />
          ) : null}
          {deletable ? (
            <TextPreset
              className={clickableItemClassList}
              header={<Text className={styles.title} msg={'campaign.drawer.delete'} />}
              handleClick={handleDeleteClick}
              rightComponent={<IconNew name={'Trash-delele'} />}
            />
          ) : null}
        </div>
      </div>
      <div className={styles.drawerClosable} onClick={handleClose} />
    </div>
  );
};

export default CampaignActionsModal;
