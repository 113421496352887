import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { graphql, useFragment } from 'react-relay';
import find from 'lodash/find';
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll';
import classnames from 'classnames';

import {
  getCurrentCreatorsAmountValue,
  getCreatorsAmountList,
} from '../BriefTemplateContent/CampaignDetails/util';
import { BriefContext } from '../../Brief.Context';

import styles from './BriefHeader.pcss';
import SavingStatus from './SavingStatus/SavingStatus';

import { useCampaignActions } from 'Hooks/useCampaignActions';
import ls from 'Util/localStorage';
import { waitUntil } from 'Util/waitUntil';
import { amplitude } from 'Helpers/amplitude';
import { identifyUser, addHubspotScript } from 'Analytics/engines/Hubspot';
import { getQueryLocationParams } from 'Util/encodeQueryData';
import MaxLaunchedCampaignsExceededDrawer from 'Modal/advertiser/MaxLaunchedCampaignsExceeded/MaxLaunchedCampaignsExceeded';
import CustomHeader from 'Components/CustomHeader/CustomHeader';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { DASHBOARD_ROUTE, FREE_PLAN, ADVERTISER } from 'Constants/general';
import { BriefHeader_campaign$key } from 'GraphTypes/BriefHeader_campaign.graphql';
import { PlanId } from 'GraphTypes/BriefQuery.graphql';
import { Auth0Context } from 'Containers/Auth0/Auth0Context';
import Help from 'Templates/Layout/Header/Help/Help';
import Balance from 'Templates/Layout/Header/Balance/Balance';
import { DemoButton } from 'Templates/Layout/Header/AdvertiserHeader/AdvertiserHeader';
import { useTikTokShopAuth } from 'Hooks/useTikTokShopAuth';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';

interface Props {
  briefId: string;
  campaignId: string;
  sendBtnMsg?: string;
  canSendToReview?: boolean;
  campaign: BriefHeader_campaign$key;
  planId?: PlanId | null;
  submitCallback: () => void;
}

/**
 * @desc Переменные _briefIsSaving и _canSendToReview
 * нужны для хранения значений в своей ячейке памяти, которые
 * в дальнейшем будут использованы в асинхронных
 * функциях waitUntil и handleSendOnReviewClick
 */
let _briefIsSaving: boolean | null;
let _canSendToReview: boolean;

const BriefHeader: React.FC<Props> = (props) => {
  const {
    briefId,
    campaignId,
    campaign,
    planId,
    sendBtnMsg,
    submitCallback,
    canSendToReview: defaultCanSendToReview,
  } = props;

  const data = useFragment(
    graphql`
      fragment BriefHeader_campaign on Campaign {
        type
        useCase
        platform
        brief {
          ... on V2Brief {
            paidSocial
            creatorsCountFrom
            creatorsCountTo
          }
        }
        activationFee {
          paymentState
        }
        organization {
          newClient
          tiktokShopLinked
          paymentAccount {
            balance
            currency
          }
          subscription {
            canActivateCampaignsWithoutFee
            paused
          }
        }
      }
    `,
    campaign
  );

  const { openDrawer } = useContext(DrawerContext);
  const [briefState, briefDispatch] = useContext(BriefContext);
  const location = useLocation();
  const [isSendToReviewLoading, setIsSendToReviewLoading] = useState<boolean>(false);
  const { authorize: authorizeTiktokShop } = useTikTokShopAuth();
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);

  const { briefIsSaving, screeningQuestions } = briefState;

  const { brief, organization } = data;

  const { submitBrief: submitBriefRequest } = useCampaignActions();

  useEffect(() => {
    const { planId, interval, action } = getQueryLocationParams();
    if (planId && interval && action === 'submitBrief') {
      window.history.replaceState({}, '', location.pathname);
      handleBreifSend();
    }
  }, []);

  const canActivateCampaignsWithoutFee = organization?.subscription?.canActivateCampaignsWithoutFee;
  const isPlanPaused = Boolean(organization?.subscription?.paused);
  const newClient = organization?.newClient;
  const paymentState = data.activationFee?.paymentState;
  const balance = organization?.paymentAccount?.balance;
  const currency = organization?.paymentAccount?.currency;

  const canSendToReview =
    defaultCanSendToReview ||
    ((!briefState.isStepAvailable.campaignInfo || briefState.campaignInfoFilled) &&
      (!briefState.isStepAvailable.productInfo || briefState.productInfoFilled) &&
      (!briefState.isStepAvailable.creativesInfo || briefState.creatorsInfoFilled) &&
      (!briefState.isStepAvailable.creativesInfo || briefState.creativesInfoFilled) &&
      (!briefState.isStepAvailable.optionalInfo || briefState.optionalInfoFilled) &&
      Boolean(canActivateCampaignsWithoutFee));

  _briefIsSaving = briefIsSaving;
  _canSendToReview = canSendToReview;

  const { user } = useContext(Auth0Context);

  useEffect(() => {
    if (canSendToReview) {
      if (user) {
        ls.set(`can_send_brief_id_${user.email}`, campaignId);
      }
    }
  }, [canSendToReview]);

  const handleSendOnReviewClick = async () => {
    if (
      data.useCase === 'TIKTOK_SHOP' &&
      !data.organization?.tiktokShopLinked &&
      hasFeatureFlag('tiktok_shop')
    ) {
      authorizeTiktokShop();
      return;
    }
    if (Object.values(screeningQuestions).includes(false)) {
      briefDispatch({ key: 'showErrors', value: true });
      scroller.scrollTo('screeningQuestion', {
        duration: 500,
        smooth: true,
        offset: -100,
      });
      return;
    }
    briefDispatch({ key: 'showErrors', value: false });
    if (isSendToReviewLoading) return;
    setIsSendToReviewLoading(true);
    await waitUntil(() => {
      return !_briefIsSaving;
    });

    if (!_canSendToReview) {
      setIsSendToReviewLoading(false);
      return;
    }
    if (briefState.isMaxLaunchedCampaignsExceeded) {
      openDrawer('max-launched-campaigns-exceeded');
      setIsSendToReviewLoading(false);
      return;
    }
    handleBreifSend();
  };

  const handleBreifSend = () => {
    const submitBriefFn = () => {
      const callback = () => {
        identifyUser({ first_brief_status: 'sent_to_moderation' });
      };
      addHubspotScript(callback);
      submitBriefRequest({ campaignId, briefId });
      amplitude.sendEvent({
        id: '88',
        category: 'brief',
        name: 'send_to_review_click',
        param: { campaignId },
      });
    };

    if (canActivateCampaignsWithoutFee || paymentState === 'SUCCESS') {
      if (submitCallback) {
        submitCallback();
      } else {
        submitBriefFn();
      }
    } else {
      setIsSendToReviewLoading(false);
      amplitude.sendEvent({
        id: 256,
        category: 'campaign',
        name: 'view_plans_after_brief',
        param: { campaignId, planId },
      });
    }
  };

  const handleSaveAndExitClick = () => {
    const creatorsAmount = getCurrentCreatorsAmountValue(
      brief?.creatorsCountFrom,
      brief?.creatorsCountTo
    );
    const creatorValue = creatorsAmount
      ? find(getCreatorsAmountList(), (item) => item.id === creatorsAmount)
      : undefined;
    amplitude.sendEvent({
      id: '87',
      category: 'brief',
      name: 'save_and_exit_click',
      param: { campaignId, creatorsCount: creatorValue?.titleText },
    });
  };

  const handleShowErrors = () => {
    briefDispatch({
      key: 'showErrors',
      value: {},
    });
  };

  const controls = (
    <div className={styles.controls}>
      {newClient && (
        <>
          <DemoButton />
          <Balance userType={ADVERTISER} newClient={true} balance={balance} currency={currency} />
          <Help />
        </>
      )}
      <Link
        to={{
          pathname: DASHBOARD_ROUTE,
        }}
        state={{ campaignStage: planId === FREE_PLAN ? 'DRAFT' : undefined }}
      >
        <AlterButton
          bordered
          type={newClient ? 'black' : undefined}
          msg="brief_template.action.save"
          dataTest="button:saveAndExitBrief"
          onClick={handleSaveAndExitClick}
        />
      </Link>
      {!newClient && !isPlanPaused && (
        <div onClick={canSendToReview ? undefined : handleShowErrors}>
          <AlterButton
            type="black"
            msg={sendBtnMsg || 'brief_template.action.send_to_review'}
            dataTest="button:sendBriefToReview"
            onClick={canSendToReview ? handleSendOnReviewClick : undefined}
            className={classnames({ [styles.disabled]: !canSendToReview })}
            loading={isSendToReviewLoading}
          />
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.root}>
      <CustomHeader controls={controls} showPartners={planId === FREE_PLAN}>
        <SavingStatus planId={planId} />
      </CustomHeader>
      <MaxLaunchedCampaignsExceededDrawer
        attach={{
          maxLaunchedCampaigns: briefState.subscription.maxLaunchedCampaigns,
          planId: briefState.planId,
        }}
      />
    </div>
  );
};

export default BriefHeader;
