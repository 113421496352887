import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import styles from '../InviteOutreachCreators.pcss';
import menuStyles from './EditorControl.pcss';
import Text from 'Components/ui/Text/Text';
import Input from 'Components/ui/Input/Input';
import { CreateOutreachTemplateMutation$data } from 'GraphTypes/CreateOutreachTemplateMutation.graphql';
import { useUpdateOutreachTemplateMutation } from 'Api/mutations/UpdateOutreachTemplate.Mutation';
import { useCreateOutreachTemplateMutation } from 'Api/mutations/CreateOutreachTemplate.Mutation';
import {
  InviteOutreachCreatorsContext,
  InviteOutreachCreatorsTemplate,
} from '../InviteOutreachCreatorsContext';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Icon from 'Components/ui/Icon/Icon';
import classNames from 'classnames';
import Handlebars from 'handlebars';
import { Locale } from 'GraphTypes/TemplateSelectorForOutreach_list.graphql';
import AddImageControl from './AddImageControl/AddImageControl';
import AddLinkControl from './AddLinkControl/AddLinkControl';

interface Props {
  variables: TemplateSpecification;
  template?: InviteOutreachCreatorsTemplate;
}

interface LinkEditMenu {
  show: boolean;
  linkText: string | null;
  linkUrl: string | null;
  linkNode: HTMLElement | null;
  position: {
    top: number;
    left: number;
  };
  isVariable: boolean;
}

const TemplateEditor: React.FC<Props> = (props) => {
  const { variables, template } = props;
  const editorEl = useRef<HTMLDivElement | null>(null);
  const [editorContent, setEditorContent] = useState(template?.htmlBody || '');
  const [templateName, setTemplateName] = useState(template?.name || '');
  const [templateSubject, setTemplateSubject] = useState(template?.subject || '');
  const [errorText, setErrorText] = useState<string | null>(null);
  const [linkEditMenu, setLinkEditMenu] = useState<LinkEditMenu>({
    show: false,
    linkText: '',
    linkUrl: '',
    linkNode: null,
    position: { top: 0, left: 0 },
    isVariable: false,
  });
  const [createOutreachTemplate, creating] = useCreateOutreachTemplateMutation();
  const [updateOutreachTemplate, updating] = useUpdateOutreachTemplateMutation();
  const { locale, setTemplateEditorStatus, setTemplatePreview } = useContext(
    InviteOutreachCreatorsContext
  );
  const savedSelection = useRef<Range | null>(null);

  useEffect(() => {
    if (template?.htmlBody && editorEl.current) {
      editorEl.current.innerHTML = template?.htmlBody;
    }
  }, [editorEl.current]);

  const formatText = (command: string, value?: string) => {
    document.execCommand(command, false, value);
  };

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      savedSelection.current = selection.getRangeAt(0);
    }
  };
  const restoreSelection = () => {
    const selection = window.getSelection();
    if (selection && savedSelection.current) {
      selection.removeAllRanges();
      selection.addRange(savedSelection.current);
    }
  };

  const handleInput = () => {
    if (editorEl.current) {
      setEditorContent(editorEl.current.innerHTML);
    }
  };

  const formatTextToBold = () => {
    formatText('bold');
  };
  const formatTextToItalic = () => {
    formatText('italic');
  };
  const formatTextToUnderline = () => {
    formatText('underline');
  };
  const formatTextToStrike = () => {
    formatText('strikeThrough');
  };
  const formatTextToOrderedList = () => {
    formatText('insertOrderedList');
  };
  const formatTextToUnorderedList = () => {
    formatText('insertUnorderedList');
  };

  const handleAddImage = (url: string, alt?: string) => {
    if (!editorEl.current) return;
    editorEl.current.focus();
    restoreSelection();
    formatText(
      'insertHtml',
      `<img src="${url}" alt="${
        alt || ''
      }" style="height: 300px; width: 400px; object-fit: contain" />&nbsp;`
    );
  };

  const handleAddLink = (url: string, text?: string) => {
    if (!editorEl.current) return;
    editorEl.current.focus();
    restoreSelection();
    formatText('insertHtml', `<a href="${url}" target="_blank">${text || url}</a>&nbsp;`);
  };

  const handleEditorTemplateClose = () => {
    setTemplateEditorStatus(false);
  };

  const handleChangeTemplateName = (e: ChangeEvent<HTMLInputElement>) => {
    setTemplateName(e.target.value);
    setErrorText(null);
  };

  const handleChangeTemplateSubject = (e: ChangeEvent<HTMLInputElement>) => {
    setTemplateSubject(e.target.value);
    setErrorText(null);
  };

  const handleSelectVariable = (e: ChangeEvent<HTMLSelectElement>) => {
    let handlebar = `<span variable="true">{{${e.target.value}}}</span>&nbsp;`;
    if (e.target.value === 'action_url') {
      handlebar = `<a variable="true" href="{{${e.target.value}}}" target="_blank">Apply</a>&nbsp;`;
      formatText('insertHtml', handlebar);
      return;
    }
    formatText('insertHtml', handlebar);
  };

  const handleSelectHeading = (e: ChangeEvent<HTMLSelectElement>) => {
    formatText('formatBlock', e.target.value);
  };

  const handlePreviewClick = () => {
    const template = Handlebars.compile(editorContent);
    const templateBody = template(variables);
    setTemplatePreview({
      subject: templateSubject,
      body: templateBody,
      name: templateName,
    });
  };

  const handleSaveTemplateClick = () => {
    if (template?.id) {
      updateOutreachTemplate({
        variables: {
          input: {
            id: template.id,
            subject: templateSubject,
            htmlBody: editorContent,
            name: templateName,
          },
        },
        onCompleted: () => {
          setTemplateEditorStatus(false);
        },
      });
    } else {
      createOutreachTemplate({
        variables: {
          input: {
            locale: locale as Locale,
            subject: templateSubject,
            htmlBody: editorContent,
            name: templateName,
          },
        },
        onCompleted: (data) => {
          if (data.createOutreachTemplate?.__typename === 'CreateOutreachTemplatePayload') {
            setTemplateEditorStatus(false);
          } else if (data.createOutreachTemplate?.__typename === 'AlreadyExists') {
            setErrorText('Template with same name already exists');
          } else {
            setErrorText('Something went wrong');
          }
        },
      });
    }
  };

  const openLinkEditMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    const linkNode = e.target as HTMLElement;
    if (linkNode && linkNode.tagName === 'A' && editorEl.current) {
      const linkRect = linkNode.getBoundingClientRect();
      const editorRect = editorEl.current.getBoundingClientRect();

      let top = linkRect.bottom - editorRect.top + 70;
      let left = linkRect.left - editorRect.left;

      const menuWidth = 300;
      const menuHeight = 180;

      if (left + menuWidth > editorRect.width) {
        left = editorRect.width - menuWidth - 50;
      }
      if (left < 0) {
        left = 10;
      }
      if (top + menuHeight > editorRect.height) {
        top = linkRect.top - editorRect.top - menuHeight + 30;
      }

      setLinkEditMenu({
        show: true,
        linkText: linkNode.textContent,
        linkUrl: linkNode.getAttribute('href'),
        linkNode: linkNode,
        position: { top, left },
        isVariable: Boolean(linkNode.getAttribute('variable')),
      });
    }
  };

  const updateLink = () => {
    if (linkEditMenu.linkNode) {
      const { linkNode, linkText, linkUrl } = linkEditMenu;
      linkNode.textContent = linkText;
      linkNode.setAttribute('href', linkUrl || '');
      closeLinkEditMenu();
    }
  };

  const closeLinkEditMenu = () => {
    setLinkEditMenu({
      show: false,
      linkText: '',
      linkUrl: '',
      linkNode: null,
      position: { top: 0, left: 0 },
      isVariable: false,
    });
  };

  const handleLinkTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLinkEditMenu({ ...linkEditMenu, linkText: e.target.value });
  };

  const handleLinkUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLinkEditMenu({ ...linkEditMenu, linkUrl: e.target.value });
  };

  return (
    <div className={styles.editorContainer}>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <div className={styles.backContainer}>
            <AlterButton
              icon="Arrow-big-left"
              className={styles.resetTemplate}
              onClick={handleEditorTemplateClose}
            />
            <Text
              type="d2"
              className={styles.title}
              msg="invite_outreach_creators_modal.editor.title"
            />
          </div>
          <div className={styles.backContainer}>
            <AlterButton
              type="white"
              msg="invite_outreach_creators_modal.editor.preview"
              icon="Eye"
              onClick={handlePreviewClick}
            />
            <AlterButton
              type="black"
              loading={creating || updating}
              disabled={Boolean(
                !editorContent.length ||
                  !templateName.length ||
                  !templateSubject.length ||
                  errorText
              )}
              msg="invite_outreach_creators_modal.editor.save"
              onClick={handleSaveTemplateClick}
            />
          </div>
        </div>

        <div className={classNames(styles.stepContainer)}>
          <Text type="md" msg="invite_outreach_creators_modal.editor.template_name.title" />
          <Input
            type="text"
            placeholderMsg="invite_outreach_creators_modal.editor.template_name.placeholder"
            value={templateName}
            onChange={handleChangeTemplateName}
            bordered
            fluid
            hideCloseIcon
          />
        </div>

        <Text type="md" msg="invite_outreach_creators_modal.editor.subtitle" />
        <div className={styles.editorWrapper}>
          <div className={classNames(styles.buttonPanel, styles.onTop)}>
            <Input
              type="text"
              placeholderMsg="invite_outreach_creators_modal.editor.template_subject.placeholder"
              value={templateSubject}
              onChange={handleChangeTemplateSubject}
              borderless
              fluid
              hideCloseIcon
            />
          </div>
          <div
            ref={editorEl}
            onClick={openLinkEditMenu}
            contentEditable
            onInput={handleInput}
            className={styles.editor}
          />
          {linkEditMenu.show && (
            <div
              style={{
                top: `${linkEditMenu.position.top}px`,
                left: `${linkEditMenu.position.left}px`,
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              }}
              className={classNames(menuStyles.buttonMenu, {
                [menuStyles.show]: linkEditMenu.show,
              })}
            >
              <Input
                label="Link URL *"
                forceLabelShow
                hideCloseIcon
                bordered
                placeholder="https://..."
                labelClassName={menuStyles.label}
                className={menuStyles.menuInput}
                value={linkEditMenu.linkUrl || ''}
                disabled={linkEditMenu.isVariable}
                onChange={handleLinkUrlChange}
              />
              <Input
                label="Link text"
                forceLabelShow
                hideCloseIcon
                bordered
                placeholder="Any text"
                labelClassName={menuStyles.label}
                className={menuStyles.menuInput}
                value={linkEditMenu.linkText || ''}
                onChange={handleLinkTextChange}
              />
              <div className={menuStyles.buttonContainer}>
                <AlterButton
                  hover={false}
                  className={menuStyles.button}
                  text="Save"
                  onClick={updateLink}
                />
                <AlterButton
                  hover={false}
                  className={menuStyles.button}
                  text="Cancel"
                  onClick={closeLinkEditMenu}
                />
              </div>
            </div>
          )}
          <div className={styles.buttonPanel}>
            <div className={styles.buttonPanelRow}>
              <div className={styles.editorSelectWrapper}>
                <select className={styles.editorSelect} onChange={handleSelectHeading}>
                  <option selected disabled hidden>
                    Text format
                  </option>
                  <option value="p">Paragraph</option>
                  <option value="h1">Heading 1</option>
                  <option value="h2">Heading 2</option>
                  <option value="h3">Heading 3</option>
                  <option value="h4">Heading 4</option>
                  <option value="h5">Heading 5</option>
                  <option value="h6">Heading 6</option>
                </select>
              </div>
              <button className={styles.control} onClick={formatTextToBold}>
                <Icon name="Bold" size={20} />
              </button>
              <button className={styles.control} onClick={formatTextToItalic}>
                <Icon name="Italic" />
              </button>
              <button className={styles.control} onClick={formatTextToUnderline}>
                <Icon name="Underline" />
              </button>
              <button className={styles.control} onClick={formatTextToStrike}>
                <Icon name="Strikethrough" />
              </button>
              <div className={styles.delimiter} />
              <button className={styles.control} onClick={formatTextToOrderedList}>
                <Icon name="Orderd-list" />
              </button>
              <button className={styles.control} onClick={formatTextToUnorderedList}>
                <Icon name="Bullited-list" />
              </button>
              <AddLinkControl saveSelection={saveSelection} onClick={handleAddLink} />
              <AddImageControl saveSelection={saveSelection} onClick={handleAddImage} />
              <div className={styles.editorSelectWrapper}>
                <select className={styles.editorSelect} onChange={handleSelectVariable}>
                  <option selected disabled hidden>
                    {'{{variables}}'}
                  </option>
                  {Object.keys(variables || []).map((item) => {
                    return (
                      <option key={item} value={item}>
                        <Text
                          type="md"
                          msg={`invite_outreach_creators_modal.editor.variable.${item}`}
                        />
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          {errorText && <Text type="md" className={styles.errorText} text={errorText} />}
        </div>
      </div>
    </div>
  );
};

export default TemplateEditor;
