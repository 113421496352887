import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import CheckboxWrapper from '../../components/CheckboxWrapper/CheckboxWrapper';
import Field from '../../components/Field/Field';

import styles from './MediaplanDetails.pcss';

import { amplitude } from 'Helpers/amplitude';
import { getCreatorData } from 'Util/creatorData';
import { getCampaignMediaplanLink } from 'Util/links';
import Avatar from 'Atoms/Avatar/Avatar';
import Button from 'Atoms/Button/Button';
import Text from 'Atoms/Text/Text';
import SmartLink from 'Atoms/SmartLink/SmartLink';
import { MediaplanDetails_campaign$key } from 'GraphTypes/MediaplanDetails_campaign.graphql';

interface Props {
  title?: string;
  description?: string;
  campaign: MediaplanDetails_campaign$key;
}

const MediaplanDetails: React.FC<Props> = (props) => {
  const { campaign, title, description } = props;

  const data = useFragment(
    graphql`
      fragment MediaplanDetails_campaign on Campaign {
        id
        private
        projects(first: 3, archived: false, orderBy: ACTIVITY) {
          edges {
            node {
              creator {
                id
                username
                __typename
                profile {
                  profilePictureFile {
                    url
                    ... on Image {
                      transformations {
                        collageThumbnailUrl
                      }
                    }
                  }
                }
              }
            }
          }
          totalCount
        }
      }
    `,
    campaign
  );
  const { id: campaignId, projects, private: privateValue } = data;

  const projectsCount = projects?.totalCount;

  const [isProjectsVisible, setProjectsVisible] = useState(Number(projectsCount) > 0);

  const handleAddedCreatorsShow = (isVisible: boolean) => {
    setProjectsVisible(isVisible);
  };

  const handleAddClick = () => {
    amplitude.sendEvent({
      id: '21',
      category: 'campaign',
      name: 'more_action_add_creators',
      param: { campaignId },
    });
  };

  const mediaplanLink = getCampaignMediaplanLink({ campaignId });

  const content = (
    <Field
      title={title || 'brief_template.field.creators.manually_picked.title'}
      description={description || 'brief_template.field.creators.manually_picked.descr'}
      isOptional={!title}
    >
      <div>
        <SmartLink path={mediaplanLink} target="_blank" className={styles.addCreators}>
          <Button
            width="full"
            size="xl"
            leftIconName="plus"
            color="normal"
            msg="brief_template.field.creators.manually_picked.add"
            onClick={handleAddClick}
          />
        </SmartLink>
        {projectsCount > 0 && (
          <div className={styles.projectsWrap}>
            <div className={styles.creatorsWrap}>
              {projects.edges?.map((item, index) => {
                if (!item?.node) return null;
                const creator = item.node?.creator;
                const { avaSrc } = getCreatorData(creator);

                return (
                  <div
                    key={creator?.id}
                    className={styles.creatorAvaWrap}
                    style={{ zIndex: 3 - index }}
                  >
                    <Avatar size="28" src={avaSrc} className={styles.creatorAva} />
                  </div>
                );
              })}
            </div>
            <Text weight="700" text={projectsCount} />
            <Text
              msg="brief_template.field.creators.manually_picked.added_count"
              values={{ count: projectsCount }}
            />
          </div>
        )}
      </div>
    </Field>
  );

  return privateValue ? (
    content
  ) : (
    <CheckboxWrapper isActive={isProjectsVisible} onChange={handleAddedCreatorsShow}>
      {content}
    </CheckboxWrapper>
  );
};

export default MediaplanDetails;
