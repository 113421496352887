import React, { Suspense, useContext, useEffect, useState } from 'react';
import { useFragment, graphql } from 'react-relay';
import { useNavigate, useSearchParams } from 'react-router-dom';
import compact from 'lodash/compact';

import styles from './InviteCreators.pcss';

import { getCampaignMediaplanLink } from 'Util/links';
import { useCreateInviteSuggestionListQueryMutation } from 'Mutations/CreateInviteSuggestionList.Mutation';
import { amplitude } from 'Helpers/amplitude';
import Tabs from 'Molecules/Tabs/Tabs';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import InviteCreatorsDrawer from 'Modal/advertiser/InviteCreators/InviteCreators';
import { CreatorType } from 'GraphTypes/SearchResultsContainerQuery.graphql';
import { InviteCreators_campaign$key } from 'GraphTypes/InviteCreators_campaign.graphql';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import { Link } from 'react-router-dom';
import { BILLING_ROUTE, OUTREACH } from 'Constants/general';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import Text from 'Components/ui/Text/Text';
import { FeatureFlagsContext } from 'Containers/FeatureFlags/FeatureFlags.Context';

interface Props {
  campaignId: string;
  section?: string;
  admin: boolean;
  campaign: InviteCreators_campaign$key;
  onSectionChange: (section: string) => void;
}

const InviteCreators: React.FC<Props> = (props) => {
  const { campaign, campaignId, admin, section, onSectionChange } = props;

  const { openDrawer } = useContext(DrawerContext);
  const { hasFeatureFlag } = useContext(FeatureFlagsContext);

  const navigate = useNavigate();

  const [inviteSuggestionListId, setInviteSuggestionListId] = useState('');
  const [inviteSuggestionListCount, setInviteSuggestionListCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [useCreateInviteSuggestionListQuery, listLoading] =
    useCreateInviteSuggestionListQueryMutation();

  const data = useFragment(
    graphql`
      fragment InviteCreators_campaign on Campaign {
        id
        type
        postingType
        completion {
          id
        }
        organization {
          subscription {
            paused
          }
        }
        platform
        maxInvitationCount
        usedInvitationCount
        searchQuery {
          customLists {
            id
            name
          }
          id
          ageRanges {
            id
            name
          }
          audienceCities {
            id
            name
          }
          audienceGenderPercentage
          audienceAgeRangePercentage
          audienceCountryPercentage
          audienceCityPercentage
          audienceGender {
            id
            name
          }
          categories {
            id
            name
          }
          cities {
            id
            name
          }
          countries {
            id
            name
          }
          tags {
            id
            name
          }
          creatorCountries {
            id
            name
          }
          currency
          creatorEthnicities {
            id
            name
          }
          creatorAnimals {
            id
            name
          }
          audienceEthnicities {
            id
            name
          }
          creatorBirthdateFrom
          creatorBirthdateTo
          engagementRateFrom
          engagementRateTo
          followersFrom
          followersTo
          realFollowersFrom
          realFollowersTo
          viewsPerPostFrom
          viewsPerPostTo
          viewsMedianFrom
          viewsMedianTo
          storiesReachFrom
          storiesReachTo
          audienceInterestPercentage
          postsReachFrom
          postsReachTo
          reelsPlaysMedianFrom
          reelsPlaysMedianTo
          campaignsCompletionsCountFrom
          campaignsCompletionsCountTo
          onlyCreatorsWorkedWith
          creatorGender {
            id
            name
          }
          creatorLanguages {
            id
            name
          }
          creatorBodyTypes {
            id
            name
          }
          creatorHairTypes {
            id
            name
          }
          excludeBadges
          strictlyByPrimaryCategory
          includeAmazonStorefrontLink
          favorite
          withTiktokShop
        }
      }
    `,
    campaign
  );

  const searchQueryValue = data.searchQuery;
  const isPlanPaused = Boolean(data.organization?.subscription?.paused);

  const [searchParams, setSearchParams] = useSearchParams();
  const urlInviteSuggestionListId = searchParams.get('inviteSuggestionListId');
  useEffect(() => {
    if (urlInviteSuggestionListId !== null && !isPlanPaused) {
      handleInviteClick();
    }
  }, [urlInviteSuggestionListId]);

  const mediaplanLink = getCampaignMediaplanLink({ campaignId });

  const canUseOutreachTool = hasFeatureFlag('outreach_tool') || admin;
  const campaignWithAllowedOutreach = data.type !== 'CONTENT_CREATION';

  const items = compact([
    campaignWithAllowedOutreach && canUseOutreachTool
      ? {
          textData: { msg: 'filter_mark.outreach' },
          id: OUTREACH,
        }
      : null,
  ]);

  const handleSectionSelect = (id: string) => {
    onSectionChange(id);
  };

  const tabs = (
    <div className={styles.tabs}>
      <Tabs currentItemId={section} items={items} onChange={handleSectionSelect} />
    </div>
  );
  let creatorTypes: CreatorType[] | undefined;

  if (data.platform === 'FACEBOOK' || data.platform === 'INSTAGRAM') {
    creatorTypes = ['INSTAGRAM'];
  } else if (data.platform === 'TIKTOK') {
    creatorTypes = ['TIKTOK'];
  } else if (data.platform === 'YOUTUBE') {
    creatorTypes = ['YOUTUBE'];
  }

  const searchQuery = { ...searchQueryValue, creatorTypes, currency: ['USD'] };

  if ((data.maxInvitationCount || 0) - (data.usedInvitationCount || 0) <= 0) {
    return (
      <div className={styles.root}>
        <Link to={mediaplanLink} state={{ ...searchQuery }}>
          <AlterButton type="pink" hover={false} msg="campaign.invite_creators" />
        </Link>
        {tabs}
        <div className={styles.divider} />
      </div>
    );
  }

  const handleInviteClick = () => {
    amplitude.sendEvent<388>({
      id: '388',
      category: 'campaign',
      name: 'invite_drawer_shown',
      param: { creators_count: 1 },
    });
    setLoading(true);
    useCreateInviteSuggestionListQuery({
      variables: { input: { campaignId } },
      onError: () => {
        navigate(mediaplanLink, { state: { ...searchQuery } });
      },
      onCompleted: (data) => {
        if (data.createInviteSuggestionList?.__typename === 'LimitExceededError') {
          navigate(mediaplanLink, { state: { ...searchQuery } });
          return;
        }
        if (data.createInviteSuggestionList?.__typename !== 'CreateInviteSuggestionListPayload')
          return;
        const inviteSuggestionListId = data.createInviteSuggestionList?.inviteSuggestionList?.id;
        const totalCount =
          data.createInviteSuggestionList?.inviteSuggestionList?.inviteSuggestionsCount.totalCount;
        if (inviteSuggestionListId) {
          setInviteSuggestionListCount(totalCount || 0);
          setInviteSuggestionListId(inviteSuggestionListId);
          setTimeout(() => {
            openDrawer('invite-creators');
          }, 0);
        }
        setLoading(false);
      },
    });
  };

  const inviteNotVisible = !(
    (data.type === 'INFLUENCER' && data.postingType === 'ORGANIC_POSTING') ||
    data.type === 'PRODUCT_SEEDING'
  );

  return (
    <div className={styles.root}>
      {!inviteNotVisible && (
        <Tooltip
          id={`campaign.invite_creators_new.tooltip.pause_plan`}
          place={'bottom'}
          clickable
          customTooltip={
            isPlanPaused ? (
              <>
                <Text type="md" msg="campaign.invite_creators.tooltip.pause_plan" />
                <a href={BILLING_ROUTE} className={styles.resumePlanTooltipLink}>
                  <Text type="md" msg="campaign.invite_creators.tooltip.pause_plan.button" />
                </a>
              </>
            ) : null
          }
        >
          <AlterButton
            type="pink"
            hover={false}
            disabled={isPlanPaused}
            loading={loading || listLoading}
            msg="campaign.invite_creators"
            onClick={handleInviteClick}
          />
        </Tooltip>
      )}
      {tabs}
      {(!inviteNotVisible || items.length > 0) && <div className={styles.divider} />}
      <Suspense fallback={null}>
        {searchQuery && inviteSuggestionListId && (
          <InviteCreatorsDrawer
            usedInvitationCount={data.usedInvitationCount || 0}
            maxInvitationCount={data.maxInvitationCount || 0}
            campaignId={campaignId}
            searchQuery={searchQuery}
            inviteSuggestionListCount={inviteSuggestionListCount}
            inviteSuggestionListId={inviteSuggestionListId}
          />
        )}
      </Suspense>
    </div>
  );
};

export default InviteCreators;
