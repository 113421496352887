import React, { useState, useCallback, useMemo, useEffect, Suspense } from 'react';
import { QueryRenderer, graphql } from 'react-relay';

import PaidSocialLicensesFilters from '../PaidSocialLicensesFilters/PaidSocialLicensesFilters';
import PaidSocialLicensesLimit from '../PaidSocialLicensesLimit/PaidSocialLicensesLimit';
import { getFilters, FilterId, FilterItem } from '../data';

import styles from './PaidSocialLicenseList.pcss';
import LicenseList from './LicenseList/LicenseList';

import environment from 'Api/Environment';
import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import Spinner from 'Atoms/Spinner/Spinner';
import {
  PaidSocialLicenseListQuery as QueryType,
  PaidSocialLicensesOrder,
} from 'GraphTypes/PaidSocialLicenseListQuery.graphql';

const PaidSocialLicenseListQuery = graphql`
  query PaidSocialLicenseListQuery(
    $orderBy: PaidSocialLicensesOrder
    $active: Boolean
    $creatorUsername: String
    $states: [BcaPermissionState!]
  ) {
    currentUser {
      organization {
        ...LicenseList_organization
          @arguments(
            orderBy: $orderBy
            active: $active
            states: $states
            creatorUsername: $creatorUsername
          )
      }
    }
  }
`;

const PaidSocialLicenseList = () => {
  const [filterId, setFilterId] = useState<FilterId>('default');
  const [orderBy, setOrderBy] = useState<PaidSocialLicensesOrder>('EXPIRES_AT_DESC');
  const [creatorUsername, setUsername] = useState('');

  const activeFilter = useMemo<FilterItem>(() => {
    return getFilters().find((item) => item.id === filterId) as FilterItem;
  }, [filterId]);

  useEffect(() => {
    amplitude.sendEvent({
      id: '180',
      category: 'pageview',
      name: 'licenses_dashboard',
    });
  }, []);

  const handleUsernameChange = useCallback((value: string) => {
    setUsername(value);
  }, []);

  const handleOrderChange = useCallback((order: PaidSocialLicensesOrder) => {
    setOrderBy(order);
  }, []);

  const handleFiltersChange = useCallback<
    NonNullable<PaidSocialLicensesFiltersProps['onFiltersChange']>
  >((filter) => {
    amplitude.sendEvent({
      id: 109,
      category: 'creator_licensing_dashboard',
      name: `filter_${`${filter.id}`.toLowerCase()}_click`,
    });
    setFilterId(filter.id);
  }, []);

  const states = filterId && filterId !== 'default' ? [filterId] : undefined;

  return (
    <Suspense fallback={<Spinner className={styles.preloader} />}>
      <div className={styles.root}>
        <Text
          type="d2"
          msg="dashboard.advertiser.licenses_section.title"
          className={styles.title}
        />
        <div>
          <PaidSocialLicensesLimit />
          <PaidSocialLicensesFilters
            orderBy={orderBy}
            filter={activeFilter}
            onOrderChange={handleOrderChange}
            onFiltersChange={handleFiltersChange}
            onUsernameChange={handleUsernameChange}
          />
        </div>
        <QueryRenderer<QueryType>
          environment={environment}
          query={PaidSocialLicenseListQuery}
          variables={{
            orderBy,
            creatorUsername,
            states,
            active: activeFilter.active,
          }}
          render={({ props: queryProps }) => {
            if (!queryProps) return <Spinner className={styles.preloader} />;

            const organization = queryProps.currentUser?.organization;

            if (!organization) return null;

            return (
              <LicenseList
                orderBy={orderBy}
                filter={activeFilter}
                creatorUsername={creatorUsername}
                organization={organization}
              />
            );
          }}
        />
      </div>
    </Suspense>
  );
};

export default PaidSocialLicenseList;

type PaidSocialLicensesFiltersProps = React.ComponentProps<typeof PaidSocialLicensesFilters>;
