/**
 * @generated SignedSource<<dcb40b8c35de6ec6b817f994b9734461>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type CampaignPlatform = "FACEBOOK" | "INSTAGRAM" | "OTHER" | "SNAPCHAT" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type CampaignStage = "ACTIVE" | "COMPLETED" | "DRAFT" | "IN_REVIEW" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type ContentReviewStatus = "FAILED" | "PENDING" | "SUCCESS" | "%future added value";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE" | "%future added value";
export type PaymentType = "BARTER" | "MONEY" | "%future added value";
export type ProjectArchivationSide = "ADVERTISER" | "CONTRACTOR" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreatorCardControls_project$data = {
  readonly advertiserStage: AdvertiserProjectStage | null | undefined;
  readonly archivation: {
    readonly id: string;
  } | null | undefined;
  readonly archivationByContractor: {
    readonly id: string;
  } | null | undefined;
  readonly archivationRequest: {
    readonly acceptance: {
      readonly id: string;
    } | null | undefined;
    readonly rejection: {
      readonly id: string;
    } | null | undefined;
    readonly side: ProjectArchivationSide;
  } | null | undefined;
  readonly campaign: {
    readonly canDuplicate: boolean;
    readonly completion: {
      readonly id: string;
    } | null | undefined;
    readonly id: string;
    readonly organization: {
      readonly id: string;
    } | null | undefined;
    readonly paymentType: PaymentType | null | undefined;
    readonly platform: CampaignPlatform | null | undefined;
    readonly publishingRequired: boolean;
    readonly stage: CampaignStage;
    readonly type: CampaignType | null | undefined;
  };
  readonly completion: {
    readonly id: string;
  } | null | undefined;
  readonly contentReview: {
    readonly status: ContentReviewStatus;
  } | null | undefined;
  readonly creator: {
    readonly id: string;
    readonly insightsAuthorized?: boolean;
    readonly type: CreatorType;
    readonly user?: {
      readonly id: string;
    } | null | undefined;
    readonly username: string;
  } | null | undefined;
  readonly events: {
    readonly unreadCount: number;
  } | null | undefined;
  readonly hasEmailThread: boolean;
  readonly id: string;
  readonly launch: {
    readonly id: string;
  } | null | undefined;
  readonly offer: {
    readonly acceptance: {
      readonly id: string;
    } | null | undefined;
    readonly id: string;
    readonly rejection: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
  readonly price: number | null | undefined;
  readonly shortlisted: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorChatDrawers_project">;
  readonly " $fragmentType": "CreatorCardControls_project";
};
export type CreatorCardControls_project$key = {
  readonly " $data"?: CreatorCardControls_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorCardControls_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatorCardControls_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortlisted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "advertiserStage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectEventConnection",
      "kind": "LinkedField",
      "name": "events",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectLaunch",
      "kind": "LinkedField",
      "name": "launch",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectCompletion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Offer",
      "kind": "LinkedField",
      "name": "offer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "OfferAcceptance",
          "kind": "LinkedField",
          "name": "acceptance",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OfferRejection",
          "kind": "LinkedField",
          "name": "rejection",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CampaignCompletion",
          "kind": "LinkedField",
          "name": "completion",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canDuplicate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "platform",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishingRequired",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "side",
          "value": "ADVERTISER"
        }
      ],
      "concreteType": "ProjectArchivation",
      "kind": "LinkedField",
      "name": "archivation",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "archivation(side:\"ADVERTISER\")"
    },
    {
      "alias": "archivationByContractor",
      "args": [
        {
          "kind": "Literal",
          "name": "side",
          "value": "CONTRACTOR"
        }
      ],
      "concreteType": "ProjectArchivation",
      "kind": "LinkedField",
      "name": "archivation",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "archivation(side:\"CONTRACTOR\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "insightsAuthorized",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "InstagramUser",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "type": "InstagramCreator",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentReview",
      "kind": "LinkedField",
      "name": "contentReview",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivationRequest",
      "kind": "LinkedField",
      "name": "archivationRequest",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectArchivationRequestAcceptance",
          "kind": "LinkedField",
          "name": "acceptance",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectArchivationRequestRejection",
          "kind": "LinkedField",
          "name": "rejection",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "side",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasEmailThread",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreatorChatDrawers_project"
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "4b916b5f9d0fa261a4b9aad887607b17";

export default node;
