import { CreateTiktokShopCollaborationMutation } from 'GraphTypes/CreateTiktokShopCollaborationMutation.graphql';
import { graphql, useMutation } from 'react-relay';
import { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const mutation = graphql`
  mutation CreateTiktokShopCollaborationMutation($input: CreateTiktokShopCollaborationInput!) {
    createTiktokShopCollaboration(input: $input) {
      __typename
      ... on CreateTiktokShopCollaborationPayload {
        collaboration {
          id
        }
      }
    }
  }
`;

export const useCreateTiktokShopCollaborationMutation: BaseMutation<
  CreateTiktokShopCollaborationMutation
> = (config) => {
  const [createTiktokShopCollaboration, loading] =
    useMutation<CreateTiktokShopCollaborationMutation>(mutation);

  const createTiktokShopCollaborationCaller: BaseMutationExecFn<
    CreateTiktokShopCollaborationMutation
  > = (execConfig) => {
    createTiktokShopCollaboration({
      ...config,
      ...execConfig,
    });
  };
  return [createTiktokShopCollaborationCaller, loading];
};
