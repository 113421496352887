import Text from 'Components/ui/Text/Text';

import styles from '../InviteOutreachCreators.pcss';
import React, { useContext } from 'react';
import { InviteOutreachCreatorsContext } from '../InviteOutreachCreatorsContext';
import RadioButton from 'Components/ui/RadioButton/RadioButton';
import { OutreachTransport } from 'GraphTypes/ImportOutreachBatchQuery.graphql';

const availableProviders: OutreachTransport[] = ['GMAIL', 'SENDGRID'];

interface Props {}

const ProviderSelector: React.FC<Props> = () => {
  const { transport, setTransportProvider } = useContext(InviteOutreachCreatorsContext);

  return (
    <>
      <Text type="md" msg="invite_outreach_creators_modal.step_3" className={styles.subtitle} />
      <div className={styles.templates}>
        {availableProviders?.map((item) => {
          const isActive = transport === item;

          const handleProviderSelect = () => {
            setTransportProvider(item);
          };

          return (
            <div className={styles.template} key={item}>
              <div className={styles.templateDetails}>
                <div onClick={handleProviderSelect}>
                  <RadioButton checked={isActive} />
                </div>
                <Text
                  type="md"
                  msg={`invite_outreach_creators_modal.transport.${item.toLowerCase()}`}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ProviderSelector;
