import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import styles from './Input.pcss';

import Text from 'Atoms/Text/Text';

const PASSWORD_TYPE = 'password';
const TEXT_TYPE = 'text';

@injectIntl
class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: props.type ? props.type : TEXT_TYPE,
    };

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
  }

  onBlur(e) {
    const { onChange, name } = this.props;
    if (!onChange) return;
    const value = e.target.value;
    onChange({ [name]: value });
  }

  onChange(e) {
    const { name, onChange, format } = this.props;
    if (!onChange) return;
    let value = e.target.value;
    if (format) {
      value = format(value);
    }
    onChange({ [name]: value });
  }

  onTypeChange() {
    const { type } = this.state;
    const newType = type === PASSWORD_TYPE ? TEXT_TYPE : PASSWORD_TYPE;
    this.setState({ type: newType });
  }

  render() {
    const {
      className,
      intl,
      name,
      input,
      placeholder,
      placeholderMsg,
      disabled,
      defaultValue,
      addition,
      style,
      hasChangeEvent,
      value,
      tabIndex,
      onFocus,
      onBlur,
      autofocus,
      type: initialType,
    } = this.props;
    const { type } = this.state;
    const inputStyle = addition ? { ...style, paddingRight: addition.width } : style;
    const placeholderText = placeholderMsg
      ? intl.formatMessage({ id: placeholderMsg })
      : placeholder;
    const valueData = value !== null && value !== undefined ? { value } : {};

    const isPassword = initialType === PASSWORD_TYPE;
    const isPasswordByText = isPassword && type === TEXT_TYPE;

    const onBlurFn = onBlur ? onBlur : null;

    return (
      <div className={styles.root}>
        <input
          className={`${className} ${styles.input}`}
          defaultValue={defaultValue ? defaultValue : undefined}
          type={type ? type : 'text'}
          name={name}
          placeholder={placeholderText}
          onFocus={onFocus}
          onBlur={hasChangeEvent ? onBlurFn : this.onBlur}
          onChange={hasChangeEvent ? this.onChange : null}
          disabled={disabled}
          style={inputStyle}
          autoComplete="off"
          tabIndex={tabIndex}
          autoFocus={autofocus}
          {...valueData}
          {...input}
        />
        {addition && (
          <Text size="sm" color="grayDog" text={addition.text} className={styles.addition} />
        )}
      </div>
    );
  }
}

export default Input;
