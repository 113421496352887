import React, { useMemo, Suspense, lazy } from 'react';
import { Route } from 'react-router';
import { RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import SessionRoute from './SessionRoute';
import PublicRoute from './PublicRoute';

import ls from 'Util/localStorage';
import { Auth0Container } from 'Containers/Auth0/Auth0Container';
import Layout from 'Templates/Layout/Layout';
import LocationHandler from 'Containers/LocationHandler/LocationHandler';
import SignUp from 'CommonPage/SignUp/SignUp';
import Brief from 'AdvertiserPage/Brief/Brief';
import Mediaplan from 'AdvertiserPage/Mediaplan/Mediaplan';
import Marketplace from 'AdvertiserPage/Marketplace/Marketplace';
import BillingHistory from 'AdvertiserPage/BillingHistory/BillingHistory';
import Billing from 'AdvertiserPage/Billing/Billing';
import UserSettings from 'AdvertiserPage/UserSettings/UserSettings';
import OrganizationProjects from 'AdvertiserPage/OrganizationProjects/OrganizationProjects';
import { SignUpComplete, SignUpType } from 'AdvertiserPage/SignUpComplete';
import Creator from 'AdvertiserPage/Creator/Creator';
import Campaign from 'AdvertiserPage/Campaign/Campaign';
import CampaignBriefForViewing from 'AdvertiserPage/CampaignBriefForViewing/CampaignBriefForViewing';
import Creatives from 'AdvertiserPage/Creatives/Creatives';
import YoutubeAuth from 'CommonPage/YoutubeAuth/YoutubeAuth';
import FbAuth from 'CommonPage/FbAuth/FbAuth';
import SnapchatAuth from 'CommonPage/SnapchatAuth/SnapchatAuth';
import TiktokAuth from 'CommonPage/TiktokAuth/TiktokAuth';
import CreateTikTokProfile from 'CommonPage/CreateTikTokProfile/CreateTikTokProfile';
import ShopifyAuth from 'CommonPage/ShopifyAuth/ShopifyAuth';
import BillingPlans from 'AdvertiserPage/BillingPlans/BillingPlans';
import Brands from 'AdvertiserPage/Brands/Brands';
import OrganizationTeam from 'AdvertiserPage/OrganizationTeam/OrganizationTeam';
import Home from 'AdvertiserPage/Home/Home';
import PaidSocialLicenses from 'AdvertiserPage/PaidSocialLicenses/PaidSocialLicenses';
import Creators from 'AdvertiserPage/Creators/Creators';
import Performance from 'AdvertiserPage/Performance/Performance';
import CreatorProfile from 'Organisms/CreatorProfile/CreatorProfile';
import CreatorProfiles from 'ContractorPage/CreatorProfiles/CreatorProfiles';
import Listing from 'ContractorPage/Listing/Listing';
import AdminCampaigns from 'AdminPage/AdminCampaigns/AdminCampaigns';
import AdminAdvertiser from 'AdminPage/AdminAdvertiser/AdminAdvertiser';
import AdminAdvertisers from 'AdminPage/AdminAdvertisers/AdminAdvertisersContainer';
import AdminCreativeLibrary from 'AdminPage/AdminCreativeLibrary/AdminCreativeLibraryContainer';
import AdminTransactions from 'AdminPage/AdminTransactions/AdminTransactionsContainer';
import AdminWithdrawls from 'AdminPage/AdminWithdrawls/AdminWithdrawls';
import AdminHelpers from 'AdminPage/AdminHelpers/AdminHelpers';
import {
  PLANS_ROUTE,
  ERROR_ROUTE,
  DOWNLOAD_APP_ROUTE,
  USER_SETTINGS,
  BILLINGHISTORY_ROUTE,
  BILLING_ROUTE,
  ADMIN_CAMPAIGNS_ROUTE,
  SIGNIN_ADVERTISER_ROUTE,
  SIGNUP_ADVERTISER_ROUTE,
  MEDIAPLAN_ROUTE,
  BRIEF_ROUTE,
  BRANDS_ROUTE,
  PS_LICENSES_ROUTE,
  CREATORS_ROUTE,
  PERFORMANCE_ROUTE,
  ORGANIZATION_PERFORMANCE_ROUTE,
  CREATOR_STATISTIC_ROUTE,
  CREATOR_ROUTE,
  CREATORS_LIST_ROUTE,
  CREATORS_FAVORITES_ROUTE,
  CREATORS_BLOCKLIST_ROUTE,
  CREATORS_PREVIOUS_COLLABS_ROUTE,
  CREATOR_SECTION_ROUTE,
  CAMPAIGN_ROUTE,
  CAMPAIGN_TYPE_ROUTE,
  CAMPAIGN_BRIEF_ROUTE,
  CREATIVES_ROUTE,
  PROJECT_ROUTE,
  PROJECT_BRIEF_ROUTE,
  CONTRACTOR_PROFILE_ROUTE,
  CONTRACTOR_PROFILES_ROUTE,
  LISTING_ROUTE,
  ADMIN_ORGANIZATION_ROUTE,
  ADMIN_ORGANIZATIONS_ROUTE,
  ADMIN_CREATIVES_ROUTE,
  ADMIN_TRANSACTIONS_ROUTE,
  ADMIN_WHITHDRAWLS_ROUTE,
  ADMIN_HELPERS,
  PROJECT_FOR_OFFER,
  CAMPAIGN_OUTREACH,
  INSTAGRAM_INSIGHTS,
  BADGE_INFO,
  CONTRACTOR_RATING_INFO,
  CONTRACTOR_PROJECTS,
  SIGNIN_CREATOR_ROUTE,
  SIGNUP_CREATOR_ROUTE,
  SIGNUP_COMPLETE_ADVERTISER_ROUTE,
  SIGNUP_COMPLETE_ADVERTISER_SURVEY_ROUTE,
  SIGNUP_COMPLETE_CREATOR_ROUTE,
  OUTCOME_PAGE_ROUTE,
  PAYMENT_METHOD_VERIFICATION,
  ORGANIZATION_INVITE_ROUTE,
  MARKETPLACE_ROUTE,
  ORGANIZATION_PROJECTS_ROUTE,
  SHOPIFY_OAUTH_VERIFY,
  HOME_ROUTE,
  DASHBOARD_ROUTE,
  VERIFICATION_ROUTE,
  CAMPAIGN_INVITE,
  OUTREACH_BATCH_ROUTE,
  TIKTOK_SHOP_OAUTH_ROUTE,
  USER_TEAM,
} from 'Constants/general';
import CampaignOutreach from 'Page/contractor/CampaignOutreach/CampaignOutreach';
import DrawerContainer from 'Containers/Drawer/DrawerContainer';
import ShopifyOAuthVerify from 'CommonPage/ShopifyOAuthVerify/ShopifyOAuthVerify';
import CampaignInviteWrapper from 'Page/contractor/CampaignInvite/CampaignInviteWrapper';
import GmailAuth from 'Page/common/GmailAuth/GmailAuth';
import CreateOutreachBatch from 'Page/advertiser/Campaign/CreateOutreachBatch/CreateOutreachBatch';
import TiktokShopAuth from 'Page/common/TiktokShopAuth/TiktokShopAuth';

const Project = lazy(() => import('Page/common/Project/Project'));
const Error404 = lazy(() => import('CommonPage/Error404/Error404'));
const DownloadAppSignIn = lazy(() => import('CommonPage/DownloadAppSignIn/DownloadAppSignIn'));
const Graphiql = lazy(() => import('CommonPage/Graphiql/Graphiql'));
const Dashboard = lazy(() => import('CommonPage/Dashboard/Dashboard'));
const ProjectOffer = lazy(() => import('PublicPage/ProjectOffer/ProjectOffer'));
const InstagramInsights = lazy(() => import('PublicPage/InstagramInsights/InstagramInsights'));
const BadgeInfo = lazy(() => import('PublicPage/BadgeInfo/BadgeInfo'));
const PaymentMethodVerification = lazy(
  () => import('PublicPage/PaymentMethodVerification/PaymentMethodVerification')
);
const OutcomePage = lazy(() => import('PublicPage/OutcomePage/OutcomePage'));
const OrganizationInvitePage = lazy(
  () => import('PublicPage/OrganizationInvite/OrganizationInvite')
);
const ContractorRatingInfo = lazy(
  () => import('PublicPage/ContractorRatingInfo/ContractorRatingInfo')
);
const VerificationPage = lazy(() => import('PublicPage/Verification/Verification'));

const AppRouter = () => {
  const isAdmin = ls.get('admin');

  const sp = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, [window.location.search]);

  const createPrivateRoute = (path: string, element: JSX.Element, index?: boolean) => {
    return <Route path={path} index={index} element={<PrivateRoute>{element}</PrivateRoute>} />;
  };

  const createSessionRoute = (path: string, element: JSX.Element | null) => {
    return <Route path={path} element={<SessionRoute>{element}</SessionRoute>} />;
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path={PROJECT_FOR_OFFER}
          element={
            <PublicRoute>
              <ProjectOffer />
            </PublicRoute>
          }
        />
        <Route
          path={CAMPAIGN_OUTREACH}
          element={
            <PublicRoute>
              <DrawerContainer>
                <CampaignOutreach />
              </DrawerContainer>
            </PublicRoute>
          }
        />
        <Route
          path={CAMPAIGN_INVITE}
          element={
            <PublicRoute>
              <DrawerContainer>
                <CampaignInviteWrapper />
              </DrawerContainer>
            </PublicRoute>
          }
        />
        <Route path={INSTAGRAM_INSIGHTS} element={<InstagramInsights />} />
        <Route path={BADGE_INFO} element={<BadgeInfo />} />
        <Route path={CONTRACTOR_RATING_INFO} element={<ContractorRatingInfo />} />
        <Route path={ERROR_ROUTE} element={<Error404 />} />
        <Route path={DOWNLOAD_APP_ROUTE} element={<DownloadAppSignIn />} />
        <Route path={PAYMENT_METHOD_VERIFICATION} element={<PaymentMethodVerification />} />
        <Route path={OUTCOME_PAGE_ROUTE} element={<OutcomePage />} />
        <Route path={ORGANIZATION_INVITE_ROUTE} element={<OrganizationInvitePage />} />
        <Route path={VERIFICATION_ROUTE} element={<VerificationPage />} />

        {/* auth routes */}

        {createSessionRoute(SIGNIN_ADVERTISER_ROUTE, null)}
        {createSessionRoute(SIGNIN_CREATOR_ROUTE, null)}
        {createSessionRoute(SIGNUP_ADVERTISER_ROUTE, null)}
        {createSessionRoute(SIGNUP_CREATOR_ROUTE, null)}
        {createSessionRoute(
          SIGNUP_COMPLETE_ADVERTISER_ROUTE,
          <SignUpComplete
            signUpType={sp.has('organizationId') ? SignUpType.InviteTeammate : SignUpType.Standard}
          />
        )}
        {createSessionRoute(
          SIGNUP_COMPLETE_ADVERTISER_SURVEY_ROUTE,
          <SignUpComplete signUpType={SignUpType.Survey} />
        )}
        {createSessionRoute(SIGNUP_COMPLETE_CREATOR_ROUTE, <SignUp />)}
        {createPrivateRoute(SHOPIFY_OAUTH_VERIFY, <ShopifyOAuthVerify />)}

        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />

          {/* routes for backward compatibility */}
          {/* common routes for contractor/advertiser*/}
          {createPrivateRoute(CONTRACTOR_PROJECTS, <Dashboard />)}
          {createPrivateRoute(PROJECT_ROUTE, <Project />)}
          {createPrivateRoute(PROJECT_BRIEF_ROUTE, <Project />)}
          {/* routes for advertiser */}
          {createPrivateRoute(HOME_ROUTE, <Home />)}
          {createPrivateRoute(BRANDS_ROUTE, <Brands />)}
          {createPrivateRoute(DASHBOARD_ROUTE, <Dashboard />)}
          {createPrivateRoute(PS_LICENSES_ROUTE, <PaidSocialLicenses />)}
          {createPrivateRoute(CREATORS_ROUTE, <Creators section="LISTS" />)}
          {createPrivateRoute(CREATORS_LIST_ROUTE, <Creators section="LISTS" />)}
          {createPrivateRoute(CREATORS_FAVORITES_ROUTE, <Creators section="FAVORITES" />)}
          {createPrivateRoute(CREATORS_BLOCKLIST_ROUTE, <Creators section="BLOCKLIST" />)}
          {createPrivateRoute(`/creators/lists/:customListId`, <Creators section="CUSTOM_LIST" />)}
          {createPrivateRoute(
            CREATORS_PREVIOUS_COLLABS_ROUTE,
            <Creators section="PREVIOUS_COLLABORATION" />
          )}
          {createPrivateRoute(ORGANIZATION_PERFORMANCE_ROUTE, <Performance />)}
          {createPrivateRoute(PERFORMANCE_ROUTE, <Performance />)}
          {createPrivateRoute(USER_TEAM, <OrganizationTeam />)}
          {createPrivateRoute(ORGANIZATION_PROJECTS_ROUTE, <OrganizationProjects />)}
          {createPrivateRoute(USER_SETTINGS, <UserSettings />)}
          {createPrivateRoute(BILLINGHISTORY_ROUTE, <BillingHistory />)}
          {createPrivateRoute(BILLING_ROUTE, <Billing />)}
          {createPrivateRoute(PLANS_ROUTE, <BillingPlans hidePromoCodeButton />)}
          {createPrivateRoute(BRIEF_ROUTE, <Brief />)}
          {createPrivateRoute(MEDIAPLAN_ROUTE, <Mediaplan />)}
          {createPrivateRoute(MARKETPLACE_ROUTE, <Marketplace />)}
          {createPrivateRoute(CREATOR_SECTION_ROUTE, <Creator />)}
          {createPrivateRoute(CREATOR_ROUTE, <Creator />)}
          {createPrivateRoute(CREATOR_STATISTIC_ROUTE, <Creator />)}
          {createPrivateRoute(CAMPAIGN_BRIEF_ROUTE, <CampaignBriefForViewing />)}
          {createPrivateRoute(CAMPAIGN_TYPE_ROUTE, <Campaign />)}
          {createPrivateRoute(CAMPAIGN_ROUTE, <Campaign />)}
          {createPrivateRoute('/campaigns/:id/', <Campaign />)}
          {createPrivateRoute(CREATIVES_ROUTE, <Creatives />)}
          {createPrivateRoute(OUTREACH_BATCH_ROUTE, <CreateOutreachBatch />)}
          {/* routes for contractor */}
          {createPrivateRoute(
            CONTRACTOR_PROFILE_ROUTE,
            <CreatorProfile hiddenContractorProfileFields />
          )}
          {createPrivateRoute(CONTRACTOR_PROFILES_ROUTE, <CreatorProfiles />)}
          {createPrivateRoute(LISTING_ROUTE, <Listing />)}
          {/* routes for admin */}
          {isAdmin && (
            <>
              {createPrivateRoute(ADMIN_CAMPAIGNS_ROUTE, <AdminCampaigns />)}
              {createPrivateRoute(ADMIN_ORGANIZATION_ROUTE, <AdminAdvertiser />)}
              {createPrivateRoute(ADMIN_ORGANIZATIONS_ROUTE, <AdminAdvertisers />)}
              {createPrivateRoute(ADMIN_CREATIVES_ROUTE, <AdminCreativeLibrary />)}
              {createPrivateRoute(ADMIN_TRANSACTIONS_ROUTE, <AdminTransactions />)}
              {createPrivateRoute(ADMIN_HELPERS, <AdminHelpers />)}
              {createPrivateRoute(ADMIN_WHITHDRAWLS_ROUTE, <AdminWithdrawls />)}
            </>
          )}
          {/* general routes */}
          {createPrivateRoute('/graphiql', <Graphiql />)}
        </Route>

        <Route path="/oauth">
          <Route path="facebook/paid-social" element={<FbAuth />} />
          {createPrivateRoute('youtube/start', <YoutubeAuth />)}
          {createPrivateRoute('shopify', <ShopifyAuth />)}
          {createPrivateRoute('snapchat/start', <SnapchatAuth />)}
          {createPrivateRoute('tiktok', <TiktokAuth />)}
          {createPrivateRoute(TIKTOK_SHOP_OAUTH_ROUTE, <TiktokShopAuth />)}
          {createPrivateRoute('tiktok/createProfile/:username', <CreateTikTokProfile />)}
          {createPrivateRoute('tiktok/createProfile', <CreateTikTokProfile />)}
          {createPrivateRoute('gmail/start', <GmailAuth />)}
        </Route>
      </>
    )
  );

  return (
    <Suspense fallback={<div />}>
      <Auth0Container>
        <RouterProvider router={router} />
      </Auth0Container>
      <LocationHandler />
    </Suspense>
  );
};

export default AppRouter;
