import React, { ChangeEvent, Suspense, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

import styles from './ProductList.pcss';

import Drawer from 'Components/ui/Drawer/Drawer';
import Text from 'Components/ui/Text/Text';
import { graphql, PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import {
  TiktokShopProductsListQuery as QueryType,
  TiktokShopProductsListQuery$data,
} from 'GraphTypes/TiktokShopProductsListQuery.graphql';
import Spinner from 'Atoms/Spinner/Spinner';
import { CollaborationInformation, TiktokShopProductsListQuery } from '../TiktokShopProductsList';
import Dropdown from 'Components/ui/Dropdown/Dropdown';
import DropdownGroup from 'Components/ui/Dropdown/DropdownGroup/DropdownGroup';
import DropdownGroupItem from 'Components/ui/Dropdown/DropdownItem/DropdownItem';
import Input from 'Components/ui/Input/Input';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import Image from 'Components/ui/Image/Image';
import { Checkbox } from 'Components/ui/Checkbox';

type DrawerProps = React.ComponentProps<typeof Drawer>;

interface Props {
  queryReference: PreloadedQuery<QueryType, Record<string, unknown>>;
  handleSelectProduct: (product: TikTokShopProductItem) => void;
  selectedProduct: TikTokShopProductItem | null;
  additionalProductInfo: CollaborationInformation;
  setProductInfo: (params: Partial<CollaborationInformation>) => void;
}

type Shop = NonNullable<
  NonNullable<
    NonNullable<TiktokShopProductsListQuery$data['campaign']>['organization']
  >['tiktokShops']
>[0];

export type TikTokShopProductItem = NonNullable<Shop['products']>[0];

const TiktokShopProductsList: React.FC<Props> = (props) => {
  const {
    queryReference,
    handleSelectProduct,
    selectedProduct,
    additionalProductInfo,
    setProductInfo,
  } = props;
  const [filterText, setFilterText] = useState('');
  const [comissionRate, setComissionRate] = useState(additionalProductInfo.comissionRate);
  const [freeSample, setFreeSampleFlag] = useState(additionalProductInfo.hasFreeSample);
  const [sampleWithoutApprove, setSampleWithoutApproveFlag] = useState(
    additionalProductInfo.isSampleApprovalExempt
  );
  const [selectedShop, setSelectedShop] = useState<Shop | null>(null);
  const queryData = usePreloadedQuery<QueryType>(TiktokShopProductsListQuery, queryReference);

  const shopList = queryData.campaign?.organization?.tiktokShops || [];

  const dropdownShopList = useMemo(() => {
    if (!shopList?.length) return [];
    return shopList.filter((shop) => shop.name.toLowerCase().includes(filterText));
  }, [shopList, filterText]);

  useEffect(() => {
    if (shopList.length === 1) {
      setSelectedShop(shopList[0]);
      setFilterText(shopList[0].name);
    }
  }, [shopList]);

  const handleSelectShop = (shop: Shop) => {
    setSelectedShop(shop);
  };

  const handleChangeFilterText = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterText(e.target.value);
  };

  const handleChangeComissionText = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 2) {
      setComissionRate((rate) => rate);
      return;
    }
    setComissionRate(Number(e.target.value));
  };

  useEffect(() => {
    setProductInfo({
      comissionRate,
    });
  }, [comissionRate]);

  useEffect(() => {
    setProductInfo({
      hasFreeSample: freeSample,
    });
  }, [freeSample]);

  useEffect(() => {
    setProductInfo({
      isSampleApprovalExempt: sampleWithoutApprove,
    });
  }, [sampleWithoutApprove]);

  const handleChangeFreeSampleFlag = (e: ChangeEvent<HTMLInputElement>) => {
    const isSampleEnabled = Boolean(e.target.value);
    setFreeSampleFlag(isSampleEnabled);
    if (!isSampleEnabled) {
      setSampleWithoutApproveFlag(false);
    }
  };

  const handleChangeSampleWithoutApproveFlag = (e: ChangeEvent<HTMLInputElement>) => {
    setSampleWithoutApproveFlag(Boolean(e.target.value));
  };

  return (
    <div className={styles.root}>
      <Dropdown
        closeBySelect
        bordered
        showArrow={false}
        className={styles.dropdownContainer}
        value={
          <Input
            type="text"
            labelMsg="tiktok_shop_products_modal.shop_list.label"
            placeholderMsg="tiktok_shop_products_modal.shop_list.placeholder"
            value={filterText}
            onChange={handleChangeFilterText}
            hideCloseIcon
            disabled={shopList.length < 2}
            borderless
            className={styles.label}
          />
        }
      >
        <DropdownGroup className={styles.menuGroup}>
          <div className={styles.listContainer}>
            {dropdownShopList?.map((shop) => (
              <DropdownGroupItem
                key={shop.id}
                handleClick={() => {
                  if (!shop) return;
                  handleSelectShop(shop);
                }}
              >
                <AlterButton
                  text={shop.name}
                  fluid
                  iconPosition={'right'}
                  icon={shop.id === selectedShop?.id ? 'Check' : null}
                />
              </DropdownGroupItem>
            ))}
          </div>
        </DropdownGroup>
      </Dropdown>
      {selectedShop && (
        <>
          <Text
            type="md"
            className={styles.productListTitle}
            msg="tiktok_shop_products_modal.product_list.title"
          />
          <div className={styles.productsContainer}>
            {selectedShop.products?.map((product) => {
              return (
                <div
                  key={product.id}
                  onClick={() => {
                    handleSelectProduct(product);
                  }}
                  className={styles.productItem}
                >
                  <div className={styles.productTitle}>
                    <div className={styles.productLogo}>
                      <Image src={product.mainImages?.[0].urls[0]} size={48} />
                    </div>
                    <div className={styles.productTitle}>
                      <Text type="md" text={product.title} />
                    </div>
                  </div>
                  {selectedProduct?.id === product.id && (
                    <>
                      <Input
                        pattern="\d{1,2}"
                        labelMsg="tiktok_shop_products_modal.product_list.comission.label"
                        placeholderMsg="tiktok_shop_products_modal.product_list.comission.placeholder"
                        value={comissionRate || ''}
                        onChange={handleChangeComissionText}
                        rightSymbol={'%'}
                        maxLength={2}
                        hideCloseIcon
                        borderless
                        className={styles.comissionInput}
                      />
                      <div className={styles.productTitle}>
                        <Checkbox
                          disableRootPadding
                          checked={freeSample}
                          onChange={handleChangeFreeSampleFlag}
                        />
                        <Text
                          type="md"
                          msg="tiktok_shop_products_modal.product_list.free_example"
                        />
                      </div>
                      {freeSample && (
                        <div className={styles.productTitle}>
                          <Checkbox
                            disableRootPadding
                            checked={sampleWithoutApprove}
                            onChange={handleChangeSampleWithoutApproveFlag}
                          />
                          <Text
                            type="md"
                            msg="tiktok_shop_products_modal.product_list.sample_exempt"
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default TiktokShopProductsList;
