import React, { useState, useEffect, useContext } from 'react';
import { useFragment, graphql } from 'react-relay';

import {
  MIN_PRICE_AMOUNT,
  MAX_PRICE_AMOUNT,
  MIN_TIKTOK_PRICE_AMOUNT,
  MIN_INSTAGRAM_PRICE_AMOUNT,
} from '../util';
import RangeField from '../../components/RangeField/RangeField';
import Warning from '../../Warning/Warning';
import { BriefContext } from '../../../../Brief.Context';

import styles from './PriceRangeDetails.pcss';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Atoms/Text/Text';
import { PRICE_RANGE_FAQ } from 'Constants/general';
import { PriceRangeDetails_campaign$key } from 'GraphTypes/PriceRangeDetails_campaign.graphql';
import type { UpdateBriefInput } from 'GraphTypes/UpdateBriefMutation.graphql';

interface Props {
  isAdmin?: boolean;
  campaign: PriceRangeDetails_campaign$key;
  onBriefChange: (data: Partial<UpdateBriefInput>) => void;
  handleCampaignUpdate?: (data: { type: string }) => void;
  withComission?: boolean;
}

const PriceRangeDetails: React.FC<Props> = (props) => {
  const { campaign, isAdmin, onBriefChange, withComission } = props;

  const [briefState] = useContext(BriefContext);

  const { showErrors, campaignInfoErrors, shownElementsWithPossibleError } = briefState;

  const fragment = useFragment(
    graphql`
      fragment PriceRangeDetails_campaign on Campaign {
        id
        type
        postingType
        platform
        brief {
          ... on V2Brief {
            submitted
            priceLimitMin
            priceLimitMax
          }
        }
      }
    `,
    campaign
  );

  const campaignType = fragment?.type;
  const postingType = fragment?.postingType;
  const platform = fragment?.platform;

  const [priceLimitMinError, setPriceLimitMinError] = useState('');
  const [prefillPriceLimitMinError, setPrefillPriceLimitMinError] = useState(false);
  const [priceLimitMaxError, setPriceLimitMaxError] = useState('');

  const priceLimitMin = fragment?.brief?.priceLimitMin;
  const priceLimitMax = fragment?.brief?.priceLimitMax;
  const isFreeAffiliate = !priceLimitMax && !priceLimitMax && withComission;

  const isBriefSubmitted = fragment?.brief?.submitted;

  const prefillMinCase =
    campaignType === 'INFLUENCER' &&
    postingType === 'ORGANIC_POSTING' &&
    (platform === 'TIKTOK' || platform === 'INSTAGRAM') &&
    !isAdmin &&
    !isBriefSubmitted &&
    !withComission;

  const finalMinLimit =
    platform === 'TIKTOK' ? MIN_TIKTOK_PRICE_AMOUNT : MIN_INSTAGRAM_PRICE_AMOUNT;

  useEffect(() => {
    if (prefillMinCase) {
      if (!priceLimitMin || priceLimitMin < finalMinLimit) {
        onBriefChange({
          priceLimitMin: finalMinLimit,
        });
      }
    }
  }, [prefillMinCase, finalMinLimit]);

  useEffect(() => {
    if (withComission) {
      setPriceLimitMaxError('');
      setPriceLimitMinError('');
    }
  }, [withComission]);

  useEffect(() => {
    setPrefillPriceLimitMinError(!priceLimitMin || priceLimitMin < finalMinLimit);
    if (!withComission && priceLimitMin && priceLimitMin <= MIN_PRICE_AMOUNT) {
      setPriceLimitMinError('brief_template.field.campaign_price_range.limit.error');
      return;
    } else if (priceLimitMin && priceLimitMax && priceLimitMin >= priceLimitMax) {
      setPriceLimitMinError('brief_template.field.campaign_price_range.min_limit.error');
    } else {
      setPriceLimitMinError('');
    }

    if (!withComission && priceLimitMax && priceLimitMax <= MIN_PRICE_AMOUNT) {
      setPriceLimitMaxError('brief_template.field.campaign_price_range.limit.error');
      return;
    } else if (!withComission && priceLimitMax && priceLimitMax > MAX_PRICE_AMOUNT) {
      setPriceLimitMaxError('brief_template.field.campaign_price_range.max_limit.error');
      return;
    }
    setPriceLimitMaxError('');
  }, [priceLimitMin, priceLimitMax]);

  useEffect(() => {
    if (priceLimitMinError || priceLimitMaxError) {
      amplitude.sendEvent({
        id: '79',
        category: 'brief',
        name: 'price_range_error_show',
        param: { campaignId: fragment?.id },
      });
    }
  }, [priceLimitMinError, priceLimitMaxError, withComission]);

  const handlePriceLimitChange = (data: { priceLimitMin?: string; priceLimitMax?: string }) => {
    const newPriceLimitMin = Object.prototype.hasOwnProperty.call(data, 'priceLimitMin')
      ? Number(data.priceLimitMin) || null
      : undefined;
    const newPriceLimitMax = Object.prototype.hasOwnProperty.call(data, 'priceLimitMax')
      ? Number(data.priceLimitMax) || null
      : undefined;

    if (newPriceLimitMin === undefined && newPriceLimitMax === undefined) return;

    onBriefChange({ priceLimitMin: newPriceLimitMin, priceLimitMax: newPriceLimitMax });
  };

  return (
    <div className={styles.root}>
      <RangeField
        title="brief_template.field.campaign_price_range.title"
        description="brief_template.field.campaign_price_range.descr"
        descriptionLinkData={{ msg: 'brief_template.field.read_more', path: PRICE_RANGE_FAQ }}
        minFiedlData={{
          name: 'priceLimitMin',
          element: 'input',
          elementData: {
            defaultValue: priceLimitMin,
            type: 'number',
            additionalComponent: <Text color="grayDog" size="sm" text="$" />,
            onBlur: handlePriceLimitChange,
            dataTest: 'input:minPrice',
            forceDefaultSet: true,
          },
          error: !!priceLimitMinError,
          errorData: { msg: priceLimitMinError },
        }}
        maxFiedlData={{
          name: 'priceLimitMax',
          required: !withComission,
          element: 'input',
          elementData: {
            defaultValue: priceLimitMax,
            type: 'number',
            additionalComponent: <Text color="grayDog" size="sm" text="$" />,
            dataTest: 'input:maxPrice',
            onBlur: handlePriceLimitChange,
            forceDefaultSet: true,
          },
          error:
            !!priceLimitMaxError ||
            (shownElementsWithPossibleError?.includes('priceRange') &&
              campaignInfoErrors.includes('priceRange') &&
              showErrors &&
              !priceLimitMax),
          errorData: { msg: priceLimitMaxError },
        }}
        className={styles.range}
      />
      {prefillMinCase && (
        <Warning
          titleMsg={
            prefillPriceLimitMinError
              ? 'brief_template.field.campaign_price_range.error.warning'
              : 'brief_template.field.campaign_price_range.warning'
          }
          titleValues={{ price: finalMinLimit }}
          error={prefillPriceLimitMinError}
        />
      )}
      {isFreeAffiliate && (
        <Warning titleMsg="brief_template.field.campaign_price_range.afiiliate.warning" />
      )}
    </div>
  );
};

export default PriceRangeDetails;
