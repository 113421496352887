import { TiktokShopAuthPostMessageData } from 'Hooks/useTikTokShopAuth';

type PostMessage = (params: TiktokShopAuthPostMessageData) => void;
const postMessage: PostMessage = (data) => {
  const { postMessageType, error, params } = data;
  if (window.opener) {
    window.opener.postMessage(
      {
        error,
        postMessageType,
        params,
      },
      window.location.origin
    );
  }
  if (!error) {
    // Wait to send postMessages
    setTimeout(() => {
      window.close();
    }, 500);
  }
};

export { postMessage };
