import React, { useMemo, useEffect, useContext } from 'react';
import { useFragment, graphql } from 'react-relay';

import { getCommissionTypesList } from '../util';
import With from '../../components/With/With';
import Field from '../../components/Field/Field';
import SegmentedControls from '../../components/SegmentedControls/SegmentedControls';
import Warning from '../../Warning/Warning';
import { BriefContext } from '../../../../Brief.Context';

import styles from './ComissionRateDetails.pcss';

import Input from 'Components/ui/Input/Input';
import { COMMISSION_DISABLED_TYPE, COMMISSION_ENABLED_TYPE } from 'Constants/general';
import type { UpdateBriefInput } from 'GraphTypes/UpdateBriefMutation.graphql';
import { ComissionRateDetails_campaign$key } from 'GraphTypes/ComissionRateDetails_campaign.graphql';

interface Props {
  campaign: ComissionRateDetails_campaign$key;
  isAdmin?: boolean;
  comissionAvailable: boolean;
  commissionInputVisible: boolean;
  onBriefChange: (data: Partial<UpdateBriefInput>) => void;
}

const ComissionRateDetails: React.FC<Props> = (props) => {
  const [briefState] = useContext(BriefContext);

  const { showErrors, shownElementsWithPossibleError } = briefState;

  const { campaign, isAdmin, comissionAvailable, commissionInputVisible, onBriefChange } = props;

  const fragment = useFragment(
    graphql`
      fragment ComissionRateDetails_campaign on Campaign {
        id
        type
        platform
        postingType
        useCase
        brief {
          ... on V2Brief {
            submitted
            includeReferralFee
            referralFeeRate
          }
        }
      }
    `,
    campaign
  );

  const includeReferralFee = fragment?.brief?.includeReferralFee;
  const referralFeeRate = fragment?.brief?.referralFeeRate;

  const isBriefSubmitted = fragment?.brief?.submitted;

  const commissionTypesList = getCommissionTypesList();

  const commissionType = includeReferralFee ? COMMISSION_ENABLED_TYPE : COMMISSION_DISABLED_TYPE;

  const prefillMinCase =
    (fragment?.type === 'INFLUENCER' &&
      fragment?.postingType === 'ORGANIC_POSTING' &&
      (fragment.platform === 'TIKTOK' || fragment.platform === 'INSTAGRAM') &&
      !isAdmin &&
      !isBriefSubmitted) ||
    fragment?.type === 'PRODUCT_SEEDING' ||
    fragment?.useCase === 'AFFILIATE_CAMPAIGN' ||
    fragment?.useCase === 'TIKTOK_SHOP';
  const isTiktokShopCase = fragment?.useCase === 'TIKTOK_SHOP';

  const isLowFee = !(Number(referralFeeRate) >= 10);

  useEffect(() => {
    if (includeReferralFee && isLowFee && prefillMinCase) {
      if (isTiktokShopCase) {
        onBriefChange({ referralFeeRate: 15 });
      } else {
        onBriefChange({ referralFeeRate: 10 });
      }
    }
  }, [includeReferralFee, fragment?.type, fragment.platform]);

  const handleIncludeReferralFeeChange = (data: { commissionType: string }) => {
    const newCommissonType = data?.commissionType;
    if (newCommissonType === COMMISSION_ENABLED_TYPE) {
      onBriefChange({ includeReferralFee: true });
    } else {
      onBriefChange({ includeReferralFee: false, referralFeeRate: null });
    }
  };

  const handleReferralFeeChanged = (event) => {
    const newValue = event?.target?.value;
    if (newValue.length === 0) {
      onBriefChange({ referralFeeRate: null });
      return;
    }
    onBriefChange({ referralFeeRate: +newValue });
  };

  const isReferralRateCorrect = useMemo<boolean>(() => {
    if (!referralFeeRate && referralFeeRate !== 0) {
      return true;
    }
    return referralFeeRate > 0 && referralFeeRate < 101 && referralFeeRate % 1 === 0;
  }, [referralFeeRate]);

  return (
    <>
      <With condition={comissionAvailable}>
        <Field
          title="brief_template.field.campaign_comission.title"
          description="brief_template.field.campaign_comission.descr"
        >
          <SegmentedControls
            id="commissionType"
            currentValue={commissionType}
            items={commissionTypesList}
            onChange={handleIncludeReferralFeeChange}
          />
        </Field>
      </With>
      <With condition={commissionInputVisible} name="fee">
        <Field
          title="brief_template.field.campaign_comission_rate.title"
          description="brief_template.field.campaign_comission_rate.descr"
          name="referralFeeRate"
        >
          <Input
            bordered
            dataTest="input:commissionRate"
            className={styles.referralRateInput}
            onBlur={handleReferralFeeChanged}
            placeholderMsg="brief_template.field.campaign_comission_rate.placeholder"
            type="number"
            rightSymbol="%"
            value={referralFeeRate}
            error={
              !isReferralRateCorrect ||
              (shownElementsWithPossibleError?.includes('fee') && showErrors && !referralFeeRate)
            }
          />
        </Field>
      </With>
      {includeReferralFee && prefillMinCase && !isTiktokShopCase && (
        <Warning
          className={styles.warning}
          error={!(Number(referralFeeRate) >= 10)}
          titleMsg={
            Number(referralFeeRate) >= 10
              ? 'brief_template.field.campaign_comission_rate.warning'
              : 'brief_template.field.campaign_comission_rate.error.warning'
          }
        />
      )}
      {includeReferralFee && prefillMinCase && isTiktokShopCase && (
        <Warning
          className={styles.warning}
          error={!(Number(referralFeeRate) >= 15)}
          titleMsg={
            Number(referralFeeRate) >= 15
              ? 'brief_template.field.campaign_comission_rate.tiktok_shop.warning'
              : 'brief_template.field.campaign_comission_rate.error.tiktok_shop.warning'
          }
        />
      )}
    </>
  );
};

export default ComissionRateDetails;
