/**
 * @generated SignedSource<<f30886411a55a0798aed94eea4a89507>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AdvertiserProjectStage = "ACCEPTED" | "ACCEPTED_AND_NOT_LAUNCHED" | "ACCEPTED_BY_UNREVIEWED_CREATOR" | "COMPLETED" | "CONTENT_SUBMITTED" | "LAUNCHED" | "PRODUCT_SHIPMENT_COMPLETED" | "PRODUCT_SHIPMENT_STARTED" | "PUBLISHING_STARTED" | "READ" | "REJECTED" | "UNREAD" | "UNSENT" | "%future added value";
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING" | "%future added value";
export type ProductDeliveryOption = "BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS" | "BRAND_WILL_REFUND_THE_PURCHASED_PRODUCT" | "COLLABORATION_WILL_NOT_REQUIRE_PRODUCT_SHIPMENT" | "%future added value";
export type ProjectArchivationSide = "ADVERTISER" | "CONTRACTOR" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreatorActions_project$data = {
  readonly advertiserStage: AdvertiserProjectStage | null | undefined;
  readonly archivation: {
    readonly id: string;
    readonly side: ProjectArchivationSide;
  } | null | undefined;
  readonly archivationRequest: {
    readonly acceptance: {
      readonly id: string;
    } | null | undefined;
    readonly rejection: {
      readonly id: string;
    } | null | undefined;
    readonly side: ProjectArchivationSide;
  } | null | undefined;
  readonly blockedCreator: boolean | null | undefined;
  readonly brief: {
    readonly productDelivery?: ProductDeliveryOption | null | undefined;
  } | null | undefined;
  readonly businessDaysSinceLatestCreatorEvent: number | null | undefined;
  readonly campaign: {
    readonly canDuplicate: boolean;
    readonly id: string;
    readonly organization: {
      readonly id: string;
    } | null | undefined;
    readonly type: CampaignType | null | undefined;
  };
  readonly completion: {
    readonly id: string;
  } | null | undefined;
  readonly creator: {
    readonly favorite: boolean;
    readonly id: string;
    readonly ownership: {
      readonly owner: {
        readonly unresponsive: boolean;
      };
    } | null | undefined;
  } | null | undefined;
  readonly favorite: boolean;
  readonly hasEmailThread: boolean;
  readonly id: string;
  readonly launch: {
    readonly id: string;
  } | null | undefined;
  readonly shortlisted: boolean;
  readonly " $fragmentType": "CreatorActions_project";
};
export type CreatorActions_project$key = {
  readonly " $data"?: CreatorActions_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorActions_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "favorite",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "side",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatorActions_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortlisted",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blockedCreator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "advertiserStage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasEmailThread",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "businessDaysSinceLatestCreatorEvent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectCompletion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "brief",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productDelivery",
              "storageKey": null
            }
          ],
          "type": "V2Brief",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivation",
      "kind": "LinkedField",
      "name": "archivation",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivationRequest",
      "kind": "LinkedField",
      "name": "archivationRequest",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectArchivationRequestAcceptance",
          "kind": "LinkedField",
          "name": "acceptance",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectArchivationRequestRejection",
          "kind": "LinkedField",
          "name": "rejection",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CreatorOwnership",
          "kind": "LinkedField",
          "name": "ownership",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "owner",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unresponsive",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canDuplicate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectLaunch",
      "kind": "LinkedField",
      "name": "launch",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "ecfabaedef2702d2759f0a9749201e8d";

export default node;
