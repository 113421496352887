import React, { PropsWithChildren, useContext, useEffect } from 'react';
import classnames from 'classnames';

import styles from './With.pcss';

import { BriefContext } from '../../../../Brief.Context';

interface Props {
  name?: string;
  condition: boolean;
  forceRerender?: boolean;
}

const With: React.FC<PropsWithChildren<Props>> = (props) => {
  const [, briefDispatch] = useContext(BriefContext);

  const { name, children, condition, forceRerender } = props;

  useEffect(() => {
    if (condition && name) {
      briefDispatch({ key: 'shownElements', value: [name] });
    }
  }, [condition]);

  if (forceRerender && !condition) return null;

  const conditionalStyles = condition ? undefined : styles.hidden;
  const classes = classnames(
    (children as React.ReactElement<any>).props.className,
    conditionalStyles
  );

  return React.cloneElement(children as React.ReactElement<any>, { className: classes });
};

export default With;
