import { AuthorizeTiktokShopUserMutation } from 'GraphTypes/AuthorizeTiktokShopUserMutation.graphql';
import { graphql, useMutation } from 'react-relay';
import { BaseMutation, BaseMutationExecFn } from 'Types/utils';

const mutation = graphql`
  mutation AuthorizeTiktokShopUserMutation($input: AuthorizeTiktokShopUserInput!) {
    authorizeTiktokShopUser(input: $input) {
      __typename
    }
  }
`;

export const useAuthorizeTiktokShopUserMutation: BaseMutation<AuthorizeTiktokShopUserMutation> = (
  config
) => {
  const [authorizeTiktokShopUser, loading] = useMutation<AuthorizeTiktokShopUserMutation>(mutation);

  const authorizeTiktokShopUserCaller: BaseMutationExecFn<AuthorizeTiktokShopUserMutation> = (
    execConfig
  ) => {
    authorizeTiktokShopUser({
      ...config,
      ...execConfig,
      updater: (store) => {
        const user = store.getRoot()?.getLinkedRecord('currentUser');
        const organization = user?.getLinkedRecord('organization');
        if (organization) {
          organization.setValue(true, 'tiktokShopLinked');
        }
      },
    });
  };
  return [authorizeTiktokShopUserCaller, loading];
};
