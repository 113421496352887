import React, { useState, useEffect } from 'react';
import remove from 'lodash/remove';

import styles from './NewNotifications.pcss';

import { AWAITING_LIST } from 'Constants/general';
import { getCampaignTypeLink } from 'Util/links';
import Text from 'Components/ui/Text/Text';
import importCreatorsBySearchQueryOperationUpdated from 'Api/subscriptions/ImportCreatorsBySearchQueryOperationUpdated.Subscription';
import { ImportCreatorsBySearchQueryOperationUpdatedSubscription$data } from 'GraphTypes/ImportCreatorsBySearchQueryOperationUpdatedSubscription.graphql';
import SmartLink from 'Atoms/SmartLink/SmartLink';

type Notification = {
  id: string;
  title: string;
  buttonMsg: string;
  link?: string;
};

const NewNotifications = () => {
  const [notofications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    importCreatorsBySearchQueryOperationUpdated({ updater: handleDataUpdate });
  }, []);

  const handleDataUpdate = (data: ImportCreatorsBySearchQueryOperationUpdatedSubscription$data) => {
    const campaignId = data.importCreatorsBySearchQueryOperationUpdated.campaign?.id || '';
    const state =
      data.importCreatorsBySearchQueryOperationUpdated.campaign
        ?.latestImportCreatorsBySearchQueryOperation?.state;

    if (state === 'SUCCESS') {
      setNotifications([
        ...notofications,
        {
          id: campaignId,
          title: 'creators_bulk_added.title',
          buttonMsg: 'creators_bulk_added.go_to',
          link: getCampaignTypeLink({ campaignId, path: AWAITING_LIST }),
        },
      ]);

      setTimeout(() => {
        removeNotification(campaignId);
      }, 5000);
    }
  };

  const removeNotification = (id: string) => {
    const newList = [...notofications];
    remove(newList, (item) => {
      return item.id === id;
    });
    setNotifications(newList);
  };

  return (
    <div className={styles.root}>
      {notofications.map((item) => {
        const { id, title, buttonMsg, link } = item;

        const handleNotificationRemove = () => {
          removeNotification(id);
        };
        return (
          <div key={id} className={styles.notification}>
            <Text type="md" msg={title} className={styles.title} />
            <SmartLink path={link} onClick={handleNotificationRemove}>
              <Text type="md" msg={buttonMsg} className={styles.link} />
            </SmartLink>
          </div>
        );
      })}
    </div>
  );
};

export default NewNotifications;
