/**
 * @generated SignedSource<<32bbb05d3cd9ab80a1d8df41555a4b7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserType = "ADVERTISER" | "CONTRACTOR" | "EPHEMERAL" | "UNKNOWN" | "%future added value";
export type useSessionActionsQuery$variables = Record<PropertyKey, never>;
export type useSessionActionsQuery$data = {
  readonly currentUser: {
    readonly advertiserProfile: {
      readonly firstName: string | null | undefined;
      readonly id: string;
    } | null | undefined;
    readonly id: string;
    readonly type: UserType;
  } | null | undefined;
};
export type useSessionActionsQuery = {
  response: useSessionActionsQuery$data;
  variables: useSessionActionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AdvertiserProfile",
        "kind": "LinkedField",
        "name": "advertiserProfile",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useSessionActionsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useSessionActionsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "aba1d0a2c8242544d785e061170e62ab",
    "id": null,
    "metadata": {},
    "name": "useSessionActionsQuery",
    "operationKind": "query",
    "text": "query useSessionActionsQuery {\n  currentUser {\n    id\n    type\n    advertiserProfile {\n      id\n      firstName\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bedb840b33bd3801ebc165811f65cea9";

export default node;
