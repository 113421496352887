import React, { useMemo, useState } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './CreatorsCount.pcss';
import { isFiltersApplied } from './utils';

import AddAllCreatorsDrawer from '../AddAllCreatorsDrawer/AddAllCreatorsDrawer';

import { amplitude } from 'Helpers/amplitude';
import { createNumber } from 'Util/numberFormatter';
import Text from 'Atoms/Text/Text';
import Tooltip from 'Atoms/Tooltip/Tooltip';
import TextButton from 'Components/ui/TextButton/TextButton';
import { SearchResultsContainerQuery$variables } from 'GraphTypes/SearchResultsContainerQuery.graphql';
import { CreatorsCountQuery as QueryType } from 'GraphTypes/CreatorsCountQuery.graphql';
import AlterButton from 'Components/ui/AlterButton/AlterButton';

const MAX_CREATORS_TO_BRAKE_ALL = 1000;

const CreatorsCountQuery = graphql`
  query CreatorsCountQuery($campaignId: ID!, $searchQueryInput: SearchQueryInput) {
    campaign(id: $campaignId) {
      stage
      searchCreators(searchQueryInput: $searchQueryInput) {
        totalCount
      }
      latestImportCreatorsBySearchQueryOperation {
        state
      }
    }
    currentUser {
      admin
    }
  }
`;

interface Props {
  campaignId: string;
  searchQueryInput: SearchResultsContainerQuery$variables['searchQueryInput'];
}

const CreatorsCount: React.FC<Props> = (props) => {
  const { campaignId, searchQueryInput } = props;
  const [isOpenedAddCreatorsDrawer, setCreatorsDrawerStatus] = useState(false);

  const data = useLazyLoadQuery<QueryType>(CreatorsCountQuery, { campaignId, searchQueryInput });

  const isFiltersAppliedValue = useMemo(() => {
    return isFiltersApplied(searchQueryInput);
  }, [searchQueryInput]);

  const totalCount = Number(data.campaign?.searchCreators?.totalCount);
  const isAdmin = data.currentUser?.admin;

  const isActiveCampaign = data.campaign?.stage === 'ACTIVE';
  const needToShowBulkInviteButton = isAdmin && isActiveCampaign;
  const isCreatorsCountExceedsMax = totalCount >= MAX_CREATORS_TO_BRAKE_ALL;

  const isImportInProgress =
    data.campaign?.latestImportCreatorsBySearchQueryOperation?.state === 'PENDING';

  const handleAddAllCreatorsToCampaign = () => {
    if (isCreatorsCountExceedsMax) return;
    setCreatorsDrawerStatus(true);
  };

  const handleCloseCreatorsDrawer = () => {
    setCreatorsDrawerStatus(false);
  };

  const handleAddCreatorsByBrand = () => {
    amplitude.sendEvent<417>({
      id: '417',
      category: 'marketplace',
      name: 'invite_multiple_creators_click',
      param: { campaign_id: campaignId },
    });
    setCreatorsDrawerStatus(true);
  };

  if (isAdmin) {
    return (
      <>
        <Text
          size="sm"
          color="grayDog"
          msg="browse_creators.search_results"
          values={{
            totalCount: createNumber(totalCount),
            totalCountNum: totalCount,
          }}
          className={styles.summary}
        />
        {needToShowBulkInviteButton && (
          <>
            <Tooltip
              place="top"
              id="add-all-creators"
              tooltipMsg={
                isCreatorsCountExceedsMax
                  ? 'browse_creators.add_all_tooltip.disabled'
                  : 'browse_creators.add_all_tooltip'
              }
              className={styles.tooltip}
            >
              <TextButton
                msg={'browse_creators.add_all_button'}
                disabled={isCreatorsCountExceedsMax}
                onClick={handleAddAllCreatorsToCampaign}
              />
            </Tooltip>
            {!isCreatorsCountExceedsMax && (
              <AddAllCreatorsDrawer
                isAdmin={true}
                opened={isOpenedAddCreatorsDrawer}
                searchParams={searchQueryInput}
                campaignId={campaignId}
                creatorsCount={totalCount}
                handleClose={handleCloseCreatorsDrawer}
              />
            )}
          </>
        )}
      </>
    );
  }

  return isActiveCampaign && totalCount ? (
    <>
      <div>
        <Tooltip
          id="invite_creators"
          place="top"
          tooltipMsg={
            isFiltersAppliedValue
              ? undefined
              : 'create_campaign.add_multiple_creators.disabled.tooltip'
          }
        >
          <AlterButton
            type="grey"
            msg="create_campaign.add_multiple_creators"
            loading={isImportInProgress}
            disabled={!isFiltersAppliedValue || isImportInProgress}
            onClick={handleAddCreatorsByBrand}
          />
        </Tooltip>
      </div>
      <AddAllCreatorsDrawer
        isAdmin={false}
        opened={isOpenedAddCreatorsDrawer}
        searchParams={searchQueryInput}
        campaignId={campaignId}
        creatorsCount={totalCount}
        handleClose={handleCloseCreatorsDrawer}
      />
    </>
  ) : null;
};

export default CreatorsCount;
