/**
 * @generated SignedSource<<ca89be0e33ad1e3870c684e33f8bfe98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AddonId = "BRANDS" | "SEATS" | "WHITELISTING_LICENSES" | "%future added value";
export type PlanId = "ADVANCED" | "ADVANCED_2021_10" | "ADVANCED_2022_11" | "AGENCY_2024_03" | "BARTER" | "BASIC_OLD_PS" | "BRAND_2024_03" | "CONTENT_CREATION" | "ENTERPRISE" | "FREE" | "FREE_OLD_PS" | "FREE_TRIAL" | "FULLY_MANAGED" | "FULLY_MANAGED_1" | "FULLY_MANAGED_2" | "FULLY_MANAGED_TTCX_MMA" | "GROW" | "PAYOUT" | "PREMIUM" | "PREMIUM_PLUS" | "PUBLICIS" | "SCALE" | "SCALE_2021_06" | "SCALE_2021_10" | "SMARTLY_ENTERPRISE_2023_06" | "SMARTLY_ENTERPRISE_TRIAL_2023_06" | "TRIAL_2022_08" | "TRIAL_2024_03" | "TRIAL_MONTH" | "UGC_2022_08" | "UGC_ADS_2022_08" | "WILDJAM" | "%future added value";
export type SubscriptionInterval = "CUSTOM" | "MONTHLY" | "TRIMONTHLY" | "YEARLY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WlSlotsBanner_campaign$data = {
  readonly organization: {
    readonly counters: {
      readonly bcaLicensedCreators: number;
    } | null | undefined;
    readonly subscription: {
      readonly addonItems: ReadonlyArray<{
        readonly addon: {
          readonly id: AddonId;
          readonly resourcesPerUnit: number;
        };
        readonly quantity: number;
      }>;
      readonly availableAddonIds: ReadonlyArray<AddonId>;
      readonly baseLimits: {
        readonly maxBcaConnections: number | null | undefined;
      };
      readonly effectiveLimits: {
        readonly maxBcaConnections: number | null | undefined;
      };
      readonly interval: SubscriptionInterval | null | undefined;
      readonly paused: boolean;
      readonly planId: PlanId;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "WlSlotsBanner_campaign";
};
export type WlSlotsBanner_campaign$key = {
  readonly " $data"?: WlSlotsBanner_campaign$data;
  readonly " $fragmentSpreads": FragmentRefs<"WlSlotsBanner_campaign">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "maxBcaConnections",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WlSlotsBanner_campaign",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationCounters",
          "kind": "LinkedField",
          "name": "counters",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bcaLicensedCreators",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdvertiserSubscription",
          "kind": "LinkedField",
          "name": "subscription",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "availableAddonIds",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AddonItem",
              "kind": "LinkedField",
              "name": "addonItems",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Addon",
                  "kind": "LinkedField",
                  "name": "addon",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "resourcesPerUnit",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paused",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "interval",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SubscriptionLimits",
              "kind": "LinkedField",
              "name": "effectiveLimits",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SubscriptionLimits",
              "kind": "LinkedField",
              "name": "baseLimits",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};
})();

(node as any).hash = "d68aeb756c47d2e7fbef3bbfc71231de";

export default node;
