import React, { useContext, useMemo } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import styles from './InviteOutreachCreators.pcss';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import { InviteOutreachCreatorsQuery as QueryType } from 'GraphTypes/InviteOutreachCreatorsQuery.graphql';

import CreatorsCsvUploader from './CreatorsCsvUploader/CreatorsCsvUploader';
import {
  InviteOutreachCreatorsContext,
  InviteOutreachTemplateData,
  InviteOutreachTemplateItemData,
} from './InviteOutreachCreatorsContext';
import TemplateSelector from './TemplateSelector/TemplateSelector';
import ProviderSelector from './ProviderSelector/ProviderSelector';
import GmailSettings from './GmailSettings/GmailSettings';
import RowErrors from './RowErrors/RowErrors';
import { useNavigate, useParams } from 'react-router-dom';
import Page from 'Templates/Page/Page';
import classNames from 'classnames';
import TemplatePreview from './TemplatePreview/TemplatePreview';

const InviteOutreachCreatorsQuery = graphql`
  query InviteOutreachCreatorsQuery($campaignId: ID!) {
    ...TemplateSelectorForOutreach_list
    campaign(id: $campaignId) {
      ...GmailSettingsForOutreach_campaign
      platform
      sampleOutreachTemplateVariables {
        name
        value
      }
    }
  }
`;

const InviteOutreachCreators: React.FC = () => {
  const { id: campaignId = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const {
    fileError,
    isFormValid,
    rows,
    error,
    showRowErrors,
    locale,
    transport,
    processing,
    templateData,
    templatePreview,
    setTemplatePreview,
    handleImportOutreachBatch,
    importOutreachBatchLoading,
  } = useContext(InviteOutreachCreatorsContext);

  const queryData = useLazyLoadQuery<QueryType>(InviteOutreachCreatorsQuery, { campaignId });
  const platform = queryData.campaign?.platform;
  const sampleOutreachTemplateVariables = queryData.campaign?.sampleOutreachTemplateVariables;

  const templateVars = useMemo(() => {
    return sampleOutreachTemplateVariables?.reduce((acc, item) => {
      return { ...acc, [item.name]: item.value };
    }, {}) as TemplateSpecification;
  }, [sampleOutreachTemplateVariables]);

  const handleSendClick = () => {
    amplitude.sendEvent<412>({
      id: '412',
      category: 'campaign',
      name: 'outreach_send_click',
      param: { template_name: templateData.name },
    });
    handleImportOutreachBatch(campaignId, platform);
  };

  const handlePreviewClick = () => {
    setTemplatePreview(templateData as InviteOutreachTemplateItemData);
  };

  const handleBackToBatches = () => {
    navigate(-1);
  };

  /*const locales = [
    { id: 'EN', msg: 'invite_outreach_creators_modal.locale.en' },
    { id: 'ES', msg: 'invite_outreach_creators_modal.locale.es' },
  ];

  const dropdownLabel = locale
    ? { msg: `invite_outreach_creators_modal.locale.${locale.toLowerCase()}` }
    : { msg: 'invite_outreach_creators_modal.locale.placeholder' };*/

  if (!queryData.campaign) return null;
  return (
    <Page className={styles.root}>
      {showRowErrors ? (
        <RowErrors />
      ) : (
        <>
          <div className={styles.content}>
            <div className={styles.titleContainer}>
              <div className={styles.backContainer}>
                <AlterButton
                  icon="Arrow-big-left"
                  className={styles.resetTemplate}
                  onClick={handleBackToBatches}
                />
                <Text
                  type="d2"
                  msg="invite_outreach_creators_modal.title"
                  className={styles.title}
                />
              </div>
              <div className={styles.backContainer}>
                <AlterButton
                  type="white"
                  icon={'Eye'}
                  disabled={!templateData.id}
                  msg="invite_outreach_creators_modal.preview"
                  onClick={handlePreviewClick}
                />
                <AlterButton
                  type="black"
                  icon={'Rocket'}
                  loading={importOutreachBatchLoading || processing}
                  disabled={!isFormValid}
                  msg="invite_outreach_creators_modal.send"
                  onClick={handleSendClick}
                />
              </div>
            </div>

            <div className={classNames(styles.content, styles.inviteFields)}>
              <CreatorsCsvUploader />
              {/*  ____________________________________________ */}
              {/*  THIS dropdown is for future multiple locales */}
              {/*  ____________________________________________ */}

              {/* {rows.length > 0 && (
              <>
                <Text
                  type="md"
                  msg="invite_outreach_creators_modal.step_2"
                  className={styles.subtitle}
                />
                <Dropdown value={<Text type="md" {...dropdownLabel} />} className={styles.dropdown}>
                  <DropdownGroup className={styles.dropdownMenu}>
                    {locales.map((item) => {
                      const isActive = item.id === locale;

                      const handleLocaleChange = () => {
                        if (isActive) return;
                        setTemplateId('');
                        setLocale(item.id);
                      };

                      return (
                        <DropdownItem key={item.msg}>
                          <AlterButton
                            msg={item.msg}
                            onClick={handleLocaleChange}
                            fluid
                            iconPosition="right"
                            icon={isActive ? 'Check' : null}
                          />
                        </DropdownItem>
                      );
                    })}
                  </DropdownGroup>
                </Dropdown>
              </>
            )} */}
              {rows?.length > 0 && locale && !fileError && (
                <TemplateSelector variables={templateVars} query={queryData} />
              )}

              {templateData?.id && <ProviderSelector />}
              {Boolean(templateData?.id && transport === 'GMAIL') && (
                <GmailSettings query={queryData.campaign} />
              )}
              {error && (
                <Text
                  type="md"
                  msg={`invite_outreach_creators_modal.title.error.${error}`}
                  className={styles.commonError}
                />
              )}
            </div>
          </div>
          {templatePreview && <TemplatePreview variables={templateVars} />}
        </>
      )}
    </Page>
  );
};

export default InviteOutreachCreators;
