/**
 * @generated SignedSource<<594b24f80eb9f995731d1db67e476719>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSettingsQuery$variables = Record<PropertyKey, never>;
export type UserSettingsQuery$data = {
  readonly currentUser: {
    readonly organization: {
      readonly id: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"PersonalInfo_user" | "Workspace_user">;
  } | null | undefined;
};
export type UserSettingsQuery = {
  response: UserSettingsQuery$data;
  variables: UserSettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PersonalInfo_user"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Workspace_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "websiteUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "industry",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdvertiserProfile",
            "kind": "LinkedField",
            "name": "advertiserProfile",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyRole",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c999814a90479d4f4d1b8f10f77ce0c7",
    "id": null,
    "metadata": {},
    "name": "UserSettingsQuery",
    "operationKind": "query",
    "text": "query UserSettingsQuery {\n  currentUser {\n    organization {\n      id\n    }\n    ...PersonalInfo_user\n    ...Workspace_user\n    id\n  }\n}\n\nfragment PersonalInfo_user on User {\n  email\n  advertiserProfile {\n    id\n    lastName\n    firstName\n    companyRole\n  }\n}\n\nfragment Workspace_user on User {\n  organization {\n    name\n    websiteUrl\n    industry\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "78d7869b7b228a506c0b1c198ddee1ce";

export default node;
