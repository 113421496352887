import React, { useContext, useState } from 'react';
import Handlebars from 'handlebars';
import { graphql, useFragment } from 'react-relay';

import styles from '../InviteOutreachCreators.pcss';
import {
  InviteOutreachCreatorsContext,
  InviteOutreachCreatorsTemplate,
} from '../InviteOutreachCreatorsContext';

import { amplitude } from 'Helpers/amplitude';
import Text from 'Components/ui/Text/Text';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import RadioButton from 'Components/ui/RadioButton/RadioButton';
import { TemplateSelectorForOutreach_list$key } from 'GraphTypes/TemplateSelectorForOutreach_list.graphql';
import TemplateEditor from '../TemplateEditor/TemplateEditor';
import { useDeleteOutreachTemplateMutation } from 'Api/mutations/DeleteOutreachTemplate.Mutation';

interface Props {
  variables: TemplateSpecification;
  query: TemplateSelectorForOutreach_list$key;
}

const TemplateSelector: React.FC<Props> = (props) => {
  const { query, variables } = props;
  const [editableTemplate, setEditableTemplate] =
    useState<InviteOutreachCreatorsTemplate>(undefined);
  const [deletedId, setDeletedId] = useState<string | null>(null);
  const [deleteTemplate, deleting] = useDeleteOutreachTemplateMutation();
  const {
    locale,
    templateData,
    templateEditor,
    setTemplateData,
    setTemplatePreview,
    setTemplateEditorStatus,
  } = useContext(InviteOutreachCreatorsContext);
  const data = useFragment(
    graphql`
      fragment TemplateSelectorForOutreach_list on Query {
        outreachTemplates {
          edges {
            node {
              id
              name
              locale
              htmlBody
              subject
            }
          }
        }
      }
    `,
    query
  );

  const templates = data.outreachTemplates?.edges;

  const handleAddTemplate = () => {
    setEditableTemplate(undefined);
    setTemplateEditorStatus(true);
  };

  return (
    <>
      <Text type="md" msg="invite_outreach_creators_modal.step_2" className={styles.subtitle} />
      <div className={styles.templates}>
        {templates?.map((item) => {
          if (!item?.node || item.node.locale !== locale) return null;

          const { id, name, htmlBody, subject } = item.node;

          const template = Handlebars.compile(htmlBody);
          const templateBody = template(variables);

          const isActive = templateData.id === id;

          const handleTemplateSelect = () => {
            amplitude.sendEvent<411>({
              id: '411',
              category: 'campaign',
              name: 'outreach_template_click',
              param: { template_name: name },
            });
            setTemplateData({
              id,
              name,
              body: htmlBody,
              subject,
            });
          };

          const handleTemplateShow = () => {
            setTemplatePreview({
              body: templateBody,
              subject,
              name,
            });
          };

          const handleTemplateEditClick = () => {
            setEditableTemplate(item.node);
            setTemplateEditorStatus(true);
          };

          const handleTemplateDelete = () => {
            if (!item.node) return;
            setDeletedId(item.node?.id);
            deleteTemplate({
              variables: {
                input: {
                  id: item.node?.id,
                },
              },
            });
          };

          return (
            <div className={styles.template} key={id}>
              <div className={styles.templateDetails}>
                <div onClick={handleTemplateSelect}>
                  <RadioButton checked={isActive} />
                </div>
                <Text type="md" text={name} />
              </div>
              <AlterButton type="grey" icon="Eye" onClick={handleTemplateShow} />
              <AlterButton type="grey" icon="Edit" onClick={handleTemplateEditClick} />
              <AlterButton
                type="grey"
                loading={deleting && deletedId === item.node.id}
                icon="Trash-delele"
                onClick={handleTemplateDelete}
              />
            </div>
          );
        })}
      </div>
      <AlterButton
        type="black"
        className={styles.listCompleteButton}
        icon="Add-plus"
        msg="invite_outreach_creators_modal.add_custom_template"
        onClick={handleAddTemplate}
      />
      {templateEditor && (
        <div className={styles.templateDrawer}>
          <TemplateEditor template={editableTemplate} variables={variables} />
        </div>
      )}
    </>
  );
};

export default TemplateSelector;
