import Tabs from 'Molecules/Tabs/Tabs';

type AmountsDataArray = ['25', '50', '75', '100'];
type AmountsDataValues = AmountsDataArray[keyof AmountsDataArray];

interface AmountsParams {
  creatorsCount: number;
}

export const createAmountsData = (data: AmountsParams) => {
  const { creatorsCount } = data;

  return [
    {
      textData: {
        msg: 'creators_bulk_adding_drawer.creators_count',
        formatValues: { count: 25 },
      },
      id: '25',
      isDisabled: 25 > creatorsCount,
    },
    {
      textData: {
        msg: 'creators_bulk_adding_drawer.creators_count',
        formatValues: { count: 50 },
      },
      id: '50',
      isDisabled: 50 > creatorsCount,
    },
    {
      textData: {
        msg: 'creators_bulk_adding_drawer.creators_count',
        formatValues: { count: 75 },
      },
      id: '75',
      isDisabled: 75 > creatorsCount,
    },
    {
      textData: {
        msg: 'creators_bulk_adding_drawer.creators_count',
        formatValues: { count: 100 },
      },
      id: '100',
      isDisabled: 100 > creatorsCount,
    },
  ];
};

export type { AmountsDataArray, AmountsDataValues };

type TabsProps = React.ComponentProps<typeof Tabs>;
type TabItems = NonNullable<TabsProps['items']>;
