/**
 * @generated SignedSource<<39e3883c7343800ed32ac7d12bc6a9fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteOutreachTemplateInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type DeleteOutreachTemplateMutation$variables = {
  input: DeleteOutreachTemplateInput;
};
export type DeleteOutreachTemplateMutation$data = {
  readonly deleteOutreachTemplate: {
    readonly __typename: string;
    readonly message?: string;
  } | null | undefined;
};
export type DeleteOutreachTemplateMutation = {
  response: DeleteOutreachTemplateMutation$data;
  variables: DeleteOutreachTemplateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "deleteOutreachTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "Error",
        "abstractKey": "__isError"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteOutreachTemplateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteOutreachTemplateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "410289546359127a353c3ca245d49dd9",
    "id": null,
    "metadata": {},
    "name": "DeleteOutreachTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteOutreachTemplateMutation(\n  $input: DeleteOutreachTemplateInput!\n) {\n  deleteOutreachTemplate(input: $input) {\n    __typename\n    ... on Error {\n      __isError: __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff0fa780c82a0b5ef639da19f05e8183";

export default node;
