import React from 'react';
import classnames from 'classnames';

import styles from './CampaignStub.pcss';

import { CampaignStage } from 'GraphTypes/CampaignsQuery.graphql';

interface Props {
  withBars?: boolean;
  stage?: CampaignStage;
}

const CampaignStub: React.FC<Props> = (props) => {
  const { stage, withBars } = props;

  const item = (
    <div className={styles.item}>
      <div className={styles.ava} />
      <div className={styles.names}>
        <div className={styles.name} />
        <div className={styles.brand} />
      </div>
      {stage === 'ACTIVE' && (
        <>
          <div className={styles.applications} />
          <div className={styles.messages} />
          <div className={styles.creatives} />
        </>
      )}
      {stage === 'DRAFT' && (
        <div className={styles.createdDateWrap}>
          <div className={styles.createdDate} />
        </div>
      )}
      {stage === 'COMPLETED' && (
        <>
          <div className={styles.completed} />
          <div className={styles.messages} />
          <div className={styles.spent} />
        </>
      )}
      <div className={styles.status} />
    </div>
  );

  return (
    <div className={classnames(styles.root, { [styles.withBars]: withBars })}>
      {item}
      {item}
      {item}
    </div>
  );
};

export default CampaignStub;
