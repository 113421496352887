import React, { useContext } from 'react';
import classnames from 'classnames';

import styles from './SubscriptionLimit.pcss';

import Tooltip from 'Atoms/Tooltip/Tooltip';
import Text from 'Components/ui/Text/Text';
import { DrawerContext } from 'Containers/Drawer/DrawerContainer';
import AlterButton, { ButtonIcon } from 'Components/ui/AlterButton/AlterButton';

interface Props {
  titleMsg: string;
  totalCount: number;
  paused?: boolean;
  disabled?: boolean;
  maxCount?: number | null;
  onManageClick?: () => void;
  type: 'maxActiveBrands' | 'maxSeats' | 'maxBcaConnections';
  children?: JSX.Element;
}

const iconManageMap = {
  maxActiveBrands: 'Planet',
  maxSeats: 'Profile',
  maxBcaConnections: 'Paid-ads',
};

const typeToMsgMap = {
  maxActiveBrands: 'brands',
  maxSeats: 'seats',
  maxBcaConnections: 'licenses',
};

const SubscriptionLimit: React.FC<Props> = (props) => {
  const { titleMsg, totalCount, maxCount, type, paused, disabled, onManageClick, children } = props;

  const { openDrawer } = useContext(DrawerContext);

  const counters =
    totalCount === 0 && maxCount === 0
      ? { text: '0' }
      : {
          msg: 'limit.counters',
          formatValues: {
            totalCount: typeof totalCount === 'number' ? totalCount : '',
            maxCount: typeof maxCount === 'number' ? maxCount : '',
          },
        };

  const handleDrawerOpen = () => {
    openDrawer(`buy-${type}-addon`);
  };

  return (
    <div className={styles.root}>
      {maxCount === null ? (
        <div className={styles.title}>
          <Text type="md" msg={titleMsg} />
          <Text type="md" msg="limit.unlimited" />
        </div>
      ) : (
        <div className={styles.title}>
          <Text type="md" msg={titleMsg} />
          <Text
            type="md"
            {...counters}
            className={classnames({
              [styles.error]: totalCount && maxCount && totalCount > maxCount,
            })}
          />
        </div>
      )}
      <div className={styles.buttonContainer}>
        <AlterButton
          msg="billing.limits.manage"
          onClick={onManageClick}
          icon={iconManageMap[type] as ButtonIcon}
          fluid
        />
        <Tooltip
          id={`billing.limits.${type}`}
          tooltipMsg={paused ? `billing.limits.${typeToMsgMap[type]}.pause_tooltip` : undefined}
          place={'top'}
        >
          <AlterButton
            type="black"
            icon={disabled ? 'Lock' : 'Add-plus-circle'}
            msg={'billing.limits.add_more'}
            disabled={disabled}
            onClick={handleDrawerOpen}
            className={styles.addButton}
            fluid
          />
        </Tooltip>
        {children}
      </div>
    </div>
  );
};

export default SubscriptionLimit;
