import React, { useContext, useState } from 'react';
import classnames from 'classnames';

import styles from '../InviteOutreachCreators.pcss';
import { InviteOutreachCreatorsContext } from '../InviteOutreachCreatorsContext';

import { amplitude } from 'Helpers/amplitude';
import FileUploader from 'Atoms/FileUploader/FileUploader';
import Text from 'Components/ui/Text/Text';
import { createBytesSize } from 'Util/numberFormatter';
import Icon from 'Components/ui/Icon/Icon';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import exampleImg from 'Images/campaign/outreach_example.png';
import example2xImg from 'Images/campaign/outreach_example_2x.png';
import Input from 'Components/ui/Input/Input';
import TextButton from 'Components/ui/TextButton/TextButton';

interface Props {}

type UserList = {
  handle: string;
  email: string;
}[];

const CreatorsCsvUploader: React.FC<Props> = (props) => {
  const [showCsvRequirements, setShowCsvRequirements] = useState(false);
  const [isManuallyType, setManuallyType] = useState(true);
  const [userList, setUserList] = useState<UserList>([
    {
      handle: '',
      email: '',
    },
  ]);

  const {
    fileName,
    fileError,
    fileSize,
    rowMetrics,
    rowErrors,
    setShowRowErrors,
    handleFileReset,
    handleListChange,
    handleParseComplete,
  } = useContext(InviteOutreachCreatorsContext);

  const handleAttachClick = () => {
    amplitude.sendEvent<410>({
      id: '410',
      category: 'campaign',
      name: 'outreach_attach_click',
      param: undefined,
    });
  };

  const handleManuallyClick = () => {
    setManuallyType(true);
  };

  const handleAddManuallyClick = () => {
    setUserList((list) => [
      ...list,
      {
        handle: '',
        email: '',
      },
    ]);
  };

  const handleChangeHandle = (index: number, handle: string) => {
    const creators = [...userList];
    creators[index].handle = handle;
    setUserList(creators);
  };

  const handleChangeEmail = (index: number, email: string) => {
    const creators = [...userList];
    creators[index].email = email;
    setUserList(creators);
  };

  const handleRemoveCreator = (index: number) => {
    const creators = [...userList];
    setUserList(creators.filter((_, idx) => index !== idx));
  };

  const handleCompleteList = () => {
    handleParseComplete({
      data: userList.map((creator) => [`${creator.handle};${creator.email}`]),
      errors: [],
      meta: null,
    });
  };

  const handleResetList = () => {
    handleFileReset();
  };

  const hanldeShowRequirementsClick = () => {
    setShowCsvRequirements(!showCsvRequirements);
  };

  const handleErrorsShow = () => {
    setShowRowErrors(true);
  };

  const notEmptyList = Number(rowMetrics?.parsedCount) > 0;

  return (
    <>
      <div className={styles.attachTitle} onClick={hanldeShowRequirementsClick}>
        <Text type="md" msg="invite_outreach_creators_modal.step_1" />
        <div>
          <Icon size={16} name={showCsvRequirements ? 'Arrow-small-up' : 'Arrow-small-down'} />
        </div>
      </div>
      {showCsvRequirements && (
        <div className={styles.requirements}>
          <Text type="md" msg="invite_outreach_creators_modal.csv_requirements" />
          <img srcSet={`${exampleImg} 1x, ${example2xImg} 2x`} className={styles.example} />
        </div>
      )}
      {fileName ? (
        <>
          <div className={styles.importHeader}>
            {Number(rowMetrics?.totalCount) > 0 && (
              <div className={classnames(styles.importInfo, { [styles.error]: !notEmptyList })}>
                <Icon name={notEmptyList ? 'Check' : 'Warning-error'} />
                <Text
                  type="md"
                  msg="invite_outreach_creators_modal.attach.not_full_list"
                  formatValues={{
                    totalCount: rowMetrics?.totalCount,
                    parsedCount: rowMetrics?.parsedCount,
                  }}
                />
              </div>
            )}
            {(rowErrors || [])?.length > 0 && (
              <Text
                type="md"
                msg="invite_outreach_creators_modal.check_errors"
                className={styles.showErrors}
                handleClick={handleErrorsShow}
              />
            )}
          </div>
          <div className={styles.file}>
            {fileError ? (
              <Icon name="Warning-error" className={styles.error} />
            ) : (
              <Icon name="Document" />
            )}
            <div className={styles.fileDetails}>
              <Text type="md" text={fileName} />
              {fileError ? (
                <Text type="label" msg={fileError} className={styles.error} />
              ) : (
                <Text type="label" text={createBytesSize(fileSize || 0)} className={styles.size} />
              )}
            </div>
            <Icon name="Close-small" className={styles.size} handleClick={handleFileReset} />
          </div>
        </>
      ) : (
        <>
          {isManuallyType ? (
            !rowMetrics ? (
              <div className={styles.listWrapper}>
                {userList.map((creator, idx) => (
                  <div className={styles.listItem} key={idx}>
                    <Input
                      bordered
                      placeholder="Username"
                      hideCloseIcon
                      value={creator.handle}
                      onChange={(e) => handleChangeHandle(idx, e.target.value)}
                    />
                    <Input
                      bordered
                      placeholder="Email"
                      hideCloseIcon
                      value={creator.email}
                      onChange={(e) => handleChangeEmail(idx, e.target.value)}
                    />
                    <div className={styles.removeItem} onClick={() => handleRemoveCreator(idx)}>
                      <Icon name="Close-small" color="grey" />
                    </div>
                  </div>
                ))}
                <TextButton
                  icon="Add-plus"
                  msg="invite_outreach_creators_modal.add_to_list"
                  className={styles.listAddButton}
                  onClick={handleAddManuallyClick}
                />
                <div className={styles.buttonWrapper}>
                  <AlterButton
                    type="black"
                    icon="Check"
                    disabled={userList.length === 0}
                    className={styles.listCompleteButton}
                    msg="invite_outreach_creators_modal.list_complete"
                    onClick={handleCompleteList}
                  />
                  <Text type="md" text="or" />
                  <div className={styles.uploaderWrapper} onClick={handleAttachClick}>
                    <AlterButton
                      type="grey"
                      icon="Attachment"
                      msg="invite_outreach_creators_modal.attach"
                    />
                    <FileUploader
                      typeList=".csv"
                      className={styles.uploader}
                      onChange={handleListChange}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.importHeader}>
                  {Number(rowMetrics?.totalCount) > 0 && (
                    <div
                      className={classnames(styles.importInfo, { [styles.error]: !notEmptyList })}
                    >
                      <Icon name={notEmptyList ? 'Check' : 'Warning-error'} />
                      <Text
                        type="md"
                        msg="invite_outreach_creators_modal.attach.not_full_list"
                        formatValues={{
                          totalCount: rowMetrics?.totalCount,
                          parsedCount: rowMetrics?.parsedCount,
                        }}
                      />
                    </div>
                  )}
                  {(rowErrors || [])?.length > 0 && (
                    <Text
                      type="md"
                      msg="invite_outreach_creators_modal.check_errors"
                      className={styles.showErrors}
                      handleClick={handleErrorsShow}
                    />
                  )}
                </div>
                {(rowErrors || [])?.length > 0 && (
                  <AlterButton
                    type="black"
                    icon="Arrow-small-left"
                    msg="invite_outreach_creators_modal.return_to_list"
                    className={styles.listCompleteButton}
                    onClick={handleResetList}
                  />
                )}
              </>
            )
          ) : null}
        </>
      )}
    </>
  );
};

export default CreatorsCsvUploader;
