import React, { useCallback, useState } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';

import styles from './Campaigns.pcss';

import { Bars } from 'CommonPage/Dashboard/AdvertiserDashboard/Campaigns/CampaignsList/CampaignsList';
import CampaignStub from 'CommonPage/Dashboard/AdvertiserDashboard/Campaigns/CampaignsList/CampaignStub/CampaignStub';
import LoaderHandler from 'Organisms/LoaderHandler/LoaderHandler';
import Campaign from 'CommonPage/Dashboard/AdvertiserDashboard/Campaigns/CampaignsList/Campaign/Campaign';
import Drawer from 'Components/ui/Drawer';
import CampaignActionsModal from 'CommonPage/Dashboard/AdvertiserDashboard/Campaigns/CampaignsList/Campaign/CampaignPreviewActions/CampaignActionsModal/CampaignActionsModal';

const CAMPAIGNS_COUNT = 18;
const FOOTER_OFFSET = 279;

const Campaigns = (props) => {
  const {
    relay,
    preloader,
    counters,
    organization: { campaigns },
    organizationId,
    userOrganizationId,
    stages,
  } = props;

  const [activeCampaign, setActiveCampaign] = useState(null);

  const canDownloadShipment =
    activeCampaign?.brief?.productDelivery === 'BRAND_WILL_HANDLE_SHIPMENT_TO_CREATORS';

  const closeCampaignMenu = useCallback(() => {
    setActiveCampaign(null);
  }, []);

  const stage = stages[0];

  return (
    <div className={styles.container}>
      <Bars stage={stage} counters={counters} />
      <LoaderHandler
        relay={relay}
        count={CAMPAIGNS_COUNT}
        offset={FOOTER_OFFSET}
        preloader={<CampaignStub stage={stage} />}
      >
        {campaigns.edges.map((item, idx) => {
          const { id } = item.node;
          return (
            <Campaign
              key={id}
              index={idx}
              isAdmin
              campaign={item.node}
              organizationId={userOrganizationId}
              handleCampaignMenu={setActiveCampaign}
            />
          );
        })}
        <Drawer
          rootKey={'admin-campaign-menu'}
          opened={!!activeCampaign}
          onClose={closeCampaignMenu}
          needCloseButton={false}
        >
          <CampaignActionsModal
            campaign={activeCampaign}
            setActiveCampaign={setActiveCampaign}
            handleClose={closeCampaignMenu}
            isAdmin
            canDownloadShipment={canDownloadShipment}
          />
        </Drawer>
      </LoaderHandler>
    </div>
  );
};

export default createPaginationContainer(
  Campaigns,
  {
    organization: graphql`
      fragment Campaigns_organization on Organization
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 18 }
        cursor: { type: "String" }
        textQuery: { type: "String" }
        stages: { type: "[CampaignStage!]" }
      ) {
        campaigns(first: $count, after: $cursor, stages: $stages, textQuery: $textQuery)
          @connection(key: "Campaigns_campaigns", filters: ["stages", "textQuery"]) {
          edges {
            node {
              id
              ...Campaign_campaign
              brief {
                ... on V2Brief {
                  productDelivery
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.organization && props.organization.campaigns;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor, orderBy }) {
      return {
        count,
        cursor,
        orderBy,
        organizationId: props.organizationId,
        stages: props.stages,
        textQuery: props.textQuery,
      };
    },
    query: graphql`
      query CampaignsPaginationQuery(
        $organizationId: ID!
        $count: Int!
        $cursor: String
        $textQuery: String
        $stages: [CampaignStage!]
      ) {
        organization(id: $organizationId) {
          ...Campaigns_organization
            @arguments(count: $count, cursor: $cursor, stages: $stages, textQuery: $textQuery)
        }
      }
    `,
  }
);
