import React from 'react';
import compact from 'lodash/compact';

import { amplitude } from 'Helpers/amplitude';
import AlterButton from 'Components/ui/AlterButton/AlterButton';
import {
  CREATIVES_ROUTE,
  BRANDS_ROUTE,
  PS_LICENSES_ROUTE,
  CREATORS_ROUTE,
  DASHBOARD_ROUTE,
  ORGANIZATION_PROJECTS_ROUTE,
  PERFORMANCE_ROUTE,
  USER_TEAM,
} from 'Constants/general';

type NavigationItems = NavigationItem[];

type NavigationItemsData = {
  chatIcon?: JSX.Element;
};
const createNavigationItems = (data: NavigationItemsData): NavigationItems => {
  const { chatIcon } = data;

  return compact([
    {
      id: 'campaigns',
      icon: 'Campaigns',
      path: DASHBOARD_ROUTE,
      msg: 'layout.navigation.campaigns',
      dataTest: 'alterButton:navigationDashboard',
    },
    {
      id: 'chats',
      iconElement: chatIcon,
      path: ORGANIZATION_PROJECTS_ROUTE,
      msg: 'layout.navigation.chats',
      dataTest: 'alterButton:navigationChats',
    },
    {
      id: 'marketplace',
      icon: 'Star-shining',
      path: '',
      msg: 'layout.navigation.marketplace',
      dataTest: 'alterButton:navigationMarketplace',
    },
    {
      id: 'brands',
      icon: 'Planet',
      path: BRANDS_ROUTE,
      msg: 'layout.navigation.brands',
      dataTest: 'alterButton:navigationBrands',
    },
    {
      id: 'licenses',
      icon: 'Paid-ads',
      path: PS_LICENSES_ROUTE,
      msg: 'layout.navigation.licenses',
      dataTest: 'alterButton:navigationLicenses',
    },
    {
      id: 'creators',
      icon: 'Star',
      path: CREATORS_ROUTE,
      msg: 'layout.navigation.creators',
      dataTest: 'alterButton:navigationCreators',
    },
    {
      id: 'content',
      icon: 'Video-movies',
      path: CREATIVES_ROUTE,
      msg: 'layout.navigation.creatives_library',
      dataTest: 'alterButton:navigationCreatives',
    },
    {
      id: 'performance',
      icon: 'Analytics',
      path: PERFORMANCE_ROUTE,
      msg: 'layout.navigation.performance',
      dataTest: 'alterButton:navigationPerfomance',
    },
    {
      id: 'team',
      icon: 'Group-users',
      path: USER_TEAM,
      msg: 'layout.navigation.team',
      dataTest: 'alterButton:navigationTeam',
      onClick: () => {
        amplitude.sendEvent<419>({
          id: '419',
          category: 'navigation',
          name: 'team_click',
          param: undefined,
        });
      },
    },
  ]);
};

export { createNavigationItems };

// types

type AlterButtonProps = React.ComponentProps<typeof AlterButton>;
type NavigationItem = {
  id: string;
  icon?: NonNullable<AlterButtonProps['icon']>;
  msg: NonNullable<AlterButtonProps['msg']>;
  iconElement?: JSX.Element;
  path: string;
  isBeta?: boolean;
  dataTest?: string;
  onClick?: () => void;
};
