/**
 * @generated SignedSource<<f6ceb7f99753f101c582afe6a5fdad72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthorizeTiktokShopUserInput = {
  clientMutationId?: string | null | undefined;
  code?: string | null | undefined;
};
export type AuthorizeTiktokShopUserMutation$variables = {
  input: AuthorizeTiktokShopUserInput;
};
export type AuthorizeTiktokShopUserMutation$data = {
  readonly authorizeTiktokShopUser: {
    readonly __typename: "AuthorizeTiktokShopUserPayload";
  } | null | undefined;
};
export type AuthorizeTiktokShopUserMutation = {
  response: AuthorizeTiktokShopUserMutation$data;
  variables: AuthorizeTiktokShopUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AuthorizeTiktokShopUserPayload",
    "kind": "LinkedField",
    "name": "authorizeTiktokShopUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorizeTiktokShopUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthorizeTiktokShopUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "89acc2833f81c0d0be37318880c658d8",
    "id": null,
    "metadata": {},
    "name": "AuthorizeTiktokShopUserMutation",
    "operationKind": "mutation",
    "text": "mutation AuthorizeTiktokShopUserMutation(\n  $input: AuthorizeTiktokShopUserInput!\n) {\n  authorizeTiktokShopUser(input: $input) {\n    __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "2bf03d97e77f6838e6f4ffa985c2d103";

export default node;
